import {
  GET_ALL_META_HEADER_FOOTER,
  GET_ALL_META_HEADER_FOOTER_FAIL,
  GET_ALL_META_HEADER_FOOTER_SUCCESS,
  GET_ALL_DATA_STEP_PAGE,
  GET_ALL_DATA_STEP_PAGE_SUCCESS,
  GET_ALL_DATA_STEP_PAGE_FAIL,
  CHANGE_LANGUAGE,
  GET_ALL_DATA_AGREEMENT_PAGE,
  GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS,
  GET_ALL_DATA_AGREEMENT_PAGE_FAIL,
  GET_ALL_DATA_MOTTO_PAGE,
  GET_ALL_DATA_MOTTO_PAGE_SUCCESS,
  GET_ALL_DATA_MOTTO_PAGE_FAIL,
} from "./actionTypes";

export const getAllComponentsData = (data_meta) => ({
  type: GET_ALL_META_HEADER_FOOTER,
  payload: data_meta,
});

export const getAllComponentsDataSuccess = (all_meta_component) => ({
  type: GET_ALL_META_HEADER_FOOTER_SUCCESS,
  payload: all_meta_component,
});

export const getAllComponentsDataFail = (error) => ({
  type: GET_ALL_META_HEADER_FOOTER_FAIL,
  payload: error,
});

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  payload: language,
});

export const getAllDataStepPage = (data_step) => ({
  type: GET_ALL_DATA_STEP_PAGE,
  payload: data_step,
});

export const getAllDataStepPageSuccess = (all_step_page) => ({
  type: GET_ALL_DATA_STEP_PAGE_SUCCESS,
  payload: all_step_page,
});

export const getAllDataStepPageFail = (error) => ({
  type: GET_ALL_DATA_AGREEMENT_PAGE_FAIL,
  payload: error,
});

export const getAllDataAgreementPage = (agreement) => ({
  type: GET_ALL_DATA_AGREEMENT_PAGE,
  payload: agreement,
});

export const getAllDataAgreementPageSuccess = (all_agreement_page) => ({
  type: GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS,
  payload: all_agreement_page,
});

export const getAllDataAgreementPageFail = (error) => ({
  type: GET_ALL_DATA_STEP_PAGE_FAIL,
  payload: error,
});

export const getAllDataMottoPage = (motto) => ({
  type: GET_ALL_DATA_MOTTO_PAGE,
  payload: motto,
});

export const getAllDataMottoPageSuccess = (all_motto_page) => ({
  type: GET_ALL_DATA_MOTTO_PAGE_SUCCESS,
  payload: all_motto_page,
});

export const getAllDataMottoPageFail = (error) => ({
  type: GET_ALL_DATA_MOTTO_PAGE_FAIL,
  payload: error,
});
