import axios from "axios";
import { publicIp } from "public-ip";

import {
  BASE_API_URL,
  BASE_API_URL_PAGE_CONTENT,
  GET_ALL_NATIONS,
  GET_ALL_NATIONS_LIST,
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_PROFILE,
  GET_ALL_USER_PROFILE_TYPE,
  GET_USER_PROFILE,
  LOGIN_USER,
  NEW_LOGIN_USER,
  SUBSCRIBER_BUTTON_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_CLAIM,
  UPDATE_USER_PROFILE_PP,
} from "./url_helper";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

const apiAxios = axios.create({
  baseURL: BASE_API_URL_PAGE_CONTENT,
});
// Ajoutez un intercepteur de requête pour inclure le token dans l'en-tête Authorization
apiAxios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("authToken");
    // const accessToken = Cookies.get("authToken");
    if (accessToken) {
      config.headers.Authorization = `${accessToken}`;
    }
    // config.headers["User-Agent"] = window.navigator.userAgent;
    // config.headers["X-Forwarded-For"] = await publicIp.v4();
    return config;
  },
  (error) => {
    // Faites quelque chose avec l'erreur de requête
    return Promise.reject(error);
  }
);

// Ajoutez un intercepteur de réponse pour gérer les réponses globalement
apiAxios.interceptors.response.use(
  (response) => {
    if (
      response &&
      (response.data.returnCode === 404 || response.data.returnCode === 3)
    ) {
      // localStorage.removeItem("authToken");
      // window.location.href = "/notfound";
    } else if (response && response.data.returnCode === 999) {
      window.location.href = "/maintenance";
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authToken");
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export const getBrowserLanguage = () => {
  return window.navigator.language || window.navigator.userLanguage;
};

export const getAllDataOfNation = async (data) => {
  const response = await axiosInstance.post(GET_ALL_NATIONS, data);
  return response.data;
};

export const getAllDataNationList = async (data) => {
  const response = await apiAxios.post(GET_ALL_NATIONS_LIST, data);
  return response.data;
};

// Fonction de connexion
export const loginUserRequest = async (user) => {
  const response = await apiAxios.post(LOGIN_USER, user);
  return response;
};
export const newLoginUserRequest = async (user) => {
  const response = await apiAxios.post(NEW_LOGIN_USER, user);
  return response;
};

// Fonction de récupération du profile Utilisateur
export const getUserProfile = async (data) => {
  const url_get_profile = GET_USER_PROFILE(data);
  const response = await apiAxios.get(url_get_profile);
  return response.data;
};

// SUBSCRIBERS METHODS
export const getAllSubscribersData = async (data) => {
  const response = await apiAxios.post(GET_ALL_SUBSCRIBERS, data);
  return response.data;
};

export const getAllSubscribersProfileData = async (data) => {
  // const url_get_subs_prof_page = GET_ALL_SUBSCRIBERS_PROFILE(page);
  const response = await apiAxios.post(GET_ALL_SUBSCRIBERS_PROFILE, data);
  return response.data;
};

export const updateUserProfile = async (formData) => {
  const response = await apiAxios.post(UPDATE_USER_PROFILE, formData);
  return response;
};
export const updateUserProfileClaim = async (data) => {
  const response = await apiAxios.post(UPDATE_USER_PROFILE_CLAIM, data);
  return response;
};
export const subscribeUserProfile = async (data) => {
  const response = await apiAxios.post(SUBSCRIBER_BUTTON_PROFILE, data);
  return response;
};

export const updateUserProfile_pp = async (formData) => {
  const response = await apiAxios.post(UPDATE_USER_PROFILE_PP, formData);
  return response;
};

export const getAllUsersType = async (language_detect) => {
  const url_get_user_type_select = GET_ALL_USER_PROFILE_TYPE(language_detect);
  const response = await apiAxios.get(url_get_user_type_select);
  return response.data;
};
