import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import Head from "../components/Head";

const DeviseLabo = ({ data_motto_page }) => {
  return (
    <div id="wrapper">
      <Header />
      <Head pageTitle={data_motto_page[0]?.title} />

      <div className="main-wrapper">
        <section className="text-data">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="fw-bold pt-3 report-title ff2">
                  {data_motto_page[0]?.title}{" "}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="modboxwrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="modbox">
                  <div className="accordion" id="accordion-provider">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollaps"
                          aria-expanded="false"
                          aria-controls="providercollaps"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_motto_page[1]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollaps"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingOne"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_motto_page[1]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsTwo"
                          aria-expanded="false"
                          aria-controls="providercollapsTwo"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_motto_page[2]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingTwo"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_motto_page[2]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsThree"
                          aria-expanded="false"
                          aria-controls="providercollapsThree"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_motto_page[3]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingThree"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_motto_page[3]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>

                    {/* <!--/.accordion-item--> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_motto_page: state.ComponentsDataReducer.data_motto_page,
  // loading: state.NationsMapReducer.loading,
  // error: state.NationsMapReducer.error,
});

export default connect(mapStateToProps)(DeviseLabo);
