import React from "react";
import { connect } from "react-redux";
import { Progress } from "reactstrap";

const ProgressBar = (props) => {
  // const value = 250000;
  const value = (props.progressStatus / 1000000) * 100;
  const { data_page_components } = props;
  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <p style={{ fontWeight: "500" }}>1</p>
        </div>
        <div>
          <p style={{ fontWeight: "500" }}>1 000 000</p>
        </div>
      </div>
      <Progress color="danger" value="6" style={{ fontWeight: "500" }}>
        {value < 0.25
          ? "< 1%"
          : value > 0.25
          ? " 25%"
          : value > 1
          ? "125%"
          : ""}
      </Progress>

      <span style={{ display: "flex", fontSize: "14px" }}>
        <p
          style={{
            fontWeight: "500",
            marginRight: "10px",
          }}
        >
          {props.progressStatus}
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: data_page_components_obj.yesCommunityTotalText,
          }}
        />
      </span>
      {/* <span>
        <div
          dangerouslySetInnerHTML={{
            __html: `<strong>${props.progressStatus}</strong> ${data_page_components_obj.yesCommunityTotalText}`,
          }}
        />
      </span> */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
});

export default connect(mapStateToProps)(ProgressBar);
