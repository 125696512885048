// import { getBrowserLanguage } from "../../helpers/api_helper";
import {
  GET_ALL_META_HEADER_FOOTER,
  GET_ALL_META_HEADER_FOOTER_FAIL,
  GET_ALL_META_HEADER_FOOTER_SUCCESS,
  GET_ALL_DATA_STEP_PAGE,
  GET_ALL_DATA_STEP_PAGE_SUCCESS,
  GET_ALL_DATA_STEP_PAGE_FAIL,
  CHANGE_LANGUAGE,
  GET_ALL_DATA_AGREEMENT_PAGE,
  GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS,
  GET_ALL_DATA_AGREEMENT_PAGE_FAIL,
  GET_ALL_DATA_MOTTO_PAGE,
  GET_ALL_DATA_MOTTO_PAGE_SUCCESS,
  GET_ALL_DATA_MOTTO_PAGE_FAIL,
} from "./actionTypes";

const getInitialLanguage = () => {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  if (selectedLanguage) {
    return selectedLanguage;
  } else {
    return window.navigator.language.slice(0, 2);
  }
};

const initialState = {
  data_components: [],
  data_step_page: [],
  data_agreement_page: [],
  data_motto_page: [],
  loading: false,
  error: null,
  language: getInitialLanguage(),
};

const componentsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_META_HEADER_FOOTER:
      return { ...state, loading: true };
    case GET_ALL_META_HEADER_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data_components: action.payload.data,
      };
    case GET_ALL_META_HEADER_FOOTER_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CHANGE_LANGUAGE:
      localStorage.setItem("selectedLanguage", action.payload);
      return {
        ...state,
        language: action.payload,
      };

    case GET_ALL_DATA_STEP_PAGE:
      return { ...state, loading: true };
    case GET_ALL_DATA_STEP_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data_step_page: action.payload.data,
      };
    case GET_ALL_DATA_STEP_PAGE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_DATA_AGREEMENT_PAGE:
      return { ...state, loading: true };
    case GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data_agreement_page: action.payload.data,
      };
    case GET_ALL_DATA_AGREEMENT_PAGE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_DATA_MOTTO_PAGE:
      return { ...state, loading: true };
    case GET_ALL_DATA_MOTTO_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data_motto_page: action.payload.data,
      };
    case GET_ALL_DATA_MOTTO_PAGE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default componentsDataReducer;
