import React from "react";

const Coups = () => {
  return (
    <div>
      <h1>Coups d'état</h1>
    </div>
  );
};

export default Coups;
