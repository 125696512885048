import React from "react";

const World = () => {
  return (
    <div>
      <h1>Monde Multipolaire</h1>
    </div>
  );
};

export default World;
