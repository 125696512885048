import React from "react";

const ReviewProfileYes = (props) => {
  switch (props.starNumber) {
    case 0:
      return (
        <div className="ratingStar starProfile">
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className="m-0" alt="star" />
        </div>
      );
    case 1:
      return (
        <div className="ratingStar starProfile">
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className="m-0" alt="star" />
        </div>
      );
    case 2:
      return (
        <div className="ratingStar starProfile">
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className="m-0" alt="star" />
        </div>
      );
    case 3:
      return (
        <div className="ratingStar starProfile">
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img src="assets/amulab1/img/star.svg" className=" m-0" alt="star" />
          <img src="assets/amulab1/img/star.svg" className="m-0" alt="star" />
        </div>
      );
    case 4:
      return (
        <div className="ratingStar starProfile">
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img src="assets/amulab1/img/star.svg" className="m-0" alt="star" />
        </div>
      );
    case 5:
      return (
        <div className="ratingStar starProfile">
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
          <img
            src="assets/amulab1/img/star.svg"
            className="gubergren m-0"
            alt="star"
          />
        </div>
      );
    default:
      return "Pas encore de note";
  }
};

export default ReviewProfileYes;
