import {
  GET_ALL_COMMUNITY_NO,
  GET_ALL_COMMUNITY_NO_AMBASSADOR,
  GET_ALL_COMMUNITY_NO_AMBASSADOR_FAIL,
  GET_ALL_COMMUNITY_NO_AMBASSADOR_SUCCESS,
  GET_ALL_COMMUNITY_NO_DELEGATE,
  GET_ALL_COMMUNITY_NO_DELEGATE_FAIL,
  GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS,
  GET_ALL_COMMUNITY_NO_FAIL,
  GET_ALL_COMMUNITY_NO_LEADERS,
  GET_ALL_COMMUNITY_NO_LEADERS_FAIL,
  GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS,
  GET_ALL_COMMUNITY_NO_MEMBERS,
  GET_ALL_COMMUNITY_NO_MEMBERS_FAIL,
  GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS,
  GET_ALL_COMMUNITY_NO_SUCCESS,
  GET_ALL_COMMUNITY_NO_TEAM,
  GET_ALL_COMMUNITY_NO_TEAM_FAIL,
  GET_ALL_COMMUNITY_NO_TEAM_SUCCESS,
  GET_ALL_COMMUNITY_YES,
  GET_ALL_COMMUNITY_YES_AMBASSADOR,
  GET_ALL_COMMUNITY_YES_AMBASSADOR_FAIL,
  GET_ALL_COMMUNITY_YES_AMBASSADOR_SUCCESS,
  GET_ALL_COMMUNITY_YES_DELEGATE,
  GET_ALL_COMMUNITY_YES_DELEGATE_FAIL,
  GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS,
  GET_ALL_COMMUNITY_YES_FAIL,
  GET_ALL_COMMUNITY_YES_LEADERS,
  GET_ALL_COMMUNITY_YES_LEADERS_FAIL,
  GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS,
  GET_ALL_COMMUNITY_YES_MEMBERS,
  GET_ALL_COMMUNITY_YES_MEMBERS_FAIL,
  GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS,
  GET_ALL_COMMUNITY_YES_SUCCESS,
  GET_ALL_COMMUNITY_YES_TEAM,
  GET_ALL_COMMUNITY_YES_TEAM_FAIL,
  GET_ALL_COMMUNITY_YES_TEAM_SUCCESS,
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_FAIL,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS,
  GET_ALL_SUBSCRIBERS_SUCCESS,
  SET_CURRENT_PAGE,
  SET_CURRENT_PAGE_SUBSCRIBERS,
} from "./actionTypes";

const initialState = {
  subscribers: [],
  subscribersProfileList: [],
  yesCommunity: [],
  yesCommunityTeam: [],
  yesCommunityAmbassador: [],
  yesCommunityDelegate: [],
  yesCommunityLeaders: [],
  yesCommunityMembers: [],
  noCommunity: [],
  noCommunityTeam: [],
  noCommunityAmbassador: [],
  noCommunityDelegate: [],
  noCommunityLeaders: [],
  noCommunityMembers: [],
  loading: false,
  error: null,
  currentPage: 1,
  currentPageSubs: 1,
  paginationData: {},
  paginationData1: {},
};

const subscribersPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIBERS:
      return { ...state, loading: true };
    case GET_ALL_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscribers: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_SUBSCRIBERS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };

    case GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE:
      return { ...state, loading: true };
    case GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        subscribersProfileList: action.payload.data,
        paginationData1: action.payload.data,
      };
    case GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SET_CURRENT_PAGE_SUBSCRIBERS:
      return { ...state, currentPageSubs: action.payload };

    case GET_ALL_COMMUNITY_YES:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunity: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_YES_TEAM:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunityTeam: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_TEAM_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_YES_AMBASSADOR:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_AMBASSADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunityAmbassador: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_AMBASSADOR_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_YES_DELEGATE:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunityDelegate: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_DELEGATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_YES_LEADERS:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunityLeaders: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_LEADERS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_YES_MEMBERS:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        yesCommunityMembers: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_YES_MEMBERS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunity: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO_TEAM:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunityTeam: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_TEAM_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO_AMBASSADOR:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_AMBASSADOR_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunityAmbassador: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_AMBASSADOR_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO_DELEGATE:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunityDelegate: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_DELEGATE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO_LEADERS:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunityLeaders: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_LEADERS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_COMMUNITY_NO_MEMBERS:
      return { ...state, loading: true };
    case GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        noCommunityMembers: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_COMMUNITY_NO_MEMBERS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default subscribersPageReducer;
