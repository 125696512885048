export const GET_ALL_SUBSCRIBERS = "GET_ALL_SUBSCRIBERS";
export const GET_ALL_SUBSCRIBERS_SUCCESS = "GET_ALL_SUBSCRIBERS_SUCCESS";
export const GET_ALL_SUBSCRIBERS_FAIL = "GET_ALL_SUBSCRIBERS_FAIL";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_CURRENT_PAGE_SUBSCRIBERS = "SET_CURRENT_PAGE_SUBSCRIBERS";

export const GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE =
  "GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE";
export const GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS =
  "GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS";
export const GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL =
  "GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL";

export const GET_ALL_COMMUNITY_YES = "GET_ALL_COMMUNITY_YES";
export const GET_ALL_COMMUNITY_YES_SUCCESS = "GET_ALL_COMMUNITY_YES_SUCCESS";
export const GET_ALL_COMMUNITY_YES_FAIL = "GET_ALL_COMMUNITY_YES_FAIL";

export const GET_ALL_COMMUNITY_YES_TEAM = "GET_ALL_COMMUNITY_YES_TEAM";
export const GET_ALL_COMMUNITY_YES_TEAM_SUCCESS =
  "GET_ALL_COMMUNITY_YES_TEAM_SUCCESS";
export const GET_ALL_COMMUNITY_YES_TEAM_FAIL =
  "GET_ALL_COMMUNITY_YES_TEAM_FAIL";

export const GET_ALL_COMMUNITY_YES_AMBASSADOR =
  "GET_ALL_COMMUNITY_YES_AMBASSADOR";
export const GET_ALL_COMMUNITY_YES_AMBASSADOR_SUCCESS =
  "GET_ALL_COMMUNITY_YES_TEAM_SUCCESS";
export const GET_ALL_COMMUNITY_YES_AMBASSADOR_FAIL =
  "GET_ALL_COMMUNITY_YES_TEAM_FAIL";

export const GET_ALL_COMMUNITY_YES_DELEGATE = "GET_ALL_COMMUNITY_YES_DELEGATE";
export const GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS =
  "GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS";
export const GET_ALL_COMMUNITY_YES_DELEGATE_FAIL =
  "GET_ALL_COMMUNITY_YES_DELEGATE_FAIL";

export const GET_ALL_COMMUNITY_YES_LEADERS = "GET_ALL_COMMUNITY_YES_LEADERS";
export const GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS =
  "GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS";
export const GET_ALL_COMMUNITY_YES_LEADERS_FAIL =
  "GET_ALL_COMMUNITY_YES_LEADERS_FAIL";

export const GET_ALL_COMMUNITY_YES_MEMBERS = "GET_ALL_COMMUNITY_YES_MEMBERS";
export const GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS =
  "GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS";
export const GET_ALL_COMMUNITY_YES_MEMBERS_FAIL =
  "GET_ALL_COMMUNITY_YES_MEMBERS_FAIL";

export const GET_ALL_COMMUNITY_NO = "GET_ALL_COMMUNITY_NO";
export const GET_ALL_COMMUNITY_NO_SUCCESS = "GET_ALL_COMMUNITY_NO_SUCCESS";
export const GET_ALL_COMMUNITY_NO_FAIL = "GET_ALL_COMMUNITY_NO_FAIL";

export const GET_ALL_COMMUNITY_NO_TEAM = "GET_ALL_COMMUNITY_NO_TEAM";
export const GET_ALL_COMMUNITY_NO_TEAM_SUCCESS =
  "GET_ALL_COMMUNITY_NO_TEAM_SUCCESS";
export const GET_ALL_COMMUNITY_NO_TEAM_FAIL = "GET_ALL_COMMUNITY_NO_TEAM_FAIL";

export const GET_ALL_COMMUNITY_NO_AMBASSADOR =
  "GET_ALL_COMMUNITY_NO_AMBASSADOR";
export const GET_ALL_COMMUNITY_NO_AMBASSADOR_SUCCESS =
  "GET_ALL_COMMUNITY_NO_TEAM_SUCCESS";
export const GET_ALL_COMMUNITY_NO_AMBASSADOR_FAIL =
  "GET_ALL_COMMUNITY_NO_TEAM_FAIL";

export const GET_ALL_COMMUNITY_NO_DELEGATE = "GET_ALL_COMMUNITY_NO_DELEGATE";
export const GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS =
  "GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS";
export const GET_ALL_COMMUNITY_NO_DELEGATE_FAIL =
  "GET_ALL_COMMUNITY_NO_DELEGATE_FAIL";

export const GET_ALL_COMMUNITY_NO_LEADERS = "GET_ALL_COMMUNITY_NO_LEADERS";
export const GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS =
  "GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS";
export const GET_ALL_COMMUNITY_NO_LEADERS_FAIL =
  "GET_ALL_COMMUNITY_NO_LEADERS_FAIL";

export const GET_ALL_COMMUNITY_NO_MEMBERS = "GET_ALL_COMMUNITY_NO_MEMBERS";
export const GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS =
  "GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS";
export const GET_ALL_COMMUNITY_NO_MEMBERS_FAIL =
  "GET_ALL_COMMUNITY_NO_MEMBERS_FAIL";
