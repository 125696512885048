import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects";
import {
  getUserProfileTypeSuccess,
  getUserProfileTypeFail,
  updateUserProfile_ppSuccess,
  updateUserProfile_ppFail,
  getAllSubscribersProfileSuccess,
  getAllSubscribersProfileFail,
  updateUserProfile_descriptionSuccess,
  updateUserProfile_descriptionFail,
  updateUserProfile_youtubeSuccess,
  updateUserProfile_youtubeFail,
  updateUserProfile_typeSuccess,
  updateUserProfile_typeFail,
  updateUserProfile_usernameSuccess,
  updateUserProfile_usernameFail,
  updateUserProfile_nameSuccess,
  updateUserProfile_nameFail,
  buttonUserSubscribeProfileSuccess,
  buttonUserSubscribeProfileFail,
  notfoundData,
  getUserProfile_a_Success,
  getUserProfile_a_Fail,
  claimUserProfilePageSuccess,
  claimUserProfilePageFail,
} from "./actions";

import {
  CLAIM_PROFILE_PAGE,
  GET_ALL_SUBSCRIBERS_PROFILE,
  GET_USER_PROFILE,
  GET_USER_PROFILE_TYPE,
  SUBSCRIBE_BUTTON,
  UPDATE_USER_PROFILE_DESCRIPTION,
  UPDATE_USER_PROFILE_NAME,
  UPDATE_USER_PROFILE_PP,
  UPDATE_USER_PROFILE_TYPE,
  UPDATE_USER_PROFILE_USERNAME,
  UPDATE_USER_PROFILE_YOUTUBE_LINK,
} from "./actionTypes";
import {
  getAllSubscribersProfileData,
  getAllUsersType,
  getBrowserLanguage,
  getUserProfile,
  subscribeUserProfile,
  updateUserProfile,
  updateUserProfileClaim,
  updateUserProfile_pp,
} from "../../helpers/api_helper";
import { CHANGE_LANGUAGE } from "../components_pages/actionTypes";
// Worker Saga

function* fetchUserProfileSaga(action) {
  try {
    const response = yield call(getUserProfile, action.payload);
    if (response.returnCode === 200) {
      yield put(getUserProfile_a_Success(response));
    } else if (response.returnCode === 404) {
      // alert("404");
      yield put(notfoundData());
    }
  } catch (error) {
    yield put(getUserProfile_a_Fail(error.toString()));
  }
}

function* fetchAllUsersTypesSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));

    const languageCode = `${
      newLanguage.substring(0, 2) === "fr" ? "fra" : "eng"
    }`;

    const response = yield call(getAllUsersType, languageCode);
    yield put(getUserProfileTypeSuccess(response));
  } catch (error) {
    yield put(getUserProfileTypeFail(error.toString()));
  }
}

function* fetchAllSubscribersProfileSaga(action) {
  try {
    const response = yield call(getAllSubscribersProfileData, action.payload);
    yield put(getAllSubscribersProfileSuccess(response));
  } catch (error) {
    yield put(getAllSubscribersProfileFail(error.toString()));
  }
}

function* updateUserProfile_p(action) {
  try {
    const response = yield call(updateUserProfile_pp, action.payload);
    if (response.data.returnCode === 200) {
      yield put(updateUserProfile_ppSuccess(response.data));
    }
  } catch (error) {
    yield put(updateUserProfile_ppFail(error.toString()));
  }
}

function* updateName(action) {
  try {
    const response = yield call(updateUserProfile, action.payload);
    if (response.data.returnCode === 200) {
      yield put(updateUserProfile_nameSuccess(response));
    }
  } catch (error) {
    yield put(updateUserProfile_nameFail(error.toString()));
  }
}

function* updateUserUsername(action) {
  try {
    const response = yield call(updateUserProfile, action.payload);
    if (response.data.returnCode === 200) {
      yield put(updateUserProfile_usernameSuccess(response));
    } else {
      let errorMessage = "Une erreur s'est produite vérifier votre réseau.";
      switch (response.data.returnCode) {
        case 58:
          yield put(updateUserProfile_usernameFail());

          break;
        default:
          errorMessage = response.data.message || errorMessage;
          break;
      }
      yield put(updateUserProfile_usernameFail(errorMessage));
    }
  } catch (error) {
    yield put(updateUserProfile_usernameFail(error.toString()));
  }
}

function* updateUserYoutubeLink(action) {
  try {
    const response = yield call(updateUserProfile, action.payload);
    if (response.data.returnCode === 200) {
      yield put(updateUserProfile_youtubeSuccess(response.data));
    } else {
      let errorMessage = "Le lien youtube existe déjà";
      switch (response.data.returnCode) {
        case 404:
          yield put(
            updateUserProfile_youtubeFail("impossible de traiter votre demande")
          );
          // errorMessage = "impossible de traiter votre demande";
          break;
        case 175:
          yield put(
            updateUserProfile_youtubeFail("Le lien YouTube existe déjà")
          );
          // errorMessage = "Le lien YouTube existe déjà";
          break;
        default:
          errorMessage = response.data.message || errorMessage;
          break;
      }
      yield put(updateUserProfile_youtubeFail(errorMessage));
    }
  } catch (error) {
    yield put(updateUserProfile_youtubeFail(error.toString));
  }
}

function* updateUserType(action) {
  try {
    const response = yield call(updateUserProfile, action.payload);
    yield put(updateUserProfile_typeSuccess(response));
  } catch (error) {
    yield put(updateUserProfile_typeFail(error.toString()));
  }
}

function* updateUserDescription(action) {
  try {
    const response = yield call(updateUserProfile, action.payload);
    yield put(updateUserProfile_descriptionSuccess(response));
  } catch (error) {
    yield put(updateUserProfile_descriptionFail(error.toString()));
  }
}

function* subscribeButtonSaga(action) {
  try {
    const response = yield call(subscribeUserProfile, action.payload);
    if (response.data && response.data.returnCode === 200) {
      yield put(buttonUserSubscribeProfileSuccess(response));
    } else if (
      (response.data && response.data.returnCode === 175) ||
      response.data.returnCode === 404
    ) {
      yield put(buttonUserSubscribeProfileFail(response));
    }
  } catch (error) {
    yield put(buttonUserSubscribeProfileFail(error.toString()));
  }
}

function* claimUserPageSaga(action) {
  try {
    const response = yield call(updateUserProfileClaim, action.payload);
    yield put(claimUserProfilePageSuccess(response));
  } catch (error) {
    yield put(claimUserProfilePageFail(error.toString()));
  }
}

// Watcher Saga
export default function* AccountPagesSaga() {
  yield takeEvery(GET_USER_PROFILE, fetchUserProfileSaga);
  yield takeEvery(
    [GET_USER_PROFILE_TYPE, CHANGE_LANGUAGE],
    fetchAllUsersTypesSaga
  );
  yield takeEvery(GET_ALL_SUBSCRIBERS_PROFILE, fetchAllSubscribersProfileSaga);
  yield takeEvery(UPDATE_USER_PROFILE_USERNAME, updateUserUsername);
  yield takeEvery(UPDATE_USER_PROFILE_NAME, updateName);
  yield takeEvery(UPDATE_USER_PROFILE_YOUTUBE_LINK, updateUserYoutubeLink);
  yield takeEvery(UPDATE_USER_PROFILE_TYPE, updateUserType);
  yield takeEvery(UPDATE_USER_PROFILE_DESCRIPTION, updateUserDescription);
  yield takeLatest(UPDATE_USER_PROFILE_PP, updateUserProfile_p);
  yield takeLatest(SUBSCRIBE_BUTTON, subscribeButtonSaga);
  yield takeLatest(CLAIM_PROFILE_PAGE, claimUserPageSaga);
}
