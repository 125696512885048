import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { changeLanguage, logoutUser } from "../store/actions";
import { useNavigate } from "react-router-dom";
import "./style.css";
// import { FiMenu } from "react-icons/fi";
// import { IoIosCloseCircle } from "react-icons/io";
import { LuUserCircle2 } from "react-icons/lu";
import Cookies from "js-cookie";

const Header = ({
  data_components,
  data_page_components,
  loading,
  error,
  language,
  dispatch,
  user,
}) => {
  const handleLanguageChange = (newLanguage) => {
    dispatch(changeLanguage(newLanguage));
    localStorage.setItem("selectedLanguage", newLanguage);
    // Forcer un re-rendu en changeant un état
    // setIsTextDataLoaded((prevState) => !prevState);
  };
  const [isTextDataLoaded, setIsTextDataLoaded] = useState(false);
  // const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const token = localStorage.getItem("authToken");
  const [isProfile, setIsProfile] = useState(false);
  // const closeNav = () => {
  //   setIsMobileNavOpen(false);
  // };
  // const toggleMobileNav = () => {
  //   setIsMobileNavOpen(!isMobileNavOpen);
  // };
  const navigate = useNavigate();

  const checkUser = Cookies.get("checkProfile");
  useEffect(() => {
    if (checkUser) {
      setIsProfile(true);
    }
  }, [checkUser]);

  useEffect(() => {
    if (data_components) {
      setIsTextDataLoaded(true);
    }
  }, [data_components, isTextDataLoaded]);

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handleLogout = () => {
    // closeNav();
    dispatch(logoutUser({ navigate }));
  };
  // useEffect(() => {
  //   closeNav();
  // }, []);
  // Convertir data_page_components en un objet
  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item?.value;
    return obj;
  }, {});

  // function getContentByKey(key) {
  //   const item = data_components.find((item) => item.key === key);
  //   return item?.contents[0];
  // }

  return (
    <div id="wrapper">
      <header className="">
        <div className="header-section">
          <div className="header-top">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 d-xl-flex align-items-center justify-content-between justify-content-xl-start py-0 py-xl-1 px-2">
                  <div className="top-left d-flex align-items-center justify-content-between justify-content-xl-start">
                    <div className="header_logo_left">
                      <Link className="d-block" to="/">
                        <img
                          className="lazy"
                          src="assets/amulab2/images/amulab-logo-svg.svg"
                          alt="amulab-logo"
                          title="Amulab-Logo"
                        />
                      </Link>
                    </div>
                    <div className="header-drop ms-0 ms-lg-4 text-end text-xl-start">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle text-start p-0 logo-select"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Africa Must Unite
                          <span className="d-block fw-bold">Laboratory</span>
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <Link className="dropdown-item" to="/">
                              Laboratoire
                              <span className="d-block fw-bold">
                                Africa Must Unite
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="top-right d-flex align-items-center justify-content-center justify-content-xl-end">
                    <ul className="list d-flex align-items-center d-none d-xl-flex">
                      {(!token || token === "undefined") && (
                        <>
                          <li>
                            <Link
                              className="btn btn-primary"
                              id="login"
                              to="https://account.duegho.com/auth/login/bd84403b2552478f9e535090b5c6af1b"
                              // to="/login"
                            >
                              {data_page_components_obj.login}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="https://account.duegho.com/auth/register/bd84403b2552478f9e535090b5c6af1b"
                              // to="https://account.duegho.com/register"
                              title="Register"
                              id="register"
                            >
                              {data_page_components_obj.register}
                            </Link>
                          </li>
                        </>
                      )}
                      {token && token !== "undefined" && (
                        <li>
                          {" "}
                          <div className="account d-none d-xl-flex">
                            <div className="dropdown">
                              <button
                                className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                id="language"
                              >
                                <LuUserCircle2 size={50} />
                              </button>
                              <ul className="dropdown-menu">
                                {isProfile ? (
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleNavigation(`/${checkUser}`)
                                      }
                                      className="dropdown-item style_account"
                                      title="Profile"
                                    >
                                      {data_page_components_obj.profile}
                                    </button>
                                  </li>
                                ) : (
                                  ""
                                )}

                                <li>
                                  <button
                                    className="dropdown-item style_account"
                                    title="Déconnexion"
                                    onClick={handleLogout}
                                  >
                                    {data_page_components_obj.logout}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>{" "}
                        </li>
                      )}
                    </ul>

                    <div className="lag-select d-none d-xl-flex">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Language"
                          id="language"
                        >
                          {language === "fr"
                            ? data_page_components_obj.languageFrench
                            : data_page_components_obj.languageEnglish}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              to="#"
                              className="dropdown-item"
                              title="English"
                              id="en_langue"
                              onClick={() => handleLanguageChange("en")}
                            >
                              {data_page_components_obj.languageEnglish}
                            </button>
                          </li>
                          <li>
                            <button
                              to="#"
                              className="dropdown-item"
                              title="French"
                              id="fr_langue"
                              onClick={() => handleLanguageChange("fr")}
                            >
                              {data_page_components_obj.languageFrench}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-bg">
            <div className="container">
              <div className="row">
                <div className="header-part col-sm-12 p-0">
                  <div className="win-header d-md-flex">
                    <div className="wins_menu_right d-flex align-items-center justify-content-between w-100 align_mobile">
                      {/* <div className="menu_open_close text-right">
                        <FiMenu
                          size={50}
                          className="menu__open"
                          onClick={toggleMobileNav}
                        />
                      </div> */}
                      <ul
                        className="list d-flex align-items-center d-xl-none"
                        style={{ padding: "9px 5px" }}
                      >
                        {token === "undefined" ||
                          (!token && (
                            <>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  data-bs-target="#exampleModal"
                                  to="https://account.duegho.com/auth/login/bd84403b2552478f9e535090b5c6af1b"
                                  // to="/login"
                                >
                                  {data_page_components_obj.login}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="https://account.duegho.com/auth/register/bd84403b2552478f9e535090b5c6af1b"
                                  // to="https://account.duegho.com/register"
                                  title="Register"
                                >
                                  {data_page_components_obj.register}
                                </Link>
                              </li>
                            </>
                          ))}
                        {token && (
                          <li>
                            <div className="account d-xl-flex">
                              <div className="dropdown">
                                <button
                                  className="btn dropdown-toggle d-flex align-items-center justify-content-start"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  title="Language"
                                  id="language"
                                >
                                  <LuUserCircle2
                                    size={40}
                                    color="white"
                                    style={{
                                      marginTop: "5px",
                                      marginRight: "-15px",
                                    }}
                                  />
                                </button>
                                <ul className="dropdown-menu">
                                  {isProfile ? (
                                    <li>
                                      <button
                                        onClick={() =>
                                          handleNavigation(`/${checkUser}`)
                                        }
                                        className="dropdown-item style_account"
                                        title="Profile"
                                      >
                                        {data_page_components_obj.profile}
                                      </button>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li>
                                    <button
                                      className="dropdown-item style_account"
                                      onClick={() => handleLogout()}
                                    >
                                      {data_page_components_obj.logout}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>

                      {/* <div className="nav-overlay" onclick="closeNav()"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_components: state.ComponentsDataReducer.data_components,
  loading: state.ComponentsDataReducer.loading,
  error: state.ComponentsDataReducer.error,
  language: state.ComponentsDataReducer.language,
  data_page_components: state.DataPageReducer.data_page_components,
  user: state.LoginReducer.user,
});

export default connect(mapStateToProps)(Header);
