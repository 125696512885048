import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Input, Label } from "reactstrap";
import { CiCamera } from "react-icons/ci";
import { updateUserProfile_pp } from "../../../store/actions";
import { BASE_API_URL_AVATAR } from "../../../helpers/url_helper";

const ProfileAvatar = (props) => {
  const avatarMedia = props?.user?.profile?.medias?.find(
    (media) => media.type === "avatar"
  );

  const [avatarKey, setAvatarKey] = useState(avatarMedia?.key);
  const [avatar, setAvatar] = useState(
    `${BASE_API_URL_AVATAR + "/" + avatarKey}`
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // Appeler onAvatarChange uniquement lorsque la valeur change
    props.onAvatarChange(avatar);
  }, [avatar, props]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("avatar", file);

    const url = URL.createObjectURL(file);
    setAvatarKey(url);
    setAvatar(url);
    props.onAvatarChange(url);
    dispatch(updateUserProfile_pp(formData));
  };

  return (
    <div>
      <FormGroup>
        <Label for="exampleFile">
          {" "}
          <FormGroup>
            <Input type="file" name="file"></Input>
            <CiCamera className="camera" />
          </FormGroup>
        </Label>
        <Input
          type="file"
          name="file"
          id="exampleFile"
          onChange={handleFileChange}
        />
      </FormGroup>
    </div>
  );
};

export default ProfileAvatar;
