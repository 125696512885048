import { all, fork } from "redux-saga/effects";
import NationsSaga from "./nations/saga";
import DataComponentsSaga from "./components_pages/saga";
import DataPagesSaga from "./master_pages/saga";
import authSaga from "./login/sagas";
import SubscribersPagesSaga from "./community/saga";
import AccountPagesSaga from "./account/saga";

export default function* rootSaga() {
  yield all([
    fork(NationsSaga),
    fork(DataComponentsSaga),
    fork(DataPagesSaga),
    fork(authSaga),
    fork(SubscribersPagesSaga),
    fork(AccountPagesSaga),
  ]);
}
