import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
  resetUpdateSuccessName,
  updateUserProfile_name,
} from "../../../store/actions";
import Swal from "sweetalert2";

const NameModal = (props) => {
  const { loadingModalName, data_page_components, updateSuccessName } = props;
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const apiKey = process.env.REACT_APP_API_KEY_DATA;
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const data = {
    apiKey: apiKey,
    name: name,
  };

  const handleUpdate = () => {
    let errors = {};
    if (name.length < 3) {
      errors["name"] = "Name must be at least 3 characters long.";
    }
    if (name.length > 50) {
      errors["name"] = "Name must be less than 15 characters long.";
    }
    if (!name) {
      errors["name"] = "Name is required.";
    }
    if (name && name.length >= 3 && name.length <= 15) {
      dispatch(updateUserProfile_name(data));
    }
    setErrors(errors);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    if (updateSuccessName) {
      props.onUpdateName(name);
      props.toggleName();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSuccessSaved}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateSuccessName());
    }
  }, [
    dispatch,
    data_page_components_obj.profileSuccessSaved,
    updateSuccessName,
    name,
    props,
  ]);

  return (
    <div>
      <Modal isOpen={props.modal_name} toggle={props.toggleName}>
        <ModalHeader toggle={props.toggleName}>
          {" "}
          {data_page_components_obj.profileNameUpdateTitle}
        </ModalHeader>
        <ModalBody>
          {" "}
          <FormGroup>
            <Input defaultValue={props.full_name} onChange={handleChange} />
          </FormGroup>
          <div className="text-danger">{errors.name}</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleName}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleUpdate}>
            {loadingModalName ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
  loadingModalName: state.UserProfilePageReducer.loadingModalName,
  error: state.UserProfilePageReducer.error,
  updateSuccessName: state.UserProfilePageReducer.updateSuccessName,
});

export default connect(mapStateToProps)(NameModal);
