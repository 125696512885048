import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../style.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useDispatch, connect } from "react-redux";
import { resetNotfoundData } from "../../store/actions";

const NotFound = ({ data_page_components }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetNotfoundData());
  }, [dispatch]);

  const data_page_components_obj = data_page_components?.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  return (
    <div id="wrapper">
      <Header />
      <div className="not-found">
        <h1> {data_page_components_obj.pageNotFoundTitle}</h1>
        <p> {data_page_components_obj.pageNotFoundDesc}</p>
        <img
          src="assets/amulab2/images/404.jpg"
          alt="404 Not Found"
          width={300}
        />
        <br />
        <p>
          <Link to="https://account.duegho.com/auth/login/bd84403b2552478f9e535090b5c6af1b">
            {data_page_components_obj.login}
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
});

export default connect(mapStateToProps)(NotFound);
