import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
  resetUpdateSuccessUsername,
  resetUsernameExist,
  updateUserProfile_username,
} from "../../../store/actions";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const UsernameModal = (props) => {
  const {
    loadingModalUsername,
    data_page_components,
    updateSuccessUsername,
    errorUsername,
  } = props;
  const [username, setUsername] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const apiKey = process.env.REACT_APP_API_KEY_DATA;
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const data = {
    apiKey: apiKey,
    username: username,
  };

  const handleUpdate = () => {
    let errors = {};
    if (username.length < 3) {
      errors["username"] =
        "Le nom d'utilisateur doit comporter au moins 3 caractères.";
    }
    if (username.length > 12) {
      errors["username"] =
        "Le nom d'utilisateur doit comporter moins de 12 caractères.";
    }
    if (!username) {
      errors["username"] = "Le nom d'utilisateur est requis.";
    }
    if (username && username.length >= 3 && username.length <= 15) {
      dispatch(updateUserProfile_username(data));
    }
    setErrors(errors);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    if (updateSuccessUsername) {
      props.onUpdateUsername(username);
      props.toggleUsername();
      Cookies.set("checkProfile", username);
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSuccessSaved}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateSuccessUsername());
    } else if (errorUsername) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `L'utilisateur existe déjà. Veuillez en choisir un autre.`,
        showConfirmButton: true,
      });
      dispatch(resetUsernameExist());
    }
  }, [
    dispatch,
    updateSuccessUsername,
    data_page_components_obj.profileSuccessSaved,
    username,
    props,
    errorUsername,
  ]);

  return (
    <div>
      <Modal isOpen={props.modalUsername} toggle={props.toggleUsername}>
        <ModalHeader toggle={props.toggleUsername}>
          {data_page_components_obj.profileUsernameUpdateTitle}
        </ModalHeader>
        <ModalBody>
          {" "}
          <FormGroup>
            <Input defaultValue={props.username_} onChange={handleChange} />
          </FormGroup>
          <span style={{ color: "red" }}>{errors["username"]}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleUsername}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleUpdate}>
            {loadingModalUsername ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
  loadingModalUsername: state.UserProfilePageReducer.loadingModalUsername,
  error: state.UserProfilePageReducer.error,
  updateSuccessUsername: state.UserProfilePageReducer.updateSuccessUsername,
  errorUsername: state.UserProfilePageReducer.errorUsername,
});

export default connect(mapStateToProps)(UsernameModal);
