import {
  GET_ALL_NATIONS,
  GET_ALL_NATIONS_FAIL,
  GET_ALL_NATIONS_LIST,
  GET_ALL_NATIONS_LIST_FAIL,
  GET_ALL_NATIONS_LIST_SUCCESS,
  GET_ALL_NATIONS_SUCCESS,
} from "./actionTypes";

const initialState = {
  all_nations: [],
  nations_list: [],
  loading: false,
  error: null,
};

const nationsMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NATIONS:
      return { ...state, loading: true };
    case GET_ALL_NATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        all_nations: action.payload.features,
      };
    case GET_ALL_NATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_NATIONS_LIST:
      return { ...state, loading: true };
    case GET_ALL_NATIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        nations_list: action.payload.data,
      };
    case GET_ALL_NATIONS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default nationsMapReducer;
