import React from "react";
import "../style.css";

const MaintenancePage = () => {
  return (
    <div className="maintenance-page">
      <div className="maintenance-content">
        <img
          src="assets/amulab2/images/maintenance.png"
          alt="Illustration de clé"
          className="maintenance-image"
          height={100}
          width={300}
        />
        <h1>En maintenance</h1>
        <p>
          Nous travaillons actuellement sur le site pour l'améliorer. Merci de
          revenir plus tard.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
