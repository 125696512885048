import {
  GET_ALL_NATIONS,
  GET_ALL_NATIONS_FAIL,
  GET_ALL_NATIONS_LIST,
  GET_ALL_NATIONS_LIST_FAIL,
  GET_ALL_NATIONS_LIST_SUCCESS,
  GET_ALL_NATIONS_SUCCESS,
} from "./actionTypes";

export const getAllNations = (data) => ({
  type: GET_ALL_NATIONS,
  payload: data,
});

export const getAllNationsSuccess = (all_nation) => ({
  type: GET_ALL_NATIONS_SUCCESS,
  payload: all_nation,
});

export const getAllNationsFail = (error) => ({
  type: GET_ALL_NATIONS_FAIL,
  payload: error,
});

export const getAllNationsList = (data) => ({
  type: GET_ALL_NATIONS_LIST,
  payload: data,
});

export const getAllNationsListSuccess = (list_nation) => ({
  type: GET_ALL_NATIONS_LIST_SUCCESS,
  payload: list_nation,
});

export const getAllNationsListFail = (error) => ({
  type: GET_ALL_NATIONS_LIST_FAIL,
  payload: error,
});
