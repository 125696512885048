import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
  buttonUserSubscribeProfile,
  resetButtonUserSubscribeProfile,
} from "../../../store/actions";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const SubscribeModal = (props) => {
  const {
    data_page_components,
    loadingModalSubscribe,
    // errorSubscribe,
    subscribeSuccess,
  } = props;
  const { username } = useParams();
  const [ipAddress, setIpAddress] = useState("");
  const [device, setDevice] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");

  const dispatch = useDispatch();
  const apiKey = process.env.REACT_APP_API_KEY_DATA;

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  fetch("https://ipapi.co/json/")
    .then((response) => response.json())
    .then((data) => setIpAddress(data.ip));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDevice("Mobile");
      } else if (window.innerWidth < 992) {
        setDevice("Tablet");
      } else {
        setDevice("Desktop");
      }
    };

    // Exécutez la fonction une fois au chargement initial
    handleResize();

    // Exécutez la fonction chaque fois que la fenêtre est redimensionnée
    window.addEventListener("resize", handleResize);

    // Supprimez l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setDeviceInfo(navigator.userAgent);
  }, []);

  const data = {
    apiKey: apiKey,
    profileId: username,
    profileIdTypeCode: "username",
    appType: "web",
    deviceType: device,
    deviceInfo: deviceInfo,
    ipAddress: ipAddress,
  };

  const handleSubscribe = () => {
    dispatch(buttonUserSubscribeProfile(data));
  };
  useEffect(() => {
    if (subscribeSuccess) {
      Cookies.set("checkProfile", username);
      props.toggleSubscribe();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSubscribeSuccess}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetButtonUserSubscribeProfile());
    }
  }, [
    dispatch,
    subscribeSuccess,
    data_page_components_obj.profileSubscribeSuccess,
    props,
    username,
  ]);

  // useEffect(() => {
  //   if (errorSubscribe === "action.payload") {
  //     Swal.fire({
  //       position: "center",
  //       icon: "error",
  //       title: `impossible de s'abonné à ce profile`,
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //   }
  // }, [errorSubscribe]);

  return (
    <div>
      <Modal isOpen={props.modalSubscribe} toggle={props.toggleSubscribe}>
        <ModalHeader toggle={props.toggleSubscribe}>
          {/* {data_page_components[140]?.value} */}
        </ModalHeader>
        <ModalBody>{data_page_components_obj.profileSubscribeAlert}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleSubscribe}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleSubscribe}>
            {loadingModalSubscribe ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // errorSubscribe: state.UserProfilePageReducer.errorSubscribe,
  data_page_components: state.DataPageReducer.data_page_components,
  loadingModalSubscribe: state.UserProfilePageReducer.loadingModalSubscribe,
  subscribeSuccess: state.UserProfilePageReducer.subscribeSuccess,
});

export default connect(mapStateToProps)(SubscribeModal);
