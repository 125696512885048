// import React, { useState, useEffect } from "react";
// import {
//   Modal,
//   Button,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   FormGroup,
//   Input,
//   Label,
//   Spinner,
// } from "reactstrap";
// import { connect, useDispatch } from "react-redux";
// import {
//   resetUpdateSuccessYoutubeLink,
//   updateUserProfile_youtube,
// } from "../../../store/actions";
// import Swal from "sweetalert2";

// const YoutubeModal = (props) => {
//   const { data_page_components, loadingModalYoutube, updateSuccessYoutube } =
//     props;
//   const [selectedOption, setSelectedOption] = useState("");
//   const [youtubeLink, setYoutubeLink] = useState("");

//   const dispatch = useDispatch();

//   const data = {
//     apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
//     youtubeVideoUrlAddInd: selectedOption,
//     youtubeVideoUrl: youtubeLink,
//   };

//   const handleUpdate = () => {
//     dispatch(updateUserProfile_youtube(data));
//   };

//   const data_page_components_obj = data_page_components.reduce((obj, item) => {
//     obj[item.key] = item.value;
//     return obj;
//   }, {});

//   useEffect(() => {
//     if (updateSuccessYoutube) {
//       props.onUpdateYoutube(youtubeLink);
//       props.toggleYoutube();
//       Swal.fire({
//         position: "center",
//         icon: "success",
//         title: `${data_page_components_obj.profileSuccessSaved}`,
//         showConfirmButton: false,
//         timer: 2000,
//       });
//       dispatch(resetUpdateSuccessYoutubeLink());
//     }
//   }, [
//     dispatch,
//     data_page_components_obj.profileSuccessSaved,
//     updateSuccessYoutube,
//     props,
//     youtubeLink,
//   ]);

//   return (
//     <div>
//       <Modal isOpen={props.modalYoutube} toggle={props.toggleYoutube}>
//         <ModalHeader toggle={props.toggleYoutube}>
//           {" "}
//           {data_page_components_obj.profileYoutubeUrlUpdateTitle}
//         </ModalHeader>
//         <ModalBody>
//           {" "}
//           <FormGroup>
//             <Input
//               type="url"
//               name="url"
//               id="exampleUrl"
//               placeholder="url youtube"
//               defaultValue={props.youtube}
//               onChange={(e) => setYoutubeLink(e.target.value)}
//             />
//           </FormGroup>
//           <FormGroup tag="fieldset">
//             {/* <legend>Radio Buttons</legend> */}
//             <FormGroup check>
//               <Label check>
//                 <Input
//                   type="radio"
//                   name="radio1"
//                   className="radioInput"
//                   onChange={() => setSelectedOption("1")}
//                 />{" "}
//                 {data_page_components_obj.profileYoutubeUrlRadioAdd}
//               </Label>
//             </FormGroup>
//           </FormGroup>
//           <FormGroup check>
//             <Label check>
//               <Input
//                 type="radio"
//                 name="radio1"
//                 className="radioInput"
//                 onChange={() => setSelectedOption("0")}
//               />{" "}
//               {data_page_components_obj.profileYoutubeUrlRadioRemove}
//             </Label>
//           </FormGroup>
//         </ModalBody>
//         <ModalFooter>
//           <Button color="danger" onClick={props.toggleYoutube}>
//             {data_page_components_obj.btnCancel}
//           </Button>
//           <Button color="success" onClick={handleUpdate}>
//             {loadingModalYoutube ? (
//               <Spinner
//                 className="m-1"
//                 color="info"
//                 style={{ height: "15px", width: "15px" }}
//               />
//             ) : (
//               `${data_page_components_obj.btnSave}`
//             )}
//           </Button>
//         </ModalFooter>
//       </Modal>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   data_page_components: state.DataPageReducer.data_page_components,
//   loadingModalYoutube: state.UserProfilePageReducer.loadingModalYoutube,
//   error: state.UserProfilePageReducer.error,
//   updateSuccessYoutube: state.UserProfilePageReducer.updateSuccessYoutube,
// });

// export default connect(mapStateToProps)(YoutubeModal);
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
  resetUpdateSuccessYoutubeLink,
  resetUserProfile_youtubeFail,
  updateUserProfile_youtube,
} from "../../../store/actions";
import Swal from "sweetalert2";

const YoutubeModal = (props) => {
  const {
    data_page_components,
    loadingModalYoutube,
    updateSuccessYoutube,
    errorYoutube,
  } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [youtubeLink, setYoutubeLink] = useState(props?.youtube || "");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const api_Key = process.env.REACT_APP_API_KEY_DATA;

  const data = {
    apiKey: api_Key,
    youtubeVideoUrlAddInd: selectedOption,
    youtubeVideoUrl: youtubeLink,
  };

  const handleUpdate = () => {
    let errors = {};

    if (!youtubeLink) {
      errors.youtubeLink = "Le lien YouTube est requis";
    } else if (
      !/^https?:\/\/(www\.)?youtube\.com(\/shorts)?/.test(youtubeLink)
    ) {
      errors.youtubeLink = "Le lien doit être au format YouTube";
    }

    if (selectedOption === "") {
      errors.selectedOption = "Une option doit être sélectionnée";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(updateUserProfile_youtube(data));
    }
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    if (updateSuccessYoutube) {
      props.onUpdateYoutube(youtubeLink);
      props.toggleYoutube();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSuccessSaved}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateSuccessYoutubeLink());
    } else if (errorYoutube !== null) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${errorYoutube}`,
        showConfirmButton: true,
      });
    }
    dispatch(resetUserProfile_youtubeFail());
  }, [
    dispatch,
    data_page_components_obj.profileSuccessSaved,
    updateSuccessYoutube,
    props,
    youtubeLink,
    errorYoutube,
  ]);

  return (
    <div>
      <Modal isOpen={props.modalYoutube} toggle={props.toggleYoutube}>
        <ModalHeader toggle={props.toggleYoutube}>
          {" "}
          {data_page_components_obj.profileYoutubeUrlUpdateTitle}
        </ModalHeader>
        <ModalBody>
          {" "}
          <FormGroup>
            <Input
              type="url"
              name="url"
              id="exampleUrl"
              placeholder="url youtube"
              defaultValue={props.youtube}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
            {errors.youtubeLink && (
              <div className="error">{errors.youtubeLink}</div>
            )}
          </FormGroup>
          <FormGroup tag="fieldset">
            {/* <legend>Radio Buttons</legend> */}
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  className="radioInput"
                  onChange={() => setSelectedOption("1")}
                />{" "}
                {data_page_components_obj.profileYoutubeUrlRadioAdd}
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radio1"
                className="radioInput"
                onChange={() => setSelectedOption("0")}
              />{" "}
              {data_page_components_obj.profileYoutubeUrlRadioRemove}
            </Label>
          </FormGroup>
          {errors.selectedOption && (
            <div className="error">{errors.selectedOption}</div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleYoutube}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleUpdate}>
            {loadingModalYoutube ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
  loadingModalYoutube: state.UserProfilePageReducer.loadingModalYoutube,
  updateSuccessYoutube: state.UserProfilePageReducer.updateSuccessYoutube,
  errorYoutube: state.UserProfilePageReducer.errorYoutube,
});

export default connect(mapStateToProps)(YoutubeModal);
