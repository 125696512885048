import { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { newLoginUser, apiError } from "../../store/login/actions";
import "../style.css";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
// import Cookies from "js-cookie";

const Login = () => {
  const secretKey = process.env.REACT_APP_API_KEY_SECRET;
  const api_auth = process.env.REACT_APP_API_KEY_AUTHENTICATION;

  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();

  const generateHmacSha256Signature = useCallback(() => {
    const signature = CryptoJS.HmacSHA256(id, secretKey);
    return signature.toString(CryptoJS.enc.Hex);
  }, [id, secretKey]);

  useEffect(() => {
    const loginUser = async () => {
      const generatedSignature = generateHmacSha256Signature();
      const data = {
        apiKey: api_auth,
        id: id,
        signature: generatedSignature,
      };
      dispatch(newLoginUser(data, navigate));
    };
    loginUser();
  }, [
    dispatch,
    id,
    secretKey,
    generateHmacSha256Signature,
    api_auth,
    navigate,
  ]);
};

const mapStateToProps = (state) => ({
  loading: state.LoginReducer.loading,
  error: state.LoginReducer.error,
});

export default connect(mapStateToProps, { newLoginUser, apiError })(Login);

Login.propTypes = {
  error: PropTypes.any,
  newLoginUser: PropTypes.func,
};

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { connect } from "react-redux";
// import Header from "../../components/Header";
// import PropTypes from "prop-types";
// import { loginUser, apiError } from "../../store/login/actions";
// import { Spinner, Alert } from "reactstrap";
// import Cookies from "js-cookie";
// import "../style.css";

// const Login = (props) => {
//   const { data_page_components, loading } = props;
//   const [formData, setFormData] = useState({
//     apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
//     expiresInMinutes: 525600,
//     login: "",
//     loginTypeCode: "",
//     password: "",
//     appType: "web",
//     deviceType: "computer",
//     deviceInfo: "test",
//     ipAddress: "192.168.1.1",
//   });
//   const navigate = useNavigate();

//   const identifyLoginType = (inputValue) => {
//     const isEmail = /\S+@\S+\.\S+/.test(inputValue);
//     const isId = !isNaN(inputValue) && inputValue.length === 13;

//     if (isEmail) {
//       return "email";
//     } else if (isId) {
//       return "id";
//     } else {
//       return "username";
//     }
//   };
//   const handleChange = (input) => (e) => {
//     const inputValue = e.target.value;

//     if (input === "login") {
//       const loginTypeCodeValue = identifyLoginType(inputValue);
//       Cookies.set("login", inputValue);
//       Cookies.set("loginTypeCode", loginTypeCodeValue);

//       setFormData({
//         ...formData,
//         login: inputValue,
//         loginTypeCode: identifyLoginType(inputValue),
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [input]: inputValue,
//       });
//     }
//   };

//   const checkProfile = Cookies.get("checkProfile");
//   // useEffect(() => {
//   //   const authToken = Cookies.get("authToken");

//   //   if (authToken && checkProfile) {
//   //     navigate(`/${checkProfile}`);
//   //   } else {
//   //     navigate("/community");
//   //   }
//   // }, [navigate, checkProfile]);

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     props.loginUser(formData, navigate);
//   };

//   return (
//     <div className="wrapper">
//       <Header />
//       <div className="container" style={{ backgroundColor: "aliceblue" }}>
//         <div className="row justify-content-center">
//           <div className="col-md-5 card_form">
//             <form className="row g-4" onSubmit={handleSubmit}>
//               <div className="col-md-12 text-center mt-4 mt-lg-2">
//                 <div className="top-logo d-flex align-items-center justify-content-center m-auto">
//                   <div className="header_logo_left me-2 ms-lg-4">
//                     <Link className="d-block" to="/">
//                       <img
//                         className="lazy"
//                         src="assets/amulab2/images/amulab-logo-svg.svg"
//                         alt="amulab-logo"
//                         title="Amulab-Logo"
//                       />
//                     </Link>
//                   </div>
//                   <div className="header-drop ms-2 ms-lg-4 text-start">
//                     <div className="dropdown">
//                       <button
//                         className="btn dropdown-toggle text-start p-0 logo-select"
//                         type="button"
//                         id="dropdownMenuButton1"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                       >
//                         Africa Must Unite
//                         <span className="d-block fw-bold">Laboratory</span>
//                       </button>
//                       <ul
//                         className="dropdown-menu"
//                         aria-labelledby="dropdownMenuButton1"
//                       >
//                         <li>
//                           <Link className="dropdown-item" to="/">
//                             Laboratoire
//                             <span className="d-block fw-bold">Login</span>
//                           </Link>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {props.error && typeof props.error === "string" ? (
//                 <Alert color="danger">{props.error}</Alert>
//               ) : null}
//               <div className="col-md-12">
//                 <label htmlFor="Inputaddress" className="form-label">
//                   {data_page_components[48]?.value}
//                 </label>
//                 <input
//                   type={formData.loginTypeCode}
//                   className="form-control h-60 h-55"
//                   id="Inputemail"
//                   aria-describedby="emailHelp"
//                   onChange={handleChange("login")}
//                 />
//               </div>
//               <div className="col-md-12 ps-icon">
//                 <label htmlFor="Inputpassword" className="form-label">
//                   {data_page_components[49]?.value}
//                 </label>
//                 <input
//                   type="password"
//                   className="form-control h-60 h-55"
//                   onChange={handleChange("password")}
//                   data-i18n-placeholder="placeholder_enter_password"
//                   id="Inputpassword"
//                 />
//                 <span className="win-eye openEye">
//                   <i className="ac-icon" data-icon="win-eye"></i>
//                 </span>
//                 <span className="win-eye closeEye hide">
//                   <i className="ac-icon" data-icon="win-eye-hidden"></i>
//                 </span>
//               </div>

//               <div className="text-center">
//                 <button
//                   type="submit"
//                   className="btn btn-primary btn-block"
//                   onSubmit={handleSubmit}
//                 >
//                   {data_page_components[42]?.value}{" "}
//                   {loading ? <Spinner size="sm"></Spinner> : ""}
//                 </button>
//               </div>

//               <div className="links-two w-100 m-auto">
//                 <ul className="d-flex justify-content-evenly align-items-center mt-4">
//                   <li>
//                     <Link
//                       to="#"
//                       className="fv"
//                       title="Forgot Password"
//                       data-i18n="forgot_pass"
//                     >
//                       {data_page_components[51]?.value}{" "}
//                     </Link>
//                   </li>
//                   <li>
//                     <Link
//                       to="#"
//                       className="fv"
//                       title="Verify account"
//                       data-i18n="verify_account"
//                     >
//                       {data_page_components[107]?.value}{" "}
//                     </Link>
//                   </li>
//                 </ul>
//               </div>
//             </form>
//             <div className="links-two w-100 m-auto">
//               <ul className="d-flex justify-content-evenly align-items-center mt-4"></ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// const mapStateToProps = (state) => ({
//   data_components: state.ComponentsDataReducer.data_components,
//   loading: state.LoginReducer.loading,
//   error: state.LoginReducer.error,
//   data_page_components: state.DataPageReducer.data_page_components,
// });

// export default connect(mapStateToProps, { loginUser, apiError })(Login);

// Login.propTypes = {
//   error: PropTypes.any,
//   loginUser: PropTypes.func,
// };
