// Logout.js
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser, logoutUserSuccess } from "../../store/actions";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        dispatch(logoutUser());
        await dispatch(logoutUserSuccess());
        navigate("/login");
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };

    handleLogout();
  }, [dispatch, navigate]);

  return null;
};

export default Logout;
