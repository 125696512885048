import {
  GET_ALL_CONTENT_STATIC_PAGE,
  GET_ALL_CONTENT_STATIC_PAGE_FAIL,
  GET_ALL_CONTENT_STATIC_PAGE_SUCCESS,
} from "./actionTypes";

const initialState = {
  data_page_components: [],
  data_step_page: [],
  loading: false,
  error: null,
};

const dataPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONTENT_STATIC_PAGE:
      return { ...state, loading: true };
    case GET_ALL_CONTENT_STATIC_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data_page_components: action.payload.data,
      };
    case GET_ALL_CONTENT_STATIC_PAGE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default dataPageReducer;
