import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllSubscribersSuccess,
  getAllSubscribersFail,
  getAllCommunityYesSuccess,
  getAllCommunityYesFail,
  getAllCommunityYesTeamsSuccess,
  getAllCommunityYesTeamsFail,
  getAllCommunityYesAmbassadorSuccess,
  getAllCommunityYesAmbassadorFail,
  getAllCommunityYesLeadersSuccess,
  getAllCommunityYesLeadersFail,
  getAllCommunityYesMembersSuccess,
  getAllCommunityYesMembersFail,
  getAllCommunityNoSuccess,
  getAllCommunityNoFail,
  getAllCommunityNoTeamsSuccess,
  getAllCommunityNoTeamsFail,
  getAllCommunityNoAmbassadorSuccess,
  getAllCommunityNoAmbassadorFail,
  getAllCommunityNoLeadersSuccess,
  getAllCommunityNoLeadersFail,
  getAllCommunityNoMembersSuccess,
  getAllCommunityNoMembersFail,
  getAllCommunityYesDelegateSuccess,
  getAllCommunityYesDelegateFail,
  getAllCommunityNoDelegateSuccess,
  getAllCommunityNoDelegateFail,
  getAllSubscribersProfileCommunitySuccess,
  getAllSubscribersProfileCommunityFail,
} from "./actions";
import {
  GET_ALL_COMMUNITY_NO,
  GET_ALL_COMMUNITY_NO_AMBASSADOR,
  GET_ALL_COMMUNITY_NO_DELEGATE,
  GET_ALL_COMMUNITY_NO_LEADERS,
  GET_ALL_COMMUNITY_NO_MEMBERS,
  GET_ALL_COMMUNITY_NO_TEAM,
  GET_ALL_COMMUNITY_YES,
  GET_ALL_COMMUNITY_YES_AMBASSADOR,
  GET_ALL_COMMUNITY_YES_DELEGATE,
  GET_ALL_COMMUNITY_YES_LEADERS,
  GET_ALL_COMMUNITY_YES_MEMBERS,
  GET_ALL_COMMUNITY_YES_TEAM,
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE,
} from "./actionTypes";
import {
  getAllSubscribersData,
  getAllSubscribersProfileData,
} from "../../helpers/api_helper";

// Worker Saga
function* fetchAllSubscribersSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllSubscribersSuccess(response));
  } catch (error) {
    yield put(getAllSubscribersFail(error.toString()));
  }
}

function* fetchAllSubscribersProfileCommunitySaga(action) {
  try {
    const response = yield call(getAllSubscribersProfileData, action.payload);
    yield put(getAllSubscribersProfileCommunitySuccess(response));
  } catch (error) {
    yield put(getAllSubscribersProfileCommunityFail(error.toString()));
  }
}

function* fetchYesCommunitySaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesFail(error.toString()));
  }
}

function* fetchYesCommunityTeamSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesTeamsSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesTeamsFail(error.toString()));
  }
}

function* fetchYesCommunityAmbassadorSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesAmbassadorSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesAmbassadorFail(error.toString()));
  }
}

function* fetchYesCommunityDelegateSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesDelegateSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesDelegateFail(error.toString()));
  }
}

function* fetchYesCommunityLeadersSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesLeadersSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesLeadersFail(error.toString()));
  }
}

function* fetchYesCommunityMembersSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityYesMembersSuccess(response));
  } catch (error) {
    yield put(getAllCommunityYesMembersFail(error.toString()));
  }
}

function* fetchNoCommunitySaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoFail(error.toString()));
  }
}

function* fetchNoCommunityTeamSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoTeamsSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoTeamsFail(error.toString()));
  }
}

function* fetchNoCommunityAmbassadorSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoAmbassadorSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoAmbassadorFail(error.toString()));
  }
}

function* fetchNoCommunityDelegateSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoDelegateSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoDelegateFail(error.toString()));
  }
}

function* fetchNoCommunityLeadersSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoLeadersSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoLeadersFail(error.toString()));
  }
}

function* fetchNoCommunityMembersSaga(action) {
  try {
    const response = yield call(getAllSubscribersData, action.payload);
    yield put(getAllCommunityNoMembersSuccess(response));
  } catch (error) {
    yield put(getAllCommunityNoMembersFail(error.toString()));
  }
}
// Watcher Saga
export default function* SubscribersPagesSaga() {
  yield takeEvery(GET_ALL_SUBSCRIBERS, fetchAllSubscribersSaga);
  yield takeEvery(GET_ALL_COMMUNITY_YES, fetchYesCommunitySaga);
  yield takeEvery(GET_ALL_COMMUNITY_YES_TEAM, fetchYesCommunityTeamSaga);
  yield takeEvery(
    GET_ALL_COMMUNITY_YES_AMBASSADOR,
    fetchYesCommunityAmbassadorSaga
  );
  yield takeEvery(
    GET_ALL_COMMUNITY_YES_DELEGATE,
    fetchYesCommunityDelegateSaga
  );
  yield takeEvery(GET_ALL_COMMUNITY_YES_LEADERS, fetchYesCommunityLeadersSaga);
  yield takeEvery(GET_ALL_COMMUNITY_YES_MEMBERS, fetchYesCommunityMembersSaga);
  yield takeEvery(GET_ALL_COMMUNITY_NO, fetchNoCommunitySaga);
  yield takeEvery(GET_ALL_COMMUNITY_NO_TEAM, fetchNoCommunityTeamSaga);
  yield takeEvery(
    GET_ALL_COMMUNITY_NO_AMBASSADOR,
    fetchNoCommunityAmbassadorSaga
  );
  yield takeEvery(GET_ALL_COMMUNITY_NO_DELEGATE, fetchNoCommunityDelegateSaga);
  yield takeEvery(GET_ALL_COMMUNITY_NO_LEADERS, fetchNoCommunityLeadersSaga);
  yield takeEvery(GET_ALL_COMMUNITY_NO_MEMBERS, fetchNoCommunityMembersSaga);
  yield takeEvery(
    GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE,
    fetchAllSubscribersProfileCommunitySaga
  );
}
// export default function* SubscribersPagesSaga() {
//   yield takeEvery(
//     [GET_ALL_SUBSCRIBERS, CHANGE_LANGUAGE],
//     fetchAllSubscribersSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES, CHANGE_LANGUAGE],
//     fetchYesCommunitySaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES_TEAM, CHANGE_LANGUAGE],
//     fetchYesCommunityTeamSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES_AMBASSADOR, CHANGE_LANGUAGE],
//     fetchYesCommunityAmbassadorSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES_DELEGATE, CHANGE_LANGUAGE],
//     fetchYesCommunityDelegateSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES_LEADERS, CHANGE_LANGUAGE],
//     fetchYesCommunityLeadersSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_YES_MEMBERS, CHANGE_LANGUAGE],
//     fetchYesCommunityMembersSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO, CHANGE_LANGUAGE],
//     fetchNoCommunitySaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO_TEAM, CHANGE_LANGUAGE],
//     fetchNoCommunityTeamSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO_AMBASSADOR, CHANGE_LANGUAGE],
//     fetchNoCommunityAmbassadorSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO_DELEGATE, CHANGE_LANGUAGE],
//     fetchNoCommunityDelegateSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO_LEADERS, CHANGE_LANGUAGE],
//     fetchNoCommunityLeadersSaga
//   );
//   yield takeEvery(
//     [GET_ALL_COMMUNITY_NO_MEMBERS, CHANGE_LANGUAGE],
//     fetchNoCommunityMembersSaga
//   );
//   yield takeEvery(
//     [GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE, CHANGE_LANGUAGE],
//     fetchAllSubscribersProfileCommunitySaga
//   );
// }
