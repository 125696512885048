import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getAllComponentsDataSuccess,
  getAllComponentsDataFail,
  getAllDataStepPageSuccess,
  getAllDataStepPageFail,
  getAllDataAgreementPageFail,
  getAllDataAgreementPageSuccess,
  getAllDataMottoPageSuccess,
  getAllDataMottoPageFail,
} from "./actions";
import {
  GET_ALL_META_HEADER_FOOTER,
  CHANGE_LANGUAGE,
  GET_ALL_DATA_STEP_PAGE,
  GET_ALL_DATA_AGREEMENT_PAGE,
  GET_ALL_DATA_MOTTO_PAGE,
} from "./actionTypes";
import {
  getAllContentsStepPage,
  getAllMetaHeaderAndFooter,
} from "../../helpers/content_helper/api_content_helper";
import { getBrowserLanguage } from "../../helpers/api_helper";

// Worker Saga
function* fetchAllDataComponentsSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
      value: "",
      appName: "amulab",
      type: "layoutcontents",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    };

    const response = yield call(getAllMetaHeaderAndFooter, data);
    // localStorage.setItem("data_meta", JSON.stringify(response));
    yield put(getAllComponentsDataSuccess(response));
  } catch (error) {
    yield put(getAllComponentsDataFail(error.toString()));
  }
}

function* fetchAllDataStepSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
      value: "labsteps",
      appName: "amulab",
      type: "pagecode",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    };

    const response = yield call(getAllContentsStepPage, data);
    yield put(getAllDataStepPageSuccess(response));
  } catch (error) {
    yield put(getAllDataStepPageFail(error.toString()));
  }
}

function* fetchAllDataAgreementSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
      value: "agreement",
      appName: "amulab",
      type: "pagecode",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    };

    const response = yield call(getAllContentsStepPage, data);
    yield put(getAllDataAgreementPageSuccess(response));
  } catch (error) {
    yield put(getAllDataAgreementPageFail(error.toString()));
  }
}

function* fetchAllDataMottoSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
      value: "labmotto",
      appName: "amulab",
      type: "pagecode",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    };

    const response = yield call(getAllContentsStepPage, data);
    yield put(getAllDataMottoPageSuccess(response));
  } catch (error) {
    yield put(getAllDataMottoPageFail(error.toString()));
  }
}

// Watcher Saga
export default function* DataComponentsSaga() {
  yield takeEvery(
    [GET_ALL_META_HEADER_FOOTER, CHANGE_LANGUAGE],
    fetchAllDataComponentsSaga
  );
  yield takeEvery(
    [GET_ALL_DATA_STEP_PAGE, CHANGE_LANGUAGE],
    fetchAllDataStepSaga
  );
  yield takeEvery(
    [GET_ALL_DATA_AGREEMENT_PAGE, CHANGE_LANGUAGE],
    fetchAllDataAgreementSaga
  );
  yield takeEvery(
    [GET_ALL_DATA_MOTTO_PAGE, CHANGE_LANGUAGE],
    fetchAllDataMottoSaga
  );
}
