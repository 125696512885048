import React from "react";
import { connect } from "react-redux";

const Footer = ({ data_components, data_page_components }) => {
  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
  return (
    <div>
      <footer className="py-3 px-5 text-center text-md-start">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9 col-lg-10 mt-3 mt-xl-0">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 mt-4 mt-lg-0">
                  <h6 className="footer-heading text-uppercase text-white">
                    {data_page_components_obj.aboutUs}
                  </h6>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_components[0]?.contents[0],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

// export default Footer;
const mapStateToProps = (state) => ({
  data_components: state.ComponentsDataReducer.data_components,
  language: state.ComponentsDataReducer.language,
  data_page_components: state.DataPageReducer.data_page_components,
});

export default connect(mapStateToProps)(Footer);
