import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { userRoutes, authRoutes } from "./routes/allRoutes";
import { useDispatch } from "react-redux";
import {
  // changeLanguage,
  getAllComponentsData,
  getAllDataAgreementPage,
  getAllDataMottoPage,
  getAllDataPages,
  getAllDataStepPage,
  getAllNationsList,
} from "./store/actions";
import NotFound from "./pages/OtherPages/NotFound";
import Head from "./components/Head";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllComponentsData());
    dispatch(getAllDataPages());
    dispatch(getAllDataStepPage());
    dispatch(getAllNationsList());
    dispatch(getAllDataAgreementPage());
    dispatch(getAllDataMottoPage());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Head />
      <Routes>
        {/* Routes protégées nécessitant une authentification */}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<route.component />}
            key={idx}
            exact={route.exact}
          />
        ))}

        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<route.component />}
            key={idx}
            exact={route.exact}
          />
        ))}
        <Route path="/notfound" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
