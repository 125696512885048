import {
  GET_ALL_SUBSCRIBERS_PROFILE,
  GET_ALL_SUBSCRIBERS_PROFILE_FAIL,
  GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_TYPE,
  GET_USER_PROFILE_TYPE_FAIL,
  GET_USER_PROFILE_TYPE_SUCCESS,
  RESET_ERROR,
  RESET_POST_SUCCESS,
  RESET_UPDATE_SUCCESS,
  RESET_UPDATE_SUCCESS_NAME,
  RESET_UPDATE_SUCCESS_TYPE,
  RESET_UPDATE_SUCCESS_USERNAME,
  RESET_UPDATE_SUCCESS_YOUTUBE,
  SET_CURRENT_PAGE,
  UPDATE_USER_PROFILE_DESCRIPTION,
  UPDATE_USER_PROFILE_DESCRIPTION_FAIL,
  UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS,
  RESET_UPDATE_SUCCESS_DESCRIPTION,
  UPDATE_USER_PROFILE_NAME,
  UPDATE_USER_PROFILE_NAME_FAIL,
  UPDATE_USER_PROFILE_NAME_SUCCESS,
  UPDATE_USER_PROFILE_PP,
  UPDATE_USER_PROFILE_PP_FAIL,
  UPDATE_USER_PROFILE_PP_SUCCESS,
  UPDATE_USER_PROFILE_TYPE,
  UPDATE_USER_PROFILE_TYPE_FAIL,
  UPDATE_USER_PROFILE_TYPE_SUCCESS,
  UPDATE_USER_PROFILE_USERNAME,
  UPDATE_USER_PROFILE_USERNAME_FAIL,
  UPDATE_USER_PROFILE_USERNAME_SUCCESS,
  UPDATE_USER_PROFILE_YOUTUBE_LINK,
  UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL,
  UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS,
  SUBSCRIBE_BUTTON,
  SUBSCRIBE_BUTTON_SUCCESS,
  SUBSCRIBE_BUTTON_FAILS,
  RESET_SUBSCRIBE_BUTTON,
  NOT_FOUND_DATA,
  RESET_NOT_FOUND_DATA,
  CLAIM_PROFILE_PAGE,
  CLAIM_PROFILE_PAGE_SUCCESS,
  CLAIM_PROFILE_PAGE_FAIL,
  RESET_CLAIM_PROFILE_PAGE_SUCCESS,
  USERNAME_ALREADY_EXISTS,
  RESET_YOUTUBE_LINK_ALREADY_EXISTS,
} from "./actionTypes";

export const getUserProfile_a = (data) => ({
  type: GET_USER_PROFILE,
  payload: data,
});
export const getUserProfile_a_Success = (response) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: response,
});

export const getUserProfile_a_Fail = (error) => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
});

export const getUserProfileType = (data_type) => ({
  type: GET_USER_PROFILE_TYPE,
  payload: data_type,
});

export const getUserProfileTypeSuccess = (response) => ({
  type: GET_USER_PROFILE_TYPE_SUCCESS,
  payload: response,
});

export const getUserProfileTypeFail = (error) => ({
  type: GET_USER_PROFILE_TYPE_FAIL,
  payload: error,
});

export const getAllSubscribersProfile = (data_meta) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE,
  payload: data_meta,
});

export const getAllSubscribersProfileSuccess = (all_meta_component) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS,
  payload: all_meta_component,
});

export const getAllSubscribersProfileFail = (error) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE_FAIL,
  payload: error,
});

export const setCurrentPage2 = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});

export const updateUserProfile_pp = (formData) => ({
  type: UPDATE_USER_PROFILE_PP,
  payload: formData,
});

export const updateUserProfile_ppSuccess = (pp) => ({
  type: UPDATE_USER_PROFILE_PP_SUCCESS,
  payload: pp,
});

export const updateUserProfile_ppFail = (error) => ({
  type: UPDATE_USER_PROFILE_PP_FAIL,
  payload: error,
});
export const resetPostSuccess = () => ({
  type: RESET_POST_SUCCESS,
});

export const resetUpdateSuccess = () => ({
  type: RESET_UPDATE_SUCCESS,
});

export const notfoundData = () => ({
  type: NOT_FOUND_DATA,
});
export const resetNotfoundData = () => ({
  type: RESET_NOT_FOUND_DATA,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

export const updateUserProfile_username = (username) => ({
  type: UPDATE_USER_PROFILE_USERNAME,
  payload: username,
});

export const updateUserProfile_usernameSuccess = (upd_type) => ({
  type: UPDATE_USER_PROFILE_USERNAME_SUCCESS,
  payload: upd_type,
});

export const updateUserProfile_usernameFail = (error) => ({
  type: UPDATE_USER_PROFILE_USERNAME_FAIL,
  payload: error,
});

export const resetUsernameExist = () => ({
  type: USERNAME_ALREADY_EXISTS,
});

export const resetUpdateSuccessUsername = () => ({
  type: RESET_UPDATE_SUCCESS_USERNAME,
});

export const updateUserProfile_name = (username) => ({
  type: UPDATE_USER_PROFILE_NAME,
  payload: username,
});

export const updateUserProfile_nameSuccess = (upd_type) => ({
  type: UPDATE_USER_PROFILE_NAME_SUCCESS,
  payload: upd_type,
});

export const updateUserProfile_nameFail = (error) => ({
  type: UPDATE_USER_PROFILE_NAME_FAIL,
  payload: error,
});

export const resetUpdateSuccessName = () => ({
  type: RESET_UPDATE_SUCCESS_NAME,
});

export const updateUserProfile_youtube = (formData) => ({
  type: UPDATE_USER_PROFILE_YOUTUBE_LINK,
  payload: formData,
});

export const updateUserProfile_youtubeSuccess = (upd_type) => ({
  type: UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS,
  payload: upd_type,
});

export const updateUserProfile_youtubeFail = (error) => ({
  type: UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL,
  payload: error,
});

export const resetUpdateSuccessYoutubeLink = () => ({
  type: RESET_UPDATE_SUCCESS_YOUTUBE,
});

export const resetUserProfile_youtubeFail = () => ({
  type: RESET_YOUTUBE_LINK_ALREADY_EXISTS,
});

export const updateUserProfile_type = (formData) => ({
  type: UPDATE_USER_PROFILE_TYPE,
  payload: formData,
});

export const updateUserProfile_typeSuccess = (upd_type) => ({
  type: UPDATE_USER_PROFILE_TYPE_SUCCESS,
  payload: upd_type,
});

export const updateUserProfile_typeFail = (error) => ({
  type: UPDATE_USER_PROFILE_TYPE_FAIL,
  payload: error,
});

export const resetUpdateSuccessType = () => ({
  type: RESET_UPDATE_SUCCESS_TYPE,
});

export const updateUserProfile_description = (desc) => ({
  type: UPDATE_USER_PROFILE_DESCRIPTION,
  payload: desc,
});

export const updateUserProfile_descriptionSuccess = (upd_desc) => ({
  type: UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS,
  payload: upd_desc,
});

export const updateUserProfile_descriptionFail = (error) => ({
  type: UPDATE_USER_PROFILE_DESCRIPTION_FAIL,
  payload: error,
});
export const resetUpdateSuccessDescription = () => ({
  type: RESET_UPDATE_SUCCESS_DESCRIPTION,
});

export const buttonUserSubscribeProfile = (data) => ({
  type: SUBSCRIBE_BUTTON,
  payload: data,
});

export const buttonUserSubscribeProfileSuccess = (subscribe) => ({
  type: SUBSCRIBE_BUTTON_SUCCESS,
  payload: subscribe,
});

export const buttonUserSubscribeProfileFail = (error) => ({
  type: SUBSCRIBE_BUTTON_FAILS,
  payload: error,
});

export const resetButtonUserSubscribeProfile = () => ({
  type: RESET_SUBSCRIBE_BUTTON,
});

export const claimUserProfilePage = (data) => ({
  type: CLAIM_PROFILE_PAGE,
  payload: data,
});

export const claimUserProfilePageSuccess = (claim) => ({
  type: CLAIM_PROFILE_PAGE_SUCCESS,
  payload: claim,
});

export const claimUserProfilePageFail = (error) => ({
  type: CLAIM_PROFILE_PAGE_FAIL,
  payload: error,
});

export const resetClaimSuccessPost = () => ({
  type: RESET_CLAIM_PROFILE_PAGE_SUCCESS,
});
