import {
  GET_ALL_SUBSCRIBERS,
  GET_ALL_SUBSCRIBERS_FAIL,
  GET_ALL_SUBSCRIBERS_SUCCESS,
  SET_CURRENT_PAGE,
  GET_ALL_COMMUNITY_YES,
  GET_ALL_COMMUNITY_YES_SUCCESS,
  GET_ALL_COMMUNITY_YES_FAIL,
  GET_ALL_COMMUNITY_YES_TEAM,
  GET_ALL_COMMUNITY_YES_TEAM_SUCCESS,
  GET_ALL_COMMUNITY_YES_TEAM_FAIL,
  GET_ALL_COMMUNITY_YES_AMBASSADOR,
  GET_ALL_COMMUNITY_YES_AMBASSADOR_SUCCESS,
  GET_ALL_COMMUNITY_YES_AMBASSADOR_FAIL,
  GET_ALL_COMMUNITY_YES_LEADERS,
  GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS,
  GET_ALL_COMMUNITY_YES_LEADERS_FAIL,
  GET_ALL_COMMUNITY_YES_MEMBERS,
  GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS,
  GET_ALL_COMMUNITY_YES_MEMBERS_FAIL,
  GET_ALL_COMMUNITY_NO,
  GET_ALL_COMMUNITY_NO_SUCCESS,
  GET_ALL_COMMUNITY_NO_FAIL,
  GET_ALL_COMMUNITY_NO_AMBASSADOR,
  GET_ALL_COMMUNITY_NO_AMBASSADOR_SUCCESS,
  GET_ALL_COMMUNITY_NO_AMBASSADOR_FAIL,
  GET_ALL_COMMUNITY_NO_TEAM,
  GET_ALL_COMMUNITY_NO_TEAM_SUCCESS,
  GET_ALL_COMMUNITY_NO_TEAM_FAIL,
  GET_ALL_COMMUNITY_NO_LEADERS,
  GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS,
  GET_ALL_COMMUNITY_NO_LEADERS_FAIL,
  GET_ALL_COMMUNITY_NO_MEMBERS,
  GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS,
  GET_ALL_COMMUNITY_NO_MEMBERS_FAIL,
  GET_ALL_COMMUNITY_YES_DELEGATE,
  GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS,
  GET_ALL_COMMUNITY_YES_DELEGATE_FAIL,
  GET_ALL_COMMUNITY_NO_DELEGATE,
  GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS,
  GET_ALL_COMMUNITY_NO_DELEGATE_FAIL,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS,
  GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL,
  SET_CURRENT_PAGE_SUBSCRIBERS,
} from "./actionTypes";

export const getAllSubscribers = (data_meta) => ({
  type: GET_ALL_SUBSCRIBERS,
  payload: data_meta,
});

export const getAllSubscribersSuccess = (all_meta_component) => ({
  type: GET_ALL_SUBSCRIBERS_SUCCESS,
  payload: all_meta_component,
});

export const getAllSubscribersFail = (error) => ({
  type: GET_ALL_SUBSCRIBERS_FAIL,
  payload: error,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
export const setCurrentPageSubscribers = (page) => ({
  type: SET_CURRENT_PAGE_SUBSCRIBERS,
  payload: page,
});

export const getAllSubscribersProfileCommunity = (data_meta) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE,
  payload: data_meta,
});

export const getAllSubscribersProfileCommunitySuccess = (
  all_meta_component
) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_SUCCESS,
  payload: all_meta_component,
});

export const getAllSubscribersProfileCommunityFail = (error) => ({
  type: GET_ALL_SUBSCRIBERS_PROFILE_COMMUNITY_PAGE_FAIL,
  payload: error,
});

export const getAllCommunityYes = (data_com_yes) => ({
  type: GET_ALL_COMMUNITY_YES,
  payload: data_com_yes,
});

export const getAllCommunityYesSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_FAIL,
  payload: error,
});

export const getAllCommunityYesTeams = (data_com_yes_t) => ({
  type: GET_ALL_COMMUNITY_YES_TEAM,
  payload: data_com_yes_t,
});

export const getAllCommunityYesTeamsSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_TEAM_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesTeamsFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_TEAM_FAIL,
  payload: error,
});

export const getAllCommunityYesAmbassador = (data_com_yes_a) => ({
  type: GET_ALL_COMMUNITY_YES_AMBASSADOR,
  payload: data_com_yes_a,
});

export const getAllCommunityYesAmbassadorSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_AMBASSADOR_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesAmbassadorFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_AMBASSADOR_FAIL,
  payload: error,
});

export const getAllCommunityYesDelegate = (data_com_yes_d) => ({
  type: GET_ALL_COMMUNITY_YES_DELEGATE,
  payload: data_com_yes_d,
});

export const getAllCommunityYesDelegateSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_DELEGATE_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesDelegateFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_DELEGATE_FAIL,
  payload: error,
});

export const getAllCommunityYesLeaders = (data_com_yes_l) => ({
  type: GET_ALL_COMMUNITY_YES_LEADERS,
  payload: data_com_yes_l,
});

export const getAllCommunityYesLeadersSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_LEADERS_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesLeadersFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_LEADERS_FAIL,
  payload: error,
});

export const getAllCommunityYesMembers = (data_com_yes_m) => ({
  type: GET_ALL_COMMUNITY_YES_MEMBERS,
  payload: data_com_yes_m,
});

export const getAllCommunityYesMembersSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_YES_MEMBERS_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityYesMembersFail = (error) => ({
  type: GET_ALL_COMMUNITY_YES_MEMBERS_FAIL,
  payload: error,
});

// SDDDDDDDDD
export const getAllCommunityNo = (data_com_no) => ({
  type: GET_ALL_COMMUNITY_NO,
  payload: data_com_no,
});

export const getAllCommunityNoSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_FAIL,
  payload: error,
});

export const getAllCommunityNoTeams = (data_com_No_t) => ({
  type: GET_ALL_COMMUNITY_NO_TEAM,
  payload: data_com_No_t,
});

export const getAllCommunityNoTeamsSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_TEAM_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoTeamsFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_TEAM_FAIL,
  payload: error,
});

export const getAllCommunityNoAmbassador = (data_com_No_a) => ({
  type: GET_ALL_COMMUNITY_NO_AMBASSADOR,
  payload: data_com_No_a,
});

export const getAllCommunityNoAmbassadorSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_AMBASSADOR_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoAmbassadorFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_AMBASSADOR_FAIL,
  payload: error,
});

export const getAllCommunityNoDelegate = (data_com_No_d) => ({
  type: GET_ALL_COMMUNITY_NO_DELEGATE,
  payload: data_com_No_d,
});

export const getAllCommunityNoDelegateSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_DELEGATE_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoDelegateFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_DELEGATE_FAIL,
  payload: error,
});

export const getAllCommunityNoLeaders = (data_com_No_l) => ({
  type: GET_ALL_COMMUNITY_NO_LEADERS,
  payload: data_com_No_l,
});

export const getAllCommunityNoLeadersSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_LEADERS_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoLeadersFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_LEADERS_FAIL,
  payload: error,
});

export const getAllCommunityNoMembers = (data_com_No_m) => ({
  type: GET_ALL_COMMUNITY_NO_MEMBERS,
  payload: data_com_No_m,
});

export const getAllCommunityNoMembersSuccess = (all_meta_component) => ({
  type: GET_ALL_COMMUNITY_NO_MEMBERS_SUCCESS,
  payload: all_meta_component,
});

export const getAllCommunityNoMembersFail = (error) => ({
  type: GET_ALL_COMMUNITY_NO_MEMBERS_FAIL,
  payload: error,
});
