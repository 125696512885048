import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import {
  resetUpdateSuccessType,
  updateUserProfile_type,
} from "../../../store/actions";
import Swal from "sweetalert2";

const TypesModal = (props) => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedTypeName, setSelectedTypeName] = useState("");
  const [errors, setErrors] = useState({});
  const { data_page_components, loadingModalType, updateSuccessType } = props;

  const data = {
    apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
    type: selectedType,
  };

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSelectedType(event.target.value);
    const selectedOption = props.user_type.find(
      (item) => item.type === event.target.value
    );
    if (selectedOption) {
      setSelectedTypeName(selectedOption.name);
    }
  };

  const handleUpdate = () => {
    let errors = {};
    if (!selectedType) {
      errors["type"] = "Type is required.";
    }
    if (selectedType) {
      dispatch(updateUserProfile_type(data));
    }
    setErrors(errors);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    if (updateSuccessType) {
      props.onUpdateType(selectedTypeName);
      props.toggleType();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSuccessSaved}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateSuccessType());
    }
  }, [
    dispatch,
    updateSuccessType,
    data_page_components_obj.profileSuccessSaved,
    selectedTypeName,
    props,
  ]);

  return (
    <div>
      <Modal isOpen={props.modalType} toggle={props.toggleType}>
        <ModalHeader toggle={props.toggleType}>
          {" "}
          {data_page_components_obj.profileYoutubeUrlUpdateType}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={handleChange}
            >
              <option>{props.type ? props.type : "-- Sélectionner --"}</option>
              {props.user_type?.map((item, index) => (
                <option value={item.type} key={index}>
                  {item.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <span style={{ color: "red" }}>{errors["type"]}</span>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleType}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleUpdate}>
            {loadingModalType ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingModalType: state.UserProfilePageReducer.loadingModalType,
  error: state.UserProfilePageReducer.error,
  data_page_components: state.DataPageReducer.data_page_components,
  updateSuccessType: state.UserProfilePageReducer.updateSuccessType,
});

export default connect(mapStateToProps)(TypesModal);
