import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import "./style.css";
import Head from "../components/Head";

const StepLabo = ({ data_step_page }) => {
  return (
    <div id="wrapper">
      <Header />
      <Head pageTitle={data_step_page[0]?.title} />

      <div className="main-wrapper">
        <section className="text-data">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="fw-bold pt-3 report-title ff2">
                  {data_step_page[0]?.title}
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="modboxwrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="modbox">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_step_page[1]?.contents[0],
                    }}
                  />
                  <h4 className="fw-bold ">{data_step_page[2]?.title} </h4>
                  <p>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data_step_page[3]?.contents[0],
                      }}
                    />
                  </p>
                  {/* <h4 className="fw-bold pt-4 pb-2 report-inner-title ff2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data_step_page[9]?.contents[0],
                      }}
                    />
                  </h4> */}
                  <div className="accordion" id="accordion-provider">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollaps"
                          aria-expanded="false"
                          aria-controls="providercollaps"
                        >
                          {data_step_page[4]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollaps"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingOne"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[4]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                          {/* <!--/.custom-ol-listin--> */}
                        </div>
                        {/* <!--/.accordion-body--> */}
                      </div>
                      {/* <!--/.accordion-collapse--> */}
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsTwo"
                          aria-expanded="false"
                          aria-controls="providercollapsTwo"
                        >
                          {data_step_page[5]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollapsTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingTwo"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[5]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsThree"
                          aria-expanded="false"
                          aria-controls="providercollapsThree"
                        >
                          {data_step_page[6]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollapsThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingThree"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[6]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>

                    {/* <!--/.accordion-item--> */}
                  </div>
                  <h4 className="fw-bold pt-4 pb-2 report-inner-title ff2">
                    {data_step_page[7]?.title}{" "}
                  </h4>
                  <p>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data_step_page[8]?.contents[0],
                      }}
                    />
                  </p>
                  <div className="accordion" id="accordion-provider">
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFour"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFour"
                          aria-expanded="false"
                          aria-controls="providercollapsFour"
                        >
                          {data_step_page[9]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollapsFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFour"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[9]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFive"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFive"
                          aria-expanded="false"
                          aria-controls="providercollapsFive"
                        >
                          {data_step_page[10]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollapsFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFive"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[10]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsSix"
                          aria-expanded="false"
                          aria-controls="providercollapsSix"
                        >
                          {data_step_page[11]?.title}
                        </button>
                      </h2>
                      <div
                        id="providercollapsSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingSix"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_step_page[11]?.contents.map((item, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item,
                                  }}
                                />
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    </div>

                    {/* <!--/.accordion-item--> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /.modbox --> */}
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_step_page: state.ComponentsDataReducer.data_step_page,
  language: state.ComponentsDataReducer.language,
});

export default connect(mapStateToProps)(StepLabo);
