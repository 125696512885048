import {
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  API_ERROR,
  SET_LOADING,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAIL,
} from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  user: null,
  userDataForProfile: null,
  isAuthenticated: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return { ...state, loading: true, error: null };
    case AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case AUTHENTICATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
      };

    case LOGOUT_USER:
      return { ...state };
    case API_ERROR:
      return { ...state, error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default loginReducer;
