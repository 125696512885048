import React from "react";
import { connect } from "react-redux";

function Head({ data_components, pageTitle }) {
  const title = data_components[1]?.contents[0];
  const description = data_components[2]?.contents[0];

  React.useEffect(() => {
    document.title = `${pageTitle ? pageTitle : ""} ${title}`;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
  }, [title, description, pageTitle]);

  return null;
}
const mapStateToProps = (state) => ({
  data_components: state.ComponentsDataReducer.data_components,
});

export default connect(mapStateToProps)(Head);
