import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { connect, useDispatch } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import { Spinner } from "reactstrap";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Head from "../../components/Head";
import { useNavigate } from "react-router-dom";
import {
  getAllCommunityNo,
  getAllCommunityNoAmbassador,
  getAllCommunityNoDelegate,
  getAllCommunityNoLeaders,
  getAllCommunityNoMembers,
  getAllCommunityNoTeams,
  getAllCommunityYes,
  getAllCommunityYesAmbassador,
  getAllCommunityYesDelegate,
  getAllCommunityYesLeaders,
  getAllCommunityYesMembers,
  getAllCommunityYesTeams,
  getAllSubscribers,
} from "../../store/actions";
import "../style.css";
// import { Spinner, Nav, NavItem, NavLink } from "reactstrap";
// import { Spinner, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import ProgressBar from "../../components/ProgressBar";
import { getBrowserLanguage } from "../../helpers/api_helper";
// import { FaUsers } from "react-icons/fa";
// import { BiSolidUserCheck } from "react-icons/bi";
// import { BiSolidUserX } from "react-icons/bi";
// import { GoChevronDown, GoChevronUp } from "react-icons/go";
import ShowMoreLessParagraph from "../../components/ShowMoreLessText";
import ReviewProfileSubsYes from "../../components/reviews/reviewSbscriber/reviewProfileSubsYes";
import ReviewProfileSubsNo from "../../components/reviews/reviewSbscriber/reviewProfileSubsNo";
import { BASE_API_URL_AVATAR } from "../../helpers/url_helper";
import PaginationComponent from "../../components/PaginationComponent";
const Home = ({
  data_page_components,
  loading,
  subscribers,
  yesCommunity,
  yesCommunityTeam,
  yesCommunityAmbassador,
  yesCommunityDelegate,
  yesCommunityLeaders,
  yesCommunityMembers,
  noCommunity,
  noCommunityTeam,
  noCommunityAmbassador,
  noCommunityDelegate,
  noCommunityLeaders,
  noCommunityMembers,
  error,
}) => {
  const [progressStatus, setProgressStatus] = useState(0);
  const [currentData, setCurrentData] = useState(subscribers);
  const [showProgress, setShowProgress] = useState(false);
  const [activeCategory, setActiveCategory] = useState("allSubscribers");
  const [title, setTitle] = useState("");
  // const [isOpen2, setIsOpen2] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialTotalPages, setInitialTotalPages] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const secretKey = process.env.REACT_APP_API_KEY_DATA;

  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const newLanguage = selectedLanguage || getBrowserLanguage();
  const data = useMemo(
    () => ({
      apiKey: secretKey,
      type: "",
      communityTypeCode: "",
      languageCode: `${newLanguage.substring(0, 2) === "fr" ? "fra" : "eng"}`,
      q: "",
      rowsPerPage: 10,
      pageNumber: currentPage,
      sortBy: "",
      sort: "desc",
    }),
    [currentPage, newLanguage, secretKey]
  );

  // const toggle2 = () => {
  //   handleSelectCategory("noCommunity");
  //   setIsOpen2(!isOpen2);
  // };

  useEffect(() => {
    dispatch(
      getAllSubscribers({
        apiKey: secretKey,
        type: "",
        communityTypeCode: "0",
        languageCode: `${newLanguage.substring(0, 2) === "fr" ? "fra" : "eng"}`,
        q: "",
        rowsPerPage: 10,
        pageNumber: 1,
        sortBy: "",
        sort: "desc",
      })
    );
  }, [dispatch, newLanguage, secretKey]);

  const data_page_components_obj = data_page_components?.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    const fetchData = async () => {
      //se rassurer de conserver le total des pages initiales et items initiales pour chaque catégorie
      if (currentPage === 1) {
        setInitialTotalPages(totalPages);
        setProgressStatus(subscribers?.totalItems);
        switch (activeCategory) {
          case "yesCommunity":
            initialDisplayTotalPagesRef.current = yesCommunity?.totalItems;
            break;
          case "yesCommunityTeams":
            initialDisplayTotalPagesRef.current = yesCommunityTeam?.totalItems;
            break;
          case "yesCommunityAmbassadors":
            initialDisplayTotalPagesRef.current =
              yesCommunityAmbassador?.totalItems;
            break;
          case "yesCommunityDelegates":
            initialDisplayTotalPagesRef.current =
              yesCommunityDelegate?.totalItems;
            break;
          case "yesCommunityLeaders":
            initialDisplayTotalPagesRef.current =
              yesCommunityLeaders?.totalItems;
            break;
          case "yesCommunityMembers":
            initialDisplayTotalPagesRef.current =
              yesCommunityMembers?.totalItems;
            break;
          case "noCommunity":
            initialDisplayTotalPagesRef.current = noCommunity?.totalItems;
            break;
          case "noCommunityTeams":
            initialDisplayTotalPagesRef.current = noCommunityTeam?.totalItems;
            break;
          case "noCommunityAmbassadors":
            initialDisplayTotalPagesRef.current =
              noCommunityAmbassador?.totalItems;
            break;
          case "noCommunityDelegates":
            initialDisplayTotalPagesRef.current =
              noCommunityDelegate?.totalItems;
            break;
          case "noCommunityLeaders":
            initialDisplayTotalPagesRef.current =
              noCommunityLeaders?.totalItems;
            break;
          case "noCommunityMembers":
            initialDisplayTotalPagesRef.current =
              noCommunityMembers?.totalItems;
            break;
          default:
            initialDisplayTotalPagesRef.current = 0;
        }
      }
      switch (activeCategory) {
        case "allSubscribers":
          setCurrentData(subscribers);
          setShowProgress(true);
          setTitle("");
          setTotalPages(subscribers?.totalPages);
          break;
        case "yesCommunity":
          setCurrentData(yesCommunity);
          setShowProgress(false);
          setTotalPages(yesCommunity?.totalPages);
          break;
        case "yesCommunityTeams":
          setCurrentData(yesCommunityTeam);
          setShowProgress(false);
          setTotalPages(yesCommunityTeam?.totalPages);
          break;
        case "yesCommunityAmbassadors":
          setCurrentData(yesCommunityAmbassador);
          setShowProgress(false);
          setTotalPages(yesCommunityAmbassador?.totalPages);
          break;
        case "yesCommunityDelegates":
          setCurrentData(yesCommunityDelegate);
          setShowProgress(false);
          setTotalPages(yesCommunityDelegate?.totalPages);
          break;
        case "yesCommunityLeaders":
          setCurrentData(yesCommunityLeaders);
          setShowProgress(false);
          setTotalPages(yesCommunityLeaders?.totalPages);
          break;
        case "yesCommunityMembers":
          setCurrentData(yesCommunityMembers);
          setShowProgress(false);
          setTotalPages(yesCommunityMembers?.totalPages);
          break;

        case "noCommunity":
          setCurrentData(noCommunity);
          setShowProgress(false);
          setTotalPages(noCommunity?.totalPages);
          break;

        case "noCommunityTeams":
          setCurrentData(noCommunityTeam);
          setShowProgress(false);
          setTotalPages(noCommunityTeam?.totalPages);
          break;

        case "noCommunityAmbassadors":
          setCurrentData(noCommunityAmbassador);
          setShowProgress(false);
          setTotalPages(noCommunityAmbassador?.totalPages);
          break;

        case "noCommunityDelegates":
          setCurrentData(noCommunityDelegate);
          setShowProgress(false);
          setTotalPages(noCommunityDelegate?.totalPages);
          break;

        case "noCommunityLeaders":
          setCurrentData(noCommunityLeaders);
          setShowProgress(false);
          setTotalPages(noCommunityLeaders?.totalPages);
          break;
        case "noCommunityMembers":
          setCurrentData(noCommunityMembers);
          setShowProgress(false);
          setTotalPages(noCommunityMembers?.totalPages);
          break;
        default:
          setShowProgress(false);
          setTitle("");
      }
    };

    fetchData();
  }, [
    subscribers,
    yesCommunity,
    yesCommunityTeam,
    yesCommunityAmbassador,
    yesCommunityDelegate,
    yesCommunityLeaders,
    yesCommunityMembers,
    noCommunity,
    noCommunityTeam,
    noCommunityAmbassador,
    noCommunityDelegate,
    noCommunityLeaders,
    noCommunityMembers,
    activeCategory,
    currentPage,
    totalPages,
  ]);

  const initialDisplayTotalPagesRef = useRef();
  const handleSelectCategory = useCallback(
    (category) => {
      switch (category) {
        case "allSubscribers":
          dispatch(
            getAllSubscribers({
              ...data,
              type: "",
              communityTypeCode: "0",
            })
          );
          break;
        case "yesCommunity":
          setTitle(data_page_components_obj.communityMenuYes);
          dispatch(
            getAllCommunityYes({
              ...data,
              type: "",
              communityTypeCode: "1",
            })
          );

          break;
        case "yesCommunityTeams":
          setTitle(data_page_components_obj.profileTeam);
          dispatch(
            getAllCommunityYesTeams({
              ...data,
              type: "5",
              communityTypeCode: "1",
            })
          );

          break;
        case "yesCommunityAmbassadors":
          setTitle(data_page_components_obj.profileAmbassador);
          dispatch(
            getAllCommunityYesAmbassador({
              ...data,
              type: "4",
              communityTypeCode: "1",
            })
          );

          break;
        case "yesCommunityDelegates":
          setTitle(data_page_components_obj.profileDelegate);
          dispatch(
            getAllCommunityYesDelegate({
              ...data,
              type: "3",
              communityTypeCode: "1",
            })
          );

          break;
        case "yesCommunityLeaders":
          setTitle(data_page_components_obj.profileLeader);
          dispatch(
            getAllCommunityYesLeaders({
              ...data,
              type: "2",
              communityTypeCode: "1",
            })
          );

          break;
        case "yesCommunityMembers":
          setTitle(data_page_components_obj.profileMember);
          dispatch(
            getAllCommunityYesMembers({
              ...data,
              type: "1",
              communityTypeCode: "1",
            })
          );

          break;

        case "noCommunity":
          setTitle(data_page_components_obj.communityMenuNo);
          dispatch(
            getAllCommunityNo({ ...data, type: "", communityTypeCode: "2" })
          );

          break;
        case "noCommunityTeams":
          setTitle(data_page_components_obj.profileTeam);
          dispatch(
            getAllCommunityNoTeams({
              ...data,
              type: "5",
              communityTypeCode: "2",
            })
          );

          break;

        case "noCommunityAmbassadors":
          setTitle(data_page_components_obj.profileAmbassador);
          dispatch(
            getAllCommunityNoAmbassador({
              ...data,
              type: "4",
              communityTypeCode: "2",
            })
          );

          break;
        case "noCommunityDelegates":
          setTitle(data_page_components_obj.profileDelegate);
          dispatch(
            getAllCommunityNoDelegate({
              ...data,
              type: "3",
              communityTypeCode: "2",
            })
          );

          break;

        case "noCommunityLeaders":
          setTitle(data_page_components_obj.profileLeader);
          dispatch(
            getAllCommunityNoLeaders({
              ...data,
              type: "2",
              communityTypeCode: "2",
            })
          );

          break;
        case "noCommunityMembers":
          setTitle(data_page_components_obj.profileMember);
          dispatch(
            getAllCommunityNoMembers({
              ...data,
              type: "1",
              communityTypeCode: "2",
            })
          );

          break;
        default:
          setCurrentData([]);
          setShowProgress(false);
      }
      if (activeCategory !== category) {
        setCurrentPage(1);
        setActiveCategory(category);
      }
    },
    [activeCategory, data, dispatch, data_page_components_obj]
  );

  const prevPageRef = useRef();
  useEffect(() => {
    if (prevPageRef.current !== currentPage && activeCategory) {
      handleSelectCategory(activeCategory);
    }
    prevPageRef.current = currentPage;
  }, [currentPage, activeCategory, handleSelectCategory]);

  useEffect(() => {
    prevPageRef.current = 0; //reset the previous page
  }, [activeCategory]);

  const handleClick = useCallback(
    (user) => {
      if (user) {
        window.scrollTo(0, 0);
        navigate(`/${user.username}`);
      }
    },
    [navigate]
  );

  const handlePageClick = useCallback(
    (page) => {
      window.scrollTo(0, 0);
      setActiveCategory(activeCategory);
      setCurrentPage(page);
    },
    [activeCategory]
  );

  return (
    <div id="wrapper">
      <Header />
      <Head pageTitle={data_page_components_obj.profileCommunity} />

      <div className="main-wrapper">
        <section className="text-data">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="fw-bold pt-3 report-title ff2">
                  {data_page_components_obj.profileCommunity}
                </h2>
              </div>
            </div>
          </div>
        </section>
        <section className="modboxwrapper">
          <div className="container">
            <div className="modbox mt-4">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {loading && (
                    <Spinner
                      animation="border"
                      role="status"
                      className="mt-3"
                      size="sm"
                      style={{ display: "block", margin: "0 auto" }}
                    ></Spinner>
                  )}

                  <div className="progress_bar">
                    {showProgress && title === "" && (
                      <ProgressBar progressStatus={progressStatus} />
                    )}
                    {title !== "" && (
                      <h3>
                        {" "}
                        {title}{" "}
                        <span> ({initialDisplayTotalPagesRef.current}) </span>
                      </h3>
                    )}
                  </div>

                  {currentData?.data?.length > 0 ? (
                    currentData?.data.map((item, index) => (
                      <div className="sidebarBox" key={item.profileId}>
                        <div className="sideProfile">
                          <div
                            className="sidebarImg sidebartextImage"
                            style={{
                              background:
                                item?.medias && item?.medias.length > 0
                                  ? `url(${
                                      BASE_API_URL_AVATAR +
                                      "/" +
                                      item?.medias.find(
                                        (media) => media.type === "avatar"
                                      )?.key
                                    }) no-repeat center center/cover`
                                  : item?.colorCode,
                            }}
                          >
                            <p className="">
                              {item?.medias.find(
                                (media) => media.type === "avatar"
                              )?.key
                                ? ""
                                : item?.nameInitials}
                            </p>
                          </div>
                        </div>
                        <div className="sidebarboxContent">
                          <h3
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(item)}
                          >
                            {item.name}

                            {item.certifyInd === "1" ? (
                              <img
                                src="assets/amulab1/img/check-star.svg"
                                alt="Check"
                                className="mb-2"
                                style={{ width: "18px" }}
                              />
                            ) : (
                              ""
                            )}
                          </h3>
                          <div className="ratingReview">
                            {item.yesCommnunityInd === "1" ? (
                              <ReviewProfileSubsYes
                                starNumberSubs={item.starNumber}
                              />
                            ) : (
                              <ReviewProfileSubsNo
                                starNumberSubs={item.starNumber}
                              />
                            )}
                            <p>
                              <span></span> Score {item?.scoreNumber}{" "}
                              <span></span>
                              {item.scoreLevelName}
                            </p>
                          </div>
                          <ShowMoreLessParagraph
                            text={item?.desc}
                            maxLength={160}
                          />
                        </div>
                      </div>
                    ))
                  ) : !loading ? (
                    <div colSpan="4" className="text-center" color="red">
                      <div>
                        <img
                          src="assets/amulab2/images/nodata.png"
                          alt="file"
                          style={{ height: "150px", marginTop: "25px" }}
                        />
                      </div>
                      <div style={{ marginTop: "25px" }}>
                        <h4 style={{ color: "red", opacity: "0.7" }}>
                          {data_page_components_obj.communityMenuNotFound}
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {currentData?.data?.length === undefined ||
                  currentData?.data?.length === 0 ? (
                    ""
                  ) : (
                    <PaginationComponent
                      currentPage={currentPage}
                      initialTotalPages={initialTotalPages}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  all_nations: state.NationsMapReducer.all_nations,
  loading: state.NationsMapReducer.loading,
  error: state.NationsMapReducer.error,
  data_page_components: state.DataPageReducer.data_page_components,
  language: state.ComponentsDataReducer.language,
  subscribers: state.SubscribersPageReducer.subscribers,
  yesCommunity: state.SubscribersPageReducer.yesCommunity,
  yesCommunityTeam: state.SubscribersPageReducer.yesCommunityTeam,
  yesCommunityAmbassador: state.SubscribersPageReducer.yesCommunityAmbassador,
  yesCommunityDelegate: state.SubscribersPageReducer.yesCommunityDelegate,
  yesCommunityLeaders: state.SubscribersPageReducer.yesCommunityLeaders,
  yesCommunityMembers: state.SubscribersPageReducer.yesCommunityMembers,
  noCommunity: state.SubscribersPageReducer.noCommunity,
  noCommunityTeam: state.SubscribersPageReducer.noCommunityTeam,
  noCommunityAmbassador: state.SubscribersPageReducer.noCommunityAmbassador,
  noCommunityDelegate: state.SubscribersPageReducer.noCommunityDelegate,
  noCommunityLeaders: state.SubscribersPageReducer.noCommunityLeaders,
  noCommunityMembers: state.SubscribersPageReducer.noCommunityMembers,
});

export default connect(mapStateToProps)(Home);
