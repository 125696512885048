import {
  GET_ALL_SUBSCRIBERS_PROFILE,
  GET_ALL_SUBSCRIBERS_PROFILE_FAIL,
  GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_TYPE,
  GET_USER_PROFILE_TYPE_FAIL,
  GET_USER_PROFILE_TYPE_SUCCESS,
  RESET_ERROR,
  RESET_POST_SUCCESS,
  RESET_UPDATE_SUCCESS,
  UPDATE_USER_PROFILE_PP,
  UPDATE_USER_PROFILE_PP_FAIL,
  UPDATE_USER_PROFILE_PP_SUCCESS,
  SET_CURRENT_PAGE,
  UPDATE_USER_PROFILE_DESCRIPTION,
  UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS,
  UPDATE_USER_PROFILE_DESCRIPTION_FAIL,
  RESET_UPDATE_SUCCESS_DESCRIPTION,
  UPDATE_USER_PROFILE_YOUTUBE_LINK,
  UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS,
  UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL,
  RESET_UPDATE_SUCCESS_YOUTUBE,
  UPDATE_USER_PROFILE_TYPE,
  UPDATE_USER_PROFILE_TYPE_SUCCESS,
  UPDATE_USER_PROFILE_TYPE_FAIL,
  RESET_UPDATE_SUCCESS_TYPE,
  RESET_UPDATE_SUCCESS_USERNAME,
  UPDATE_USER_PROFILE_USERNAME,
  UPDATE_USER_PROFILE_USERNAME_SUCCESS,
  UPDATE_USER_PROFILE_USERNAME_FAIL,
  UPDATE_USER_PROFILE_NAME,
  UPDATE_USER_PROFILE_NAME_SUCCESS,
  UPDATE_USER_PROFILE_NAME_FAIL,
  RESET_UPDATE_SUCCESS_NAME,
  SUBSCRIBE_BUTTON,
  SUBSCRIBE_BUTTON_SUCCESS,
  SUBSCRIBE_BUTTON_FAILS,
  RESET_SUBSCRIBE_BUTTON,
  NOT_FOUND_DATA,
  RESET_NOT_FOUND_DATA,
  RESET_CLAIM_PROFILE_PAGE_SUCCESS,
  CLAIM_PROFILE_PAGE_FAIL,
  CLAIM_PROFILE_PAGE_SUCCESS,
  CLAIM_PROFILE_PAGE,
  USERNAME_ALREADY_EXISTS,
  RESET_YOUTUBE_LINK_ALREADY_EXISTS,
} from "./actionTypes";

const initialState = {
  user: {},
  subscribersProfile: {},
  loadingModalYoutube: false,
  loadingModalSubscribe: false,
  loadingModalType: false,
  loadingModalName: false,
  loadingModalUsername: false,
  loadingModalDescription: false,
  loadingModalClaim: false,
  loading: false,
  error: null,
  errorUsername: false,
  errorYoutube: null,
  user_type: [],
  updateSuccess: false,
  updateSuccessName: false,
  updateSuccessUsername: false,
  updateSuccessYoutube: false,
  updateSuccessType: false,
  updateSuccessDescription: false,
  updateSuccessPP: false,
  subscribeSuccess: false,
  claimSuccessPage: false,
  currentPage: 1,
  paginationData: {},
  getUser: false,
};

const userProfilePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE:
      return { ...state, loading: true, getUser: false };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        getUser: false,
        user: action.payload.data,
      };
    case GET_USER_PROFILE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_USER_PROFILE_TYPE:
      return { ...state };
    case GET_USER_PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        user_type: action.payload.data,
      };
    case GET_USER_PROFILE_TYPE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_SUBSCRIBERS_PROFILE:
      return { ...state, loading: true };
    case GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        subscribersProfile: action.payload.data,
        paginationData: action.payload.data,
      };
    case GET_ALL_SUBSCRIBERS_PROFILE_FAIL:
      return { ...state, loading: false, error: action.payload };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };

    case UPDATE_USER_PROFILE_NAME:
      return { ...state, loadingModalName: true, updateSuccessName: false };
    case UPDATE_USER_PROFILE_NAME_SUCCESS:
      return {
        ...state,
        loadingModalName: false,
        updateSuccessName: true,
        user: {
          ...state.user,
          name: action.payload.newName,
        },
      };
    case UPDATE_USER_PROFILE_NAME_FAIL:
      return {
        ...state,
        updateSuccessName: false,
        loadingModalName: false,
        error: action.payload,
      };

    case RESET_UPDATE_SUCCESS_NAME:
      return {
        ...state,
        updateSuccessName: false,
      };

    case UPDATE_USER_PROFILE_USERNAME:
      return { ...state, loadingModalUsername: true };
    case UPDATE_USER_PROFILE_USERNAME_SUCCESS:
      return {
        ...state,
        loadingModalUsername: false,
        updateSuccessUsername: true,
        user: {
          ...state.user,
          username: action.payload.newUsername,
        },
      };
    case UPDATE_USER_PROFILE_USERNAME_FAIL:
      return {
        ...state,
        updateSuccessUsername: false,
        loadingModalUsername: false,
        errorUsername: true,
      };

    case USERNAME_ALREADY_EXISTS:
      return {
        ...state,
        updateSuccessUsername: false,
        loadingModalUsername: false,
        errorUsername: false,
      };

    case RESET_UPDATE_SUCCESS_USERNAME:
      return {
        ...state,
        updateSuccessUsername: false,
      };

    case UPDATE_USER_PROFILE_PP:
      return { ...state, updateSuccessPP: false };
    // case UPDATE_USER_PROFILE_PP_SUCCESS:
    //   return {
    //     ...state,

    //     updateSuccessPP: true,
    //     user: {
    //       ...state.user,
    //       medias: state.user.profile.medias.map((media, index) =>
    //         index === 1 ? action.payload.newMedia : media
    //       ),
    //     },
    //   };
    case UPDATE_USER_PROFILE_PP_SUCCESS:
      return {
        ...state,
        updateSuccessPP: true,
        user: {
          ...state.user,
          medias: state.user.profile.medias.map((media) =>
            media.type === "avatar" ? action.payload.newMedia : media
          ),
        },
      };
    case UPDATE_USER_PROFILE_PP_FAIL:
      return {
        ...state,
        updateSuccess: false,
        updateSuccessPP: false,

        error: action.payload,
      };

    case UPDATE_USER_PROFILE_YOUTUBE_LINK:
      return { ...state, loadingModalYoutube: true };

    case UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS:
      return {
        ...state,
        loadingModalYoutube: false,
        updateSuccessYoutube: true,
        user: {
          ...state.user,
          medias: state.user.profile.medias.map((media) =>
            media.type === "youtube" ? action.payload.newMedia : media
          ),
        },
      };

    case UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL:
      return {
        ...state,
        updateSuccessYoutube: false,
        loadingModalYoutube: false,
        errorYoutube: action.payload,
      };

    case RESET_UPDATE_SUCCESS_YOUTUBE:
      return {
        ...state,
        updateSuccessYoutube: false,
      };

    case RESET_YOUTUBE_LINK_ALREADY_EXISTS:
      return {
        ...state,
        errorYoutube: null,
      };

    case UPDATE_USER_PROFILE_TYPE:
      return { ...state, loadingModalType: true, updateSuccessType: false };
    case UPDATE_USER_PROFILE_TYPE_SUCCESS:
      return {
        ...state,
        loadingModalType: false,
        updateSuccessType: true,
        user: {
          ...state.user,
          typeName: action.payload.newTypeName,
        },
      };
    case UPDATE_USER_PROFILE_TYPE_FAIL:
      return {
        ...state,
        updateSuccessType: false,
        loadingModalType: false,
        error: action.payload,
      };

    case RESET_UPDATE_SUCCESS_TYPE:
      return {
        ...state,
        updateSuccessType: false,
      };

    case UPDATE_USER_PROFILE_DESCRIPTION:
      return {
        ...state,
        loadingModalDescription: true,
        updateSuccessDescription: false,
      };
    case UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS:
      return {
        ...state,
        updateSuccessDescription: true,
        loadingModalDescription: false,
        user: {
          ...state.user,
          desc: action.payload.newDesc,
        },
      };
    case UPDATE_USER_PROFILE_DESCRIPTION_FAIL:
      return {
        ...state,
        updateSuccessDescription: false,
        loadingModalDescription: false,
        error: action.payload,
      };
    case RESET_UPDATE_SUCCESS_DESCRIPTION:
      return {
        ...state,
        updateSuccessDescription: false,
      };

    case SUBSCRIBE_BUTTON:
      return {
        ...state,
        loadingModalSubscribe: true,
        subscribeSuccess: false,
      };
    case SUBSCRIBE_BUTTON_SUCCESS:
      return {
        ...state,
        subscribeSuccess: true,
        loadingModalSubscribe: false,
      };
    case SUBSCRIBE_BUTTON_FAILS:
      return {
        ...state,
        subscribeSuccess: false,
        loadingModalSubscribe: false,
      };

    case RESET_SUBSCRIBE_BUTTON:
      return {
        ...state,
        subscribeSuccess: false,
      };

    case CLAIM_PROFILE_PAGE:
      return { ...state, loadingModalClaim: false, claimSuccessPage: false };
    case CLAIM_PROFILE_PAGE_SUCCESS:
      return {
        ...state,
        claimSuccessPage: true,
        loadingModalClaim: false,
        // user: {
        //   ...state.user,
        //   claimedStatusCode: action.payload.newStatus,
        // },
      };
    case CLAIM_PROFILE_PAGE_FAIL:
      return {
        ...state,
        claimSuccessPage: false,
        loadingModalClaim: false,
        error: action.payload,
      };

    case RESET_CLAIM_PROFILE_PAGE_SUCCESS:
      return {
        ...state,
        claimSuccessPage: false,
        loadingModalClaim: false,
      };

    case RESET_POST_SUCCESS:
      return {
        ...state,
        postSuccess: false,
      };

    case RESET_UPDATE_SUCCESS:
      return {
        ...state,
        updateSuccess: true,
      };

    case RESET_ERROR:
      return { ...state, error: null };

    case NOT_FOUND_DATA:
      return { ...state, loading: false, getUser: true };

    case RESET_NOT_FOUND_DATA:
      return { ...state, getUser: false };

    default:
      return state;
  }
};

export default userProfilePageReducer;
