export const GET_ALL_META_HEADER_FOOTER = "GET_ALL_META_HEADER_FOOTER";
export const GET_ALL_META_HEADER_FOOTER_SUCCESS =
  "GET_ALL_META_HEADER_FOOTER_SUCCESS";
export const GET_ALL_META_HEADER_FOOTER_FAIL =
  "GET_ALL_META_HEADER_FOOTER_FAIL";

//LANGUAGE SWITCHER
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const GET_ALL_DATA_STEP_PAGE = "GET_ALL_DATA_STEP_PAGE";
export const GET_ALL_DATA_STEP_PAGE_SUCCESS = "GET_ALL_DATA_STEP_PAGE_SUCCESS";
export const GET_ALL_DATA_STEP_PAGE_FAIL = "GET_ALL_DATA_STEP_PAGE_FAIL";

export const GET_ALL_DATA_AGREEMENT_PAGE = "GET_ALL_DATA_AGREEMENT_PAGE";
export const GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS =
  "GET_ALL_DATA_AGREEMENT_PAGE_SUCCESS";
export const GET_ALL_DATA_AGREEMENT_PAGE_FAIL =
  "GET_ALL_DATA_AGREEMENT_PAGE_FAIL";

export const GET_ALL_DATA_MOTTO_PAGE = "GET_ALL_DATA_MOTTO_PAGE";
export const GET_ALL_DATA_MOTTO_PAGE_SUCCESS =
  "GET_ALL_DATA_MOTTO_PAGE_SUCCESS";
export const GET_ALL_DATA_MOTTO_PAGE_FAIL = "GET_ALL_DATA_MOTTO_PAGE_FAIL";
