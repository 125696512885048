import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import Head from "../components/Head";

const Agreements = ({ data_agreement_page }) => {
  return (
    <div id="wrapper">
      <Header />
      <Head pageTitle={data_agreement_page[0]?.title} />

      <div className="main-wrapper">
        <section className="text-data">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="fw-bold pt-3 report-title ff2">
                  {data_agreement_page[0]?.title}{" "}
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="modboxwrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="modbox">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[1]?.contents[0],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[2]?.contents[0],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[3]?.title,
                    }}
                  />

                  <div className="accordion" id="accordion-provider">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollaps"
                          aria-expanded="false"
                          aria-controls="providercollaps"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[4]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollaps"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingOne"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[4]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsTwo"
                          aria-expanded="false"
                          aria-controls="providercollapsTwo"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[5]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingTwo"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[5]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingThree"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsThree"
                          aria-expanded="false"
                          aria-controls="providercollapsThree"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[6]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingThree"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[6]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFour"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFour"
                          aria-expanded="false"
                          aria-controls="providercollapsFour"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[7]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFour"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[7]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFive"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFive"
                          aria-expanded="false"
                          aria-controls="providercollapsFive"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[8]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFive"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[8]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsSix"
                          aria-expanded="false"
                          aria-controls="providercollapsSix"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[9]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingSix"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[9]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingSeven"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsSeven"
                          aria-expanded="false"
                          aria-controls="providercollapsSeven"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[10]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsSeven"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingSeven"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[10]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingEight"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsEight"
                          aria-expanded="false"
                          aria-controls="providercollapsEight"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[11]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingEight"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[11]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingNine"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsNine"
                          aria-expanded="false"
                          aria-controls="providercollapsNine"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[12]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingNine"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[12]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="provider-headingTen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsTen"
                          aria-expanded="false"
                          aria-controls="providercollapsTen"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[13]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingTen"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[13]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingEleven"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsEleven"
                          aria-expanded="false"
                          aria-controls="providercollapsEleven"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[14]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsEleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingEleven"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[14]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingTwelve"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsTwelve"
                          aria-expanded="false"
                          aria-controls="providercollapsTwelve"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[15]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsTwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingTwelve"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[15]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingThirteen"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsThirteen"
                          aria-expanded="false"
                          aria-controls="providercollapsThirteen"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[16]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsThirteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingThirteen"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[16]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFourteen"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFourteen"
                          aria-expanded="false"
                          aria-controls="providercollapsFourteen"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[17]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsFourteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFourteen"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[17]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="provider-headingFifteen"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#providercollapsFifteen"
                          aria-expanded="false"
                          aria-controls="providercollapsFifteen"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data_agreement_page[18]?.title,
                            }}
                          />
                        </button>
                      </h2>
                      <div
                        id="providercollapsFifteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="provider-headingFifteen"
                        data-bs-parent="#accordion-provider"
                      >
                        <div className="accordion-body">
                          <ol className="custom-number-listing">
                            {data_agreement_page[18]?.contents.map(
                              (item, index) => (
                                <li key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item,
                                    }}
                                  />
                                </li>
                              )
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                    {/* <!--/.accordion-item--> */}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[19]?.contents[0],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[20]?.contents[0],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[21]?.contents[0],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data_agreement_page[22]?.contents[0],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_agreement_page: state.ComponentsDataReducer.data_agreement_page,
  // loading: state.NationsMapReducer.loading,
  // error: state.NationsMapReducer.error,
});

export default connect(mapStateToProps)(Agreements);
