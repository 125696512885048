// export const BASE_API_URL = "https://api.amulab.app/api/v1";
export const BASE_API_URL = "https://api.duegho.com/api/v1";

// PAGE CONTENT
export const BASE_API_URL_PAGE_CONTENT = "https://apijs.duegho.com";

//AVATAR

export const BASE_API_URL_AVATAR = "https://dghos3.b-cdn.net";

// LOGIN
export const LOGIN_USER = "/auth/login";
export const NEW_LOGIN_USER = "/auth/retrieveToken";
export const GET_USER_PROFILE = (data) => {
  const queryString = Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
  return `/amulab/getProfile?${queryString}`;
};

// SUBSCRIBERS DATA
export const GET_ALL_SUBSCRIBERS = "/amulab/getAllSubscribers";

export const GET_ALL_SUBSCRIBERS_PROFILE = `/amulab/getProfileSubscribers?`;

export const GET_ALL_USER_PROFILE_TYPE = (language_detect) =>
  `/amulab/getProfileAmulabTypes?languageCode=${language_detect}`;

export const UPDATE_USER_PROFILE = `/amulab/updateProfile`;
export const SUBSCRIBER_BUTTON_PROFILE = "./amulab/subscribe";
export const UPDATE_USER_PROFILE_CLAIM = `/amulab/claimProfile`;
export const UPDATE_USER_PROFILE_PP = `entity/avatar/upload/amulab/1000100v10013e51784ad39461296af47a0641c1bd2`;

//GET ALL NATIONS MAP
export const GET_ALL_NATIONS = `/Nation/getNationGeoAsync`;
// export const GET_ALL_NATIONS = `/p/getNationGeoAsync/`;

// GET NATIONS LIST
export const GET_ALL_NATIONS_LIST = "/pagecontent/get";
// META / HEADER / FOOTER
export const GET_ALL_META_HEADER_FOOTER = "/pagecontent/get";

// CONTENT PAGES DATA
export const GET_ALL_CONTENTS_STATIC_PAGES = (language_detect) =>
  `/statictext?appName=amulab&apiKey=1000100v10013e51784ad39461296af47a0641c1bd2&languageCode=${language_detect}`;
export const GET_ALL_CONTENTS_STEP_PAGES = `/pagecontent/get`;
