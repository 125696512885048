import {
  GET_ALL_CONTENT_STATIC_PAGE,
  GET_ALL_CONTENT_STATIC_PAGE_FAIL,
  GET_ALL_CONTENT_STATIC_PAGE_SUCCESS,
} from "./actionTypes";

export const getAllDataPages = (data_meta) => ({
  type: GET_ALL_CONTENT_STATIC_PAGE,
  payload: data_meta,
});

export const getAllDataPagesSuccess = (all_meta_component) => ({
  type: GET_ALL_CONTENT_STATIC_PAGE_SUCCESS,
  payload: all_meta_component,
});

export const getAllDataPagesFail = (error) => ({
  type: GET_ALL_CONTENT_STATIC_PAGE_FAIL,
  payload: error,
});
