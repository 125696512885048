import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  claimUserProfilePage,
  resetClaimSuccessPost,
} from "../../../store/actions";
import Swal from "sweetalert2";

const ClaimedProfile = (props) => {
  const { data_page_components, claimSuccessPage, loadingModalClaim } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const apiKey = process.env.REACT_APP_API_KEY_DATA;

  const dispatch = useDispatch();

  const { username } = useParams();

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  const data = {
    apiKey: apiKey,
    id: username,
    idTypeCode: "username",
    deletionInd: selectedOption,
    message: message,
    ipAddress: "192.168.205.1",
  };

  const handleClaim = () => {
    let errors = {};

    if (!selectedOption) {
      errors["radio"] = "Veuillez sélectionner une option.";
    }

    if (message.length < 20) {
      errors["text"] = "Le texte doit comporter au moins 20 caractères.";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      dispatch(claimUserProfilePage(data));
    }
  };

  useEffect(() => {
    let timer;
    if (claimSuccessPage) {
      props.toggleReclamation();
      Swal.fire({
        title: `${data_page_components_obj.profileClaimSuccess}`,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      timer = setTimeout(() => {
        dispatch(resetClaimSuccessPost());
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [claimSuccessPage, data_page_components_obj.profileClaimSuccess]);

  return (
    <div>
      <Modal isOpen={props.reclamation} toggle={props.toggleReclamation}>
        <ModalHeader toggle={props.toggleReclamation}>
          {data_page_components_obj.profileNotClaimedTitle}
        </ModalHeader>
        <ModalBody>
          <legend> {data_page_components_obj.profileNotClaimedQuestion}</legend>
          <div className="d-flex">
            <div>
              <FormGroup tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      className="radioInput"
                      onChange={() => setSelectedOption("0")}
                    />{" "}
                    {data_page_components_obj.profileNotClaimedAcceptation}
                  </Label>
                </FormGroup>
              </FormGroup>
            </div>
            <div>
              {" "}
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    className="radioInput"
                    onChange={() => setSelectedOption("1")}
                  />{" "}
                  {data_page_components_obj.profileNotClaimedDeletion}
                </Label>
              </FormGroup>
            </div>
          </div>

          <FormGroup>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              style={{ minHeight: "300px" }}
              onChange={handleChange}
              placeholder={data_page_components_obj.profileNotClaimedContact}
            />
            {errors["text"] && (
              <div className="error" style={{ color: "red" }}>
                {errors["text"]}
              </div>
            )}
            {errors["radio"] && (
              <div className="error" style={{ color: "red" }}>
                {errors["radio"]}
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={props.toggleReclamation}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleClaim}>
            {loadingModalClaim ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              "Ok"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
  claimSuccessPage: state.UserProfilePageReducer.claimSuccessPage,
  loadingModalClaim: state.UserProfilePageReducer.loadingModalClaim,
});

export default connect(mapStateToProps)(ClaimedProfile);
