import { combineReducers } from "redux";

import NationsMapReducer from "./nations/reducer";
import ComponentsDataReducer from "./components_pages/reducer";
import DataPageReducer from "./master_pages/reducer";
import LoginReducer from "./login/reducers";
import SubscribersPageReducer from "./community/reducer";
import UserProfilePageReducer from "./account/reducer";
const rootReducer = combineReducers({
  NationsMapReducer,
  ComponentsDataReducer,
  DataPageReducer,
  LoginReducer,
  SubscribersPageReducer,
  UserProfilePageReducer,
});

export default rootReducer;
