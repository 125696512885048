import axios from "axios";
import Cookies from "js-cookie";
import {
  BASE_API_URL_PAGE_CONTENT,
  GET_ALL_META_HEADER_FOOTER,
  GET_ALL_CONTENTS_STATIC_PAGES,
  GET_ALL_CONTENTS_STEP_PAGES,
} from "../url_helper";

// Créez une instance d'axios avec la configuration par défaut
const axiosInstance = axios.create({
  baseURL: BASE_API_URL_PAGE_CONTENT,
});

// Ajoutez un intercepteur de requête pour inclure le token dans l'en-tête Authorization
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    // Faites quelque chose avec l'erreur de requête
    return Promise.reject(error);
  }
);

// Ajoutez un intercepteur de réponse pour gérer les réponses globalement
axiosInstance.interceptors.response.use(
  (response) => {
    // Toute réponse de statut 2xx provoque l'exécution de ce code
    return response;
  },
  (error) => {
    // Toute réponse en dehors de la plage 2xx provoque l'exécution de ce code
    // Vous pouvez ici gérer les erreurs, par exemple une redirection sur une erreur 401
    if (error.response && error.response.status === 401) {
      // Déconnectez l'utilisateur, supprimez le token, redirigez, etc.
      Cookies.remove("access_token");
      //   window.location.href = "/login";
    }

    // Vous pouvez également traiter d'autres codes d'état ici si nécessaire
    return Promise.reject(error);
  }
);

export const getAllMetaHeaderAndFooter = async (data) => {
  const response = await axiosInstance.post(GET_ALL_META_HEADER_FOOTER, data);
  return response.data;
};

export const getAllContentsStaticPages = async (language_detect) => {
  const url_get_content_page = GET_ALL_CONTENTS_STATIC_PAGES(language_detect);
  const response = await axiosInstance.get(url_get_content_page);
  return response.data;
};

export const getAllContentsStepPage = async (data) => {
  const response = await axiosInstance.post(GET_ALL_CONTENTS_STEP_PAGES, data);
  return response.data;
};
