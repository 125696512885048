export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const GET_ALL_SUBSCRIBERS_PROFILE = "GET_ALL_SUBSCRIBERS_PROFILE";
export const GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS =
  "GET_ALL_SUBSCRIBERS_PROFILE_SUCCESS";
export const GET_ALL_SUBSCRIBERS_PROFILE_FAIL =
  "GET_ALL_SUBSCRIBERS_PROFILE_FAIL";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const UPDATE_USER_PROFILE_DESCRIPTION =
  "UPDATE_USER_PROFILE_DESCRIPTION";
export const UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS =
  "UPDATE_USER_PROFILE_DESCRIPTION_SUCCESS";
export const UPDATE_USER_PROFILE_DESCRIPTION_FAIL =
  "UPDATE_USER_PROFILE_DESCRIPTION_FAIL";

export const RESET_UPDATE_SUCCESS_DESCRIPTION =
  "RESET_UPDATE_SUCCESS_DESCRIPTION";

export const UPDATE_USER_PROFILE_USERNAME = "UPDATE_USER_PROFILE_USERNAME";
export const UPDATE_USER_PROFILE_USERNAME_SUCCESS =
  "UPDATE_USER_PROFILE_USERNAME_SUCCESS";
export const UPDATE_USER_PROFILE_USERNAME_FAIL =
  "UPDATE_USER_PROFILE_USERNAME_FAIL";
export const USERNAME_ALREADY_EXISTS = "USERNAME_ALREADY_EXISTS";

export const RESET_UPDATE_SUCCESS_USERNAME = "RESET_UPDATE_SUCCESS_USERNAME";

export const UPDATE_USER_PROFILE_NAME = "UPDATE_USER_PROFILE_NAME";
export const UPDATE_USER_PROFILE_NAME_SUCCESS =
  "UPDATE_USER_PROFILE_NAME_SUCCESS";
export const UPDATE_USER_PROFILE_NAME_FAIL = "UPDATE_USER_PROFILE_NAME_FAIL";

export const RESET_UPDATE_SUCCESS_NAME = "RESET_UPDATE_SUCCESS_NAME";

export const UPDATE_USER_PROFILE_AVATAR = "UPDATE_USER_PROFILE_AVATAR";
export const UPDATE_USER_PROFILE_AVATAR_SUCCESS =
  "UPDATE_USER_PROFILE_AVATAR_SUCCESS";
export const UPDATE_USER_PROFILE_AVATAR_FAIL =
  "UPDATE_USER_PROFILE_AVATAR_FAIL";

export const GET_USER_PROFILE_TYPE = "GET_USER_PROFILE_TYPE";
export const GET_USER_PROFILE_TYPE_SUCCESS = "GET_USER_PROFILE_TYPE_SUCCESS";
export const GET_USER_PROFILE_TYPE_FAIL = "GET_USER_PROFILE_TYPE_FAIL";

export const UPDATE_USER_PROFILE_TYPE = "UPDATE_USER_PROFILE_TYPE";
export const UPDATE_USER_PROFILE_TYPE_SUCCESS =
  "UPDATE_USER_PROFILE_TYPE_SUCCESS";
export const UPDATE_USER_PROFILE_TYPE_FAIL = "UPDATE_USER_PROFILE_TYPE_FAIL";

export const RESET_UPDATE_SUCCESS_TYPE = "RESET_UPDATE_SUCCESS_TYPE";

export const UPDATE_USER_PROFILE_YOUTUBE_LINK =
  "UPDATE_USER_PROFILE_YOUTUBE_LINK";
export const UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS =
  "UPDATE_USER_PROFILE_YOUTUBE_LINK_SUCCESS";
export const UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL =
  "UPDATE_USER_PROFILE_YOUTUBE_LINK_FAIL";
export const YOUTUBE_LINK_ALREADY_EXISTS = "YOUTUBE_LINK_ALREADY_EXISTS";

export const RESET_UPDATE_SUCCESS_YOUTUBE = "RESET_UPDATE_SUCCESS_YOUTUBE";
export const RESET_YOUTUBE_LINK_ALREADY_EXISTS =
  "RESET_YOUTUBE_LINK_ALREADY_EXISTS";

export const UPDATE_USER_PROFILE_PP = "UPDATE_USER_PROFILE_PP";
export const UPDATE_USER_PROFILE_PP_SUCCESS = "UPDATE_USER_PROFILE_PP_SUCCESS";
export const UPDATE_USER_PROFILE_PP_FAIL = "UPDATE_USER_PROFILE_PP_FAIL";

export const RESET_POST_SUCCESS = "RESET_POST_SUCCESS";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";

export const SUBSCRIBE_BUTTON = "SUBSCRIBE_BUTTON";
export const SUBSCRIBE_BUTTON_SUCCESS = "SUBSCRIBE_BUTTON_SUCCESS";
export const SUBSCRIBE_BUTTON_FAILS = "SUBSCRIBE_BUTTON_FAILS";

export const RESET_SUBSCRIBE_BUTTON = "RESET_SUBSCRIBE_BUTTON";

// CLAIM PROFILE PAGE
export const CLAIM_PROFILE_PAGE = "CLAIM_PROFILE_PAGE";
export const CLAIM_PROFILE_PAGE_SUCCESS = "CLAIM_PROFILE_PAGE_SUCCESS";
export const CLAIM_PROFILE_PAGE_FAIL = "CLAIM_PROFILE_PAGE_FAIL";

export const RESET_CLAIM_PROFILE_PAGE_SUCCESS =
  "RESET_CLAIM_PROFILE_PAGE_SUCCESS";

export const NOT_FOUND_DATA = "NOT_FOUND_DATA";
export const RESET_NOT_FOUND_DATA = "RESET_NOT_FOUND_DATA";
