import Agreements from "../pages/Agreements";
import AllInOne from "../pages/AllInOne";
import Community from "../pages/communityPages/Community";
import Home from "../pages/Nations/Home";
import Books from "../pages/MorePages/Books";
import Coups from "../pages/MorePages/Coups";
import IfOnlyPage from "../pages/MorePages/IfOnlyPage";
import World from "../pages/MorePages/World";
import SovereignFund from "../pages/SovereignFund";
import StepLabo from "../pages/StepLabo";
import Teams from "../pages/Teams";
import YesPage from "../pages/YesPage";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassxord from "../pages/Authentication/ForgotPassxord";
import MaintenancePage from "../pages/OtherPages/MaintenancePage";
import Nations_List from "../pages/Nations/Nations_List";
import BestTime from "../pages/MorePages/BestTime";
import DeviseLabo from "../pages/DeviseLabo";
import SubscriberProfile from "../pages/communityPages/SubscriberProfile";
import NotFound from "../pages/OtherPages/NotFound";

const userRoutes = [
  { path: "/", component: Home },
  { path: "/nationslist", component: Nations_List },
  { path: "/steps", component: StepLabo },
  { path: "/agreement", component: Agreements },
  { path: "/funds", component: SovereignFund },
  { path: "/allinone", component: AllInOne },
  { path: "/yesbut", component: YesPage },
  { path: "/community", component: Community },
  { path: "/teams", component: Teams },
  { path: "/book", component: Books },
  { path: "/ifonlyforthis", component: IfOnlyPage },
  { path: "/coupsdetat", component: Coups },
  { path: "/multipolarworld", component: World },
  { path: "/besttime", component: BestTime },
  { path: "/motto", component: DeviseLabo },
  { path: "/:username", component: SubscriberProfile },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "login/:id", component: Login },
  { path: "/forgot-password", component: ForgotPassxord },
  { path: "/maintenance", component: MaintenancePage },
  { path: "/notfound", component: NotFound },
];

export { userRoutes, authRoutes };
