import { call, put, takeEvery, select } from "redux-saga/effects";
import { LOGOUT_USER, AUTHENTICATE_USER, LOGIN_USER } from "./actionTypes";
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  newLoginUserSuccess,
} from "./actions";

import Cookies from "js-cookie";
import {
  getBrowserLanguage,
  getUserProfile,
  loginUserRequest,
  newLoginUserRequest,
} from "../../helpers/api_helper";

const apiKey = process.env.REACT_APP_API_KEY;

function* loginUser(action) {
  const user = action.payload.user;
  const navigate = action.payload.navigate;
  const currentLanguage = yield select((state) => state.language);
  const newLanguage =
    localStorage.getItem("selectedLanguage") ||
    currentLanguage ||
    (yield call(getBrowserLanguage));

  try {
    const response = yield call(loginUserRequest, user);

    if (response.data && response.data.returnCode === 200) {
      const { authToken, expiresUtcSec } = response.data.data;
      const expires = new Date(new Date().getTime() + expiresUtcSec * 1000);
      localStorage.setItem("authToken", authToken, { expires, secure: true });
      yield put(loginSuccess(response.data));
      Cookies.set("_SSDID_", response.data.data.accountId);

      let requestData = {
        apiKey: apiKey,
        id: `${response.data.data.accountId}`,
        cultureInfo: `${newLanguage.substring(0, 2)}`,
        idTypeCode: `id`,
        viewerId: `${response.data.data.accountId}`,
        languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
      };
      const checkProfile = yield call(getUserProfile, requestData);
      Cookies.set("checkProfile", checkProfile.data.profile.username);
      Cookies.set("requestData", JSON.stringify(requestData));
      if (!checkProfile) {
        navigate("/community");
      } else if (checkProfile.data.profile.username) {
        navigate(`/${checkProfile.data.profile.username}`);
      }
    } else {
      let errorMessage = "Une erreur s'est produite vérifier votre réseau.";
      // if (response.data && response.data.returnCode !== 200) {
      //   throw new Error(response.data.data);
      // }

      switch (response.data.returnCode) {
        case 404:
          errorMessage = "Le compte n'existe pas";
          break;
        case 10:
          errorMessage = "Adresse e-mail ou mot de passe incorrect";
          break;

        default:
          errorMessage = response.data.message || errorMessage;
          break;
      }
      yield put(apiError(errorMessage));
    }
  } catch (error) {
    navigate("/community");
    let errorMessage;
    // "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.";
    yield put(apiError(errorMessage));
  }
}

function* loginUserAmu(action) {
  const users = action.payload.users;
  const navigate = action.payload.navigate;
  const currentLanguage = yield select((state) => state.language);
  const newLanguage =
    localStorage.getItem("selectedLanguage") ||
    currentLanguage ||
    (yield call(getBrowserLanguage));
  console.log(users, "users");
  console.log(navigate, "navigate");
  try {
    const response = yield call(newLoginUserRequest, users);

    if (response.data && response.data.returnCode === 200) {
      const { authToken, expiresUtcSec } = response.data.data;
      const expires = new Date(new Date().getTime() + expiresUtcSec * 1000);

      yield put(newLoginUserSuccess(response.data));

      localStorage.setItem("authToken", authToken, { expires, secure: true });
      Cookies.set("_SSDID_", response.data.data.accountId);

      let requestData = {
        apiKey: apiKey,
        id: `${response.data.data.accountId}`,
        cultureInfo: `${newLanguage.substring(0, 2)}`,
        idTypeCode: `id`,
        viewerId: `${response.data.data.accountId}`,
        languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
      };
      Cookies.set("requestData", JSON.stringify(requestData));

      const checkProfile = yield call(getUserProfile, requestData);
      Cookies.set("checkProfile", checkProfile.data.profile.username);
      if (!checkProfile) {
        navigate("/community");
      } else if (checkProfile.data.profile.username) {
        navigate(`/${checkProfile?.data?.profile?.username}`);
      }
    } else {
      let errorMessage = "Une erreur s'est produite vérifier votre réseau.";
      // if (response.data && response.data.returnCode !== 200) {
      //   throw new Error(response.data.data);
      // }

      switch (response.data.returnCode) {
        case 404:
          errorMessage = "Le compte n'existe pas";
          break;
        case 10:
          errorMessage = "Adresse e-mail ou mot de passe incorrect";
          break;

        default:
          errorMessage = response.data.message || errorMessage;
          break;
      }
      yield put(apiError(errorMessage));
    }
  } catch (error) {
    navigate("/community");
    let errorMessage;
    // "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.";

    yield put(apiError(errorMessage));
  }
}

function* logoutUserSaga({ payload: { navigate } }) {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("authToken");
    Cookies.remove("_SSDID_");
    Cookies.remove("subscriberList");
    Cookies.remove("id_profile");
    Cookies.remove("requestData");
    Cookies.remove("checkProfile");
    navigate("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(AUTHENTICATE_USER, loginUserAmu);
  yield takeEvery(LOGOUT_USER, logoutUserSaga);
}

export default authSaga;
