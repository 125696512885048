import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { connect, useDispatch } from "react-redux";
import Footer from "../../components/Footer";
import { Spinner } from "reactstrap";
import { getAllNations, getAllNationsSuccess } from "../../store/actions";
import "../style.css";
import Head from "../../components/Head";
const Nations_List = ({
  nations_list,
  loading,
  all_nations,
  data_page_components,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    // Check if data exists in localStorage
    const storedData = localStorage.getItem("all_nations");
    if (storedData) {
      // Use the data from localStorage
      dispatch(getAllNationsSuccess(JSON.parse(storedData)));
    } else {
      dispatch(getAllNations());
    }
  }, [dispatch]);

  const renderPreviousCountries = (previousCountries) => {
    if (previousCountries) {
      const countriesArray = previousCountries.split(", ");
      return countriesArray.map((country, index) => (
        <div key={index}>
          {country}
          {index < countriesArray.length - 1 && <br />}
        </div>
      ));
    }
    return null;
  };

  const data_page_components_obj = data_page_components?.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  const nouvelleChaîne = data_page_components_obj.paginationShowingEntriesText
    .replace("{0}", 1)
    .replace("{1}", itemsPerPage)
    .replace("{2}", 265);

  const filteredNations = all_nations
    ?.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.properties.nationName === value.properties.nationName
        )
    )
    .filter(
      (uniqueNation) =>
        uniqueNation.properties.nationName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        uniqueNation.properties.previousCountries
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNations.slice(indexOfFirstItem, indexOfLastItem);

  // Déplacez la déclaration de pageNumbers ici
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredNations.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Afficher seulement 5 pages à la fois
  const maxPagesToShow = 5;
  const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(startPage + maxPagesToShow - 1, pageNumbers.length);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pageNumbers.length));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  return (
    <div>
      <Header />
      <Head pageTitle={nations_list[0]?.title} />

      <div id="wrapper">
        <div className="main-wrapper">
          <section className="text-data">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="fw-bold pt-3 report-title ff2">
                    {nations_list[0]?.title}
                  </h2>
                </div>
              </div>
            </div>
          </section>

          <section className="modboxwrapper">
            <div className="container">
              <div className="row">
                <div className="modbox ">
                  <div
                    className="text_search_form mb-2"
                    style={{ marginBottom: "-15px " }}
                  >
                    <form action="#" className="row align-items-left">
                      <label htmlFor="text_search" className="col-sm-2 fw-bold">
                        {data_page_components_obj.keyWordSearch}
                      </label>
                      <div className="col-sm-4">
                        <input
                          type="text"
                          name="text_search"
                          id="text_search"
                          className="form-control"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                  <div id="loading-message">
                    {loading ? <Spinner></Spinner> : ""}
                  </div>
                  <div className="topfilter">
                    <div
                      className="dataTables_info"
                      id="datatable_info"
                      role="status"
                      aria-live="polite"
                    >
                      {nouvelleChaîne}
                    </div>
                    <div className="dataTables_length" id="datatable_length">
                      <label>
                        {data_page_components_obj.paginationShow}
                        <select
                          name="datatable_length"
                          aria-controls="datatable"
                          className=""
                          value={itemsPerPage}
                          style={{ margin: "5px" }}
                          onChange={(e) =>
                            setItemsPerPage(parseInt(e.target.value))
                          }
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        {data_page_components_obj.paginationEntries}
                      </label>
                    </div>
                  </div>
                  <div className="datatablewrapper">
                    {filteredNations.length === 0 ? (
                      <p style={{ color: "red" }}>
                        {" "}
                        {data_page_components_obj.searchNationNotFound}
                      </p>
                    ) : (
                      <table
                        id="datatable"
                        className="table-responsive table-striped table-hover dataTable no-footer"
                        aria-describedby="datatable_info"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          {/* <tr > */}
                          <tr>
                            <th> {data_page_components_obj.tableNationName}</th>
                            <th>
                              {" "}
                              {
                                data_page_components_obj.tableNationAlternateName
                              }{" "}
                            </th>
                            <th>{data_page_components_obj.area} </th>
                            <th>
                              {data_page_components_obj.tableNationRegion}
                            </th>
                            <th>
                              {" "}
                              {
                                data_page_components_obj.tableNationPreviousCountries
                              }{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.map((uniqueNation, index) => (
                            <tr key={uniqueNation.id} className="even">
                              <td>{uniqueNation.properties.nationName}</td>
                              <td style={{ padding: "10px 18px" }}>
                                {uniqueNation.properties.nationOtherName}
                              </td>
                              <td>{uniqueNation.properties.area}</td>
                              <td>{uniqueNation.properties.regionName}</td>
                              <td>
                                {/* {uniqueNation.properties.previousCountries} */}
                                {renderPreviousCountries(
                                  uniqueNation.properties.previousCountries
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                    {/* Pagination */}
                    <div className="bottompagination">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="datatable_paginate"
                      >
                        <ul className="pagination">
                          <li>
                            <button
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                            >
                              {data_page_components_obj.paginationPrevious}
                            </button>
                          </li>
                          {startPage > 1 && (
                            <li>
                              <button onClick={() => handlePageChange(1)}>
                                1
                              </button>
                            </li>
                          )}
                          {startPage > 2 && <li>...</li>}
                          {pageNumbers
                            .slice(startPage - 1, endPage)
                            .map((number) => (
                              <li
                                key={number}
                                className={
                                  currentPage === number ? "active" : ""
                                }
                              >
                                <button
                                  className={
                                    currentPage === number ? "active" : ""
                                  }
                                  onClick={() => handlePageChange(number)}
                                >
                                  {number}
                                </button>
                              </li>
                            ))}
                          {endPage < pageNumbers.length - 1 && <li>...</li>}
                          {endPage < pageNumbers.length && (
                            <li>
                              <button
                                onClick={() =>
                                  handlePageChange(pageNumbers.length)
                                }
                              >
                                {pageNumbers.length}
                              </button>
                            </li>
                          )}
                          <li>
                            <button
                              onClick={handleNextPage}
                              disabled={currentPage === pageNumbers.length}
                            >
                              {data_page_components_obj.btnNext}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!--/.row--> */}
              </div>
            </div>
            {/* /.container */}
          </section>
          {/* /.modboxwrapper */}
        </div>
        {/* /.main-wrapper */}

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  all_nations: state.NationsMapReducer.all_nations,
  data_page_components: state.DataPageReducer.data_page_components,
  nations_list: state.NationsMapReducer.nations_list,
  loading: state.NationsMapReducer.loading,
  error: state.NationsMapReducer.error,
});

export default connect(mapStateToProps)(Nations_List);
