import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import {
  resetUpdateSuccessDescription,
  updateUserProfile_description,
} from "../../../store/actions";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";

const DescriptionModal = (props) => {
  const {
    data_page_components,
    loadingModalDescription,
    updateSuccessDescription,
  } = props;

  const [desc, setDesc] = useState("");
  const [errors, setErrors] = useState({});
  const apiKey = process.env.REACT_APP_API_KEY_DATA;
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setDesc(event.target.value);
  };
  const data = {
    apiKey: apiKey,
    desc: desc,
  };
  const handleUpdate = () => {
    let errors = {};
    if (desc.length < 20) {
      errors["desc"] = "Description must be at least 20 characters long.";
    }
    if (desc.length > 500) {
      errors["desc"] = "Description must be less than 500 characters long.";
    }
    if (!desc) {
      errors["desc"] = "Description is required.";
    }
    if (desc && desc.length >= 3 && desc.length <= 500) {
      dispatch(updateUserProfile_description(data));
    }
    setErrors(errors);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});

  useEffect(() => {
    if (updateSuccessDescription) {
      props.onUpdateDescription(desc);
      props.toggleDescription();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data_page_components_obj.profileSuccessSaved}`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateSuccessDescription());
    }
  }, [
    dispatch,
    data_page_components_obj.profileSuccessSaved,
    updateSuccessDescription,
    desc,
    props,
  ]);

  return (
    <div>
      <Modal isOpen={props.modalDescription} toggle={props.toggleDescription}>
        <ModalHeader toggle={props.toggleDescription}>
          {data_page_components_obj.profileYoutubeUrlUpdateDesc}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              style={{ minHeight: "300px" }}
              onChange={handleChange}
              defaultValue={props.description}
            />
          </FormGroup>
          <div className="text-danger">{errors.desc}</div>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={props.toggleDescription}>
            {data_page_components_obj.btnCancel}
          </Button>
          <Button color="success" onClick={handleUpdate}>
            {loadingModalDescription ? (
              <Spinner
                className="m-1"
                color="info"
                style={{ height: "15px", width: "15px" }}
              />
            ) : (
              `${data_page_components_obj.btnSave}`
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data_page_components: state.DataPageReducer.data_page_components,
  loadingModalDescription: state.UserProfilePageReducer.loadingModalDescription,
  error: state.UserProfilePageReducer.error,
  updateSuccessDescription:
    state.UserProfilePageReducer.updateSuccessDescription,
});

export default connect(mapStateToProps)(DescriptionModal);
