import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = (props) => {
  const MAX_PAGES_DISPLAYED = 5;

  const getPaginationItems = () => {
    let items = [];
    let startPage = Math.max(
      1,
      props.currentPage - Math.floor(MAX_PAGES_DISPLAYED / 2)
    );
    let endPage = Math.min(
      props.initialTotalPages,
      startPage + MAX_PAGES_DISPLAYED - 1
    );

    // Ajuster si le nombre de pages est inférieur au MAX_PAGES_DISPLAYED
    if (endPage - startPage + 1 < MAX_PAGES_DISPLAYED) {
      startPage = Math.max(1, endPage - MAX_PAGES_DISPLAYED + 1);
    }

    // Points de suspension au début
    if (startPage > 1) {
      items.push(
        <PaginationItem key="startEllipsis" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    // Pages numérotées
    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem active={i === props.currentPage} key={i}>
          <PaginationLink onClick={() => props.handlePageClick(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    // Points de suspension à la fin
    if (endPage < props.initialTotalPages) {
      items.push(
        <PaginationItem key="endEllipsis" disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };
  return (
    <div>
      <Pagination className="d-flex align-items-center justify-content-center">
        <PaginationItem disabled={props.currentPage <= 1}>
          <PaginationLink
            onClick={() => props.handlePageClick(props.currentPage - 1)}
            previous
          />
        </PaginationItem>
        {getPaginationItems()}
        <PaginationItem disabled={props.currentPage >= props.initialTotalPages}>
          <PaginationLink
            onClick={() => props.handlePageClick(props.currentPage + 1)}
            next
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
