import React, { useState } from "react";
import { HiPlusCircle } from "react-icons/hi2";
import { HiMinusCircle } from "react-icons/hi2";

const ShowMoreLessParagraph = ({ text = "", maxLength }) => {
  const [showMore, setShowMore] = useState(false);
  const displayText =
    showMore || text.length <= maxLength
      ? text
      : `${text.slice(0, maxLength)}...`;
  return (
    <div>
      <p className="showText20">
        {displayText}{" "}
        {text.length > maxLength && (
          <span
            className="showMoreLink"
            onClick={() => setShowMore(!showMore)}
            style={{ cursor: "pointer" }}
          >
            {showMore ? (
              <HiMinusCircle size={20} color="#dc3545" />
            ) : (
              <HiPlusCircle size={20} color="#002d56" />
            )}
          </span>
        )}
      </p>
    </div>
  );
};
export default ShowMoreLessParagraph;
