import { call, put, takeEvery, select } from "redux-saga/effects";
import { getAllDataPagesSuccess, getAllDataPagesFail } from "./actions";
import { CHANGE_LANGUAGE, GET_ALL_CONTENT_STATIC_PAGE } from "./actionTypes";
import { getBrowserLanguage } from "../../helpers/api_helper";
import { getAllContentsStaticPages } from "../../helpers/content_helper/api_content_helper";

// Worker Saga
function* fetchAllDataPagesSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));

    const response = yield call(
      getAllContentsStaticPages,
      newLanguage === "fr" ? "fra" : "eng"
    );
    // localStorage.setItem("data_meta", JSON.stringify(response));
    yield put(getAllDataPagesSuccess(response));
  } catch (error) {
    yield put(getAllDataPagesFail(error.toString()));
  }
}

// Watcher Saga
export default function* DataPagesSaga() {
  yield takeEvery(
    [GET_ALL_CONTENT_STATIC_PAGE, CHANGE_LANGUAGE],
    fetchAllDataPagesSaga
  );
}
