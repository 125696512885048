import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getAllDataNationList,
  getAllDataOfNation,
  getBrowserLanguage,
} from "../../helpers/api_helper";
import {
  getAllNationsFail,
  getAllNationsListFail,
  getAllNationsListSuccess,
  getAllNationsSuccess,
} from "./actions";
import { GET_ALL_NATIONS, GET_ALL_NATIONS_LIST } from "./actionTypes";
import { CHANGE_LANGUAGE } from "../components_pages/actionTypes";

// Worker Saga
function* fetchAllNationSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      id: "",
      idTypeCode: "0",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
      addFullCountryNameInd: "1",
      addGeoPointInd: "1",
      overwriteJsonFileInd: "0",
      backgroundColorOpacity: 0.7,
      statusCode: "1",
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
    };
    const response = yield call(getAllDataOfNation, data);
    localStorage.setItem("all_nations", JSON.stringify(response));
    yield put(getAllNationsSuccess(response));
  } catch (error) {
    yield put(getAllNationsFail(error.toString()));
  }
}

function* fetchAllNationListSaga(action) {
  try {
    const currentLanguage = yield select((state) => state.language);
    const newLanguage =
      localStorage.getItem("selectedLanguage") ||
      currentLanguage ||
      (yield call(getBrowserLanguage));
    let data = {
      apiKey: "1000100v10013e51784ad39461296af47a0641c1bd2",
      value: "nationList",
      appName: "amulab",
      type: "pagecode",
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    };
    const response = yield call(getAllDataNationList, data);
    yield put(getAllNationsListSuccess(response));
  } catch (error) {
    yield put(getAllNationsListFail(error.toString()));
  }
}
// Watcher Saga
export default function* NationsSaga() {
  yield takeEvery([GET_ALL_NATIONS, CHANGE_LANGUAGE], fetchAllNationSaga);
  yield takeEvery(
    [GET_ALL_NATIONS_LIST, CHANGE_LANGUAGE],
    fetchAllNationListSaga
  );
}
