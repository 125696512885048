import React from "react";

const BestTime = () => {
  return (
    <div>
      <h1>Temps favorable</h1>
    </div>
  );
};

export default BestTime;
