import {
  LOGIN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LOGIN_USER_SUCCESS,
  SET_LOADING,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAIL,
} from "./actionTypes";

export const loginUser = (user, navigate) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate },
  };
};

export const newLoginUser = (users, navigate) => {
  return {
    type: AUTHENTICATE_USER,
    payload: { users, navigate },
  };
};

export const newLoginUserSuccess = (users) => {
  return {
    type: AUTHENTICATE_USER_SUCCESS,
    payload: users,
  };
};
export const newLoginUserFail = (error) => {
  return {
    type: AUTHENTICATE_USER_FAIL,
    payload: error,
  };
};

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const loginSuccess = (user) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (payload) => {
  return {
    type: LOGOUT_USER,
    payload,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
