import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const YesPage = () => {
  return (
    <div>
      <Header />
      <h1>Oui mais</h1>
      {/* <div class="datatablewrapper">
        <table id="datatable" class="table-responsive">
          <thead>
            <tr>
              <th>Nations</th>
              <th>Intructions</th>
              <th style="width:15%">Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Kongo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 175 </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 194 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 40 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                    <tr>
                      <td> 78 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mbundu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 205 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 89 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lozi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        108(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waluchazi_108.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waluchazi_108.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 329 </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        340(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/balozi_340.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="balozi_340.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        342(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bankoya_and_wambuela_342.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bankoya_and_wambuela_342.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Subia</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 111 </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td> 330 </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        340(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/balozi_340.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="balozi_340.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 343 </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 351 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lunda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        203(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/balunda_203.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="balunda_203.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 248 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 341 </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        347(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wachokwe_347.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wachokwe_347.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        350(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waluchazi_350.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waluchazi_350.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 86 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mbuela</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 92 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        94(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_94.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_94.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nkoya</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        342(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bankoya_and_wambuela_342.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bankoya_and_wambuela_342.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Herero West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        332(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/herero_332.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="herero_332.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td> 96 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Herero East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        328(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_328.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_328.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        332(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/herero_332.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="herero_332.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        find existing line around red line on attached image
                        near white space.
                      </td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        333(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hottentots_333.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hottentots_333.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Pedi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 119 </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        356(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mashona_356.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mashona_356.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 358 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 442 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ovambo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 100 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        331(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_331.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_331.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td> 334 </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        94(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_94.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_94.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ovimbundu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 104 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Chokwe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        107(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wachokwe_107.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wachokwe_107.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        203(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/balunda_203.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="balunda_203.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        347(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wachokwe_347.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wachokwe_347.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luchazi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        107(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wachokwe_107.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wachokwe_107.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        108(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waluchazi_108.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waluchazi_108.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        340(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/balozi_340.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="balozi_340.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        350(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waluchazi_350.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waluchazi_350.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 82 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nyaneka-Humbe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 109 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        94(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_94.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_94.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ndonga-Yeyi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 110 </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td> 123 </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td> 335 </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Azande</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 188 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        192(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakomo_192.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakomo_192.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        231(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mba _231.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mba _231.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        244(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sere_mundu_244.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sere_mundu_244.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 293 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td> 297 </td>
                      <td>297-bakare</td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        468(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/azande_468.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="azande_468.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        469(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_469.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_469.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 470 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Boa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 190 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        231(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mba _231.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mba _231.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ngombe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        226(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bobangi_and_bangala_226.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bobangi_and_bangala_226.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        242(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ngombe_242.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ngombe_242.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bangala-Bobangi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        177(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_177.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_177.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 179 </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        226(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bobangi_and_bangala_226.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bobangi_and_bangala_226.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Komo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        192(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakomo_192.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakomo_192.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 301 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lega, Shabunda Rega</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        215(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/barega_215.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="barega_215.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Konjo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        196 <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakonjo_196.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakonjo_196.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Add the white space No1 from the image on this nation
                      </td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 302 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kuba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 198 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        208(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_208.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_208.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        192(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakomo_192.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakomo_192.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 201 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        215(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/barega_215.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="barega_215.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bemba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 225 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 344 </td>
                      <td>
                        Include the white space at the north-west of 344 in this
                        nation
                      </td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        348(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wafipa_348.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wafipa_348.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fipa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 270 </td>
                      <td>Include the white space inside 270 in this nation</td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        348(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wafipa_348.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wafipa_348.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mongo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        208(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_208.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_208.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 233 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sakata</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 220 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Teke</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        176(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakota_176.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakota_176.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 178 </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 223 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 43 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kota</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        176(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bakota_176.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bakota_176.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 41 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mpongwe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 181 </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        45(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mpongwe_45.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mpongwe_45.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kele</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 174 </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 39 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        45(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mpongwe_45.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mpongwe_45.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Beti-Fang</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 14 </td>
                      <td></td>
                      <td>Equatorial Guinea</td>
                    </tr>
                    <tr>
                      <td> 15 </td>
                      <td>15-Maka</td>
                      <td>Equatorial Guinea</td>
                    </tr>
                    <tr>
                      <td> 267 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 44 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fang Island</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 900 </td>
                      <td>
                        Get geo info by get Google Maps geo points of this
                        country: São Tomé and Príncipe
                      </td>
                      <td>São Tomé and Príncipe</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bubi Island</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 12 </td>
                      <td></td>
                      <td>Equatorial Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sawa, Duala</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 13 </td>
                      <td></td>
                      <td>Equatorial Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        265(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/duala_265.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="duala_265.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        See instructions on the image how to split use Google
                        Map to get line a, b and c.
                      </td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bassa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        265(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/duala_265.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="duala_265.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        See instructions on the image how to split use Google
                        Map to get line a, b and c.
                      </td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bamileke</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        252(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bamileke_252.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bamileke_252.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        The line between spaces 1 and 2, use Google Maps line
                        between Noun division and other 4 divisions of West
                        Region, Cameroon.
                      </td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        263(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_263.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_263.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bamun</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        252(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bamileke_252.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bamileke_252.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maka-Baka</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 1 </td>
                      <td></td>
                      <td>Central African Republic</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        177(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_177.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_177.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 180 </td>
                      <td>Maka</td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        254(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banda_254.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banda_254.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 256 </td>
                      <td>Baka</td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 276 </td>
                      <td>Maka</td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        300(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_300.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_300.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Central African Republic</td>
                    </tr>
                    <tr>
                      <td> 42 </td>
                      <td></td>
                      <td>Gabon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ngbandi-Ngiri</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        177(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_177.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_177.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 183 </td>
                      <td>183-Ngiri</td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 238 </td>
                      <td>238-Ngbandi</td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        240(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ngiri_240.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ngiri_240.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        242(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ngiri_242.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ngiri_242.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 3 </td>
                      <td>3-Ngiri</td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        300(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_300.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_300.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Gbaya</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 228 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tikar</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 286 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 420 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tiv</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 289 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 421 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Jukun</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 271 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        404(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_404.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_404.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        411(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/jukun_idoma_411.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="jukun_idoma_411.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Idoma</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        411(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/jukun_idoma_411.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="jukun_idoma_411.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani I</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 159 </td>
                      <td>159- Bura, Bata and Tera</td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 162 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        263(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_263.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_263.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        278(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandara_278.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandara_278.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        404(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_404.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_404.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        411(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/jukun_idoma_411.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="jukun_idoma_411.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        411(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/jukun_idoma_411.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="jukun_idoma_411.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mbum</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        160(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_160.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_160.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 2 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        250(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banda_250.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banda_250.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        254(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banda_254.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banda_254.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        263(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_263.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_263.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        282(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mbum_282.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mbum_282.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Chamba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        160(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_160.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_160.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        263(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_263.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_263.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        282(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_282.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_282.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        404(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/chamba_404.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="chamba_404.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandara</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        278(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandara_278.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandara_278.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 415 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bura, Batta and Tera</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 258 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        269(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_269.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_269.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 401 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(9) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Hausa, Bute</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 261 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        282(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mbum_282.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mbum_282.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 403 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mubi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        167(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mubi_167.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mubi_167.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Masa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 166 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 280 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kotoko</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        163(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kanuri_163.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kanuri_163.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 164 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 274 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kanuri</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        163(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kanuri_163.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kanuri_163.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        168(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/shoa_arabs_168.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="shoa_arabs_168.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 272 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        386(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_386.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_386.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td> 390 </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        393(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_393.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_393.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        413(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kanuri_413.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kanuri_413.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bade North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        397(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bade_397.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bade_397.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bade South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        397(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bade_397.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bade_397.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mumuye</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 416 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Shoa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        168(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/shoa_arabs_168.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="shoa_arabs_168.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 284 </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(8) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        413(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kanuri_413.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kanuri_413.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 418 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Hausa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 141 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        386(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_386.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_386.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        389(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_389.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_389.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Angas</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 396 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Berom-Jarawa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 400 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Katab</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 414 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nupe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 417 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kambari</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 412 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ibibio</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        408(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ibibio_408.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ibibio_408.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Igbo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        409(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ibo_409.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ibo_409.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Includes the 1 space (of 421-Tiv) inside this area
                      </td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ijaw</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        399(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bini_399.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bini_399.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        408(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ibibio_408.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ibibio_408.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        409(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ibo_409.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ibo_409.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        410(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ijo_410.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ijo_410.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Edo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        399(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bini_399.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bini_399.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Yoruba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        150(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tem_150.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tem_150.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 152 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 212 </td>
                      <td>212-Barba</td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        227(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tem_227.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tem_227.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 230 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        399(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bini_399.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bini_399.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        410(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ijo_410.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ijo_410.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 422 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ewe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 136 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 216 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 405 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 48 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        57(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/togo_tribes_57.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="togo_tribes_57.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fon-Adja</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        901 <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fon_adja.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fon_adja.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        create NEW nation are from 136 and 216: see instructions
                        on the image
                      </td>
                      <td>d</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Akposso-Akebou</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        227(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tem_227.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tem_227.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 229 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 56 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        57(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/togo_tribes_57.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="togo_tribes_57.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Akan East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        211(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/akan_211.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="akan_211.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        46(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/akan_46.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="akan_46.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Akan West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 75 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                    <tr>
                      <td> 85 </td>
                      <td>85-Lagoon tribes</td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        90(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mano_90.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mano_90.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bete</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 79 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Wè-Guéré</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 124 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        81(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/gere_81.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="gere_81.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mano</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 130 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 64 </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        81(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/gere_81.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="gere_81.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        90(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mano_90.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mano_90.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kru, Krumen and Grebo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 122 </td>
                      <td>122-Bantu-speaking Pygmy tribes</td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 77 </td>
                      <td>77-Bantu-speaking Pygmy tribes</td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Vai</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        129(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/loma_129.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="loma_129.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 133 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 462 </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mende</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 132 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        460(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mende_460.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mende_460.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kissi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 126 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 458 </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        459(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_459.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_459.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        460(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mende_460.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mende_460.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        59(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kisi_59.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kisi_59.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Susu South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 461 </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        59(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kisi_59.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kisi_59.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        62(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_62.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_62.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        66(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/susu_66.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="susu_66.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Susu North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 364 </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 454 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        58(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_58.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_58.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        66(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/susu_66.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="susu_66.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandinka, Bambara</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 374 </td>
                      <td></td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandinka</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        459(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_459.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_459.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        58(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_58.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_58.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        62(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_62.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_62.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        66(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/susu_66.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="susu_66.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td> 70 </td>
                      <td>70-Wai</td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandinka, Bambara East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 321 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandinka West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 37 </td>
                      <td>Includes the white space inside this area</td>
                      <td>Gambia</td>
                    </tr>
                    <tr>
                      <td> 427 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 450 </td>
                      <td>
                        Includes the 1 space (of 456-Wolof) inside this area
                      </td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        62(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_62.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_62.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mandinka, Dioula</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 88 </td>
                      <td>88-Mandingo</td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Diola</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 36 </td>
                      <td></td>
                      <td>Gambia</td>
                    </tr>
                    <tr>
                      <td> 425 </td>
                      <td>Includes the white space inside this area</td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 448 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Wolof</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 38 </td>
                      <td></td>
                      <td>Gambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        449(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_449.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_449.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td> 456 </td>
                      <td>Excludes the 1 space inside 450-Mandingo </td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Serer</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 452 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Balante</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 423 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 424 </td>
                      <td>424-biafada</td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 429 </td>
                      <td>429-Nalu</td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 447 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td> 65 </td>
                      <td>65-Nalu</td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Manjak</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 428 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 451 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Papel</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 430 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Soninke I</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        363(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_363.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_363.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        375(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_375.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_375.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mauritania</td>
                    </tr>
                    <tr>
                      <td> 453 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Soninke II</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        363(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_363.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_363.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        366(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_366.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_366.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        373(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_373.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_373.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mauritania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        375(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_375.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_375.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani II</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 426 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        449(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_449.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_449.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        58(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_58.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_58.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        59(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/kisi_59.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="kisi_59.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        62(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_62.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_62.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani III</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 80 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani IV</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        373(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_373.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_373.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani V</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        363(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_363.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_363.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani VI</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        449(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_449.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_449.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tenda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 431 </td>
                      <td></td>
                      <td>Guinea Bissau</td>
                    </tr>
                    <tr>
                      <td> 455 </td>
                      <td></td>
                      <td>Senegal</td>
                    </tr>
                    <tr>
                      <td> 68 </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bissa East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 131 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        137(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_137.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_137.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 402 </td>
                      <td>
                        Includes the 1 space of 417-nupe on the left of this
                        area
                      </td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        406(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_406.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_406.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bissa West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 214 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 314 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 47 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bariba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        127(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/barba_127.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="barba_127.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        137(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_137.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_137.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 398 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Somba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 143 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 224 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 55 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kabye-Tem</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        150(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tem_150.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tem_150.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        221(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/gurma_221.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="gurma_221.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        227(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tem_227.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tem_227.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Songhai East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        127(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/barba_127.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="barba_127.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td> 146 </td>
                      <td></td>
                      <td>Benin</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        327(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_327.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_327.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        386(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_386.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_386.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        389(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_389.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_389.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        392(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/songai_392.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="songai_392.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td> 395 </td>
                      <td>395-West Sahara Arabs</td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        407(8) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hausa_407.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hausa_407.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                    <tr>
                      <td> 419 </td>
                      <td></td>
                      <td>Nigeria</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Songhai West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        317(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_317.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_317.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        325(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/songai_325.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="songai_325.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 362 </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tuareg</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 171 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        325(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/songai_325.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="songai_325.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 327 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        365(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_365.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_365.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        392(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/songai_392.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="songai_392.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        393(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_393.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_393.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Gurunsi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        317(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_317.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_317.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        49(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/grusi_49.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="grusi_49.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kulango</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        49(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/grusi_49.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="grusi_49.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td> 51 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td> 53 </td>
                      <td>53- Mandingo</td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td> 83 </td>
                      <td>83-Grusi</td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                    <tr>
                      <td> 84 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        88(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_88.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_88.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Senufo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        153(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mandingo_153.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mandingo_153.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 154 </td>
                      <td>154-Sanu</td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        323(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sanu_323.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sanu_323.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 324 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        326(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_326.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_326.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 361 </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 91 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Samo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        323(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sanu_323.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sanu_323.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bobo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 313 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        326(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/soninke_326.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="soninke_326.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Dogon</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 315 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kpelle</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 128 </td>
                      <td>
                        Includes the 1 space (of 122-Bantu-speaking) inside this
                        area
                      </td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 60 </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Loma</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        129(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/loma_129.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="loma_129.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 61 </td>
                      <td></td>
                      <td>Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Gola</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 125 </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        129(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/loma_129.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="loma_129.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Liberia</td>
                    </tr>
                    <tr>
                      <td> 457 </td>
                      <td></td>
                      <td>Sierra Leone</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Toubou</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 172 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 387 </td>
                      <td>387-gola</td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td> 391 </td>
                      <td>391-Libya Arabs</td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        394(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tubu_394.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tubu_394.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lobi Center</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 319 </td>
                      <td>319-Kulango</td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 320 </td>
                      <td>
                        Includes the 1 space (of 322-Mossi) inside this area
                      </td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        52(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/lobi_52.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="lobi_52.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td> 87 </td>
                      <td></td>
                      <td>Cote d'Ivoire</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lobi North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        149(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_149.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_149.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td> 151 </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        365(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tuaregs_365.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tuaregs_365.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Mali</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Lobi South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        52(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/lobi_52.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="lobi_52.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fulani VII</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        316(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_316.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_316.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        386(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_386.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_386.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Gurma</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        211(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/akan_211.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="akan_211.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td> 218 </td>
                      <td>218-Fulbe</td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        221(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/gurma_221.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="gurma_221.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        316(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_316.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_316.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 318 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        386(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_386.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_386.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td> 388 </td>
                      <td></td>
                      <td>Niger</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        46(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/akan_46.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="akan_46.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                    <tr>
                      <td> 50 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mossi North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 222 </td>
                      <td></td>
                      <td>Togo</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        316(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/fulbe_316.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="fulbe_316.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                    <tr>
                      <td> 322 </td>
                      <td></td>
                      <td>Burkina Faso</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mossi South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 54 </td>
                      <td></td>
                      <td>Ghana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nyamwezi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 239(1) </td>
                      <td>239-baha</td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        279(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wanyamwezi_279.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wanyamwezi_279.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Banyarwanda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 155 </td>
                      <td></td>
                      <td>Burundi</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        208(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bantu_speaking_pygmy_tribes_208.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bantu_speaking_pygmy_tribes_208.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 210 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 304 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td> 433 </td>
                      <td>433- Bantu-speaking Pygmy tribes</td>
                      <td>Rwanda</td>
                    </tr>
                    <tr>
                      <td> 434 </td>
                      <td></td>
                      <td>Rwanda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Rundi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 156 </td>
                      <td></td>
                      <td>Burundi</td>
                    </tr>
                    <tr>
                      <td> 219 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 245 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nyoro</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 213 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        243(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banyoro_243.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banyoro_243.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        305(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banyoro_305.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banyoro_305.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ganda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        298(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/baganda_298.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="baganda_298.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Soga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        307(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/basoga_307.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="basoga_307.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>San</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114 <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 112(bechuanas) and add here</td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        331(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_331.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_331.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td> 354 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 439 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        94(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_94.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_94.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Angola</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kalanga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(10) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 357(Matebele), Zimbabwe(357)</td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(11) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Get from 356(4)(Mashona), IMAGE: mashona_356.png,
                        Zimbabwe(356)
                      </td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 112(Bechuanas), Botswana(112)</td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 116(Mashona), Botswana(116)</td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(8) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 353(Bechuanas), Zimbabwe(353)</td>
                      <td>Botswana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Shona</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        356(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mashona_356.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mashona_356.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 381 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tswana</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        112(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bechuanas_112.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bechuanas_112.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        On this image, 112(2) go to 114 (SAN Nation); 112(3) go
                        to KALANGA Nation
                      </td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        438(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bechuanas_438.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bechuanas_438.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nama</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        328(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_328.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_328.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        331(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_331.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_331.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        333(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hottentots_333.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hottentots_333.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Namibia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td> 440 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Khoikhoi of West Coast, Namakwa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(1)(G1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435_google_map_instructions.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435_google_map_instructions.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        SECOND IMAGE: afrikaners_435.png; NORTERN CAPE: Namakwa
                        District Municipality; WESTERN CAPE: West Coast District
                        Municipality (MINUS Swartland Local Municipality)
                      </td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Khoikhoi North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(1)(G2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435_google_map_instructions.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435_google_map_instructions.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        SECOND IMAGE: afrikaners_435.png; NORTERN CAPE: ZF
                        Mgcawu AND John Taolo Gaetsewe District Municipalities;
                        REMOVE South Africa(439) &amp; South Africa(440) HERE
                        because we added that in NAMA nation.
                      </td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Khoikhoi Central</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(1)(G3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435_google_map_instructions.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435_google_map_instructions.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        SECOND IMAGE: afrikaners_435.png; NORTERN CAPE: Pixley
                        ka Seme AND Frances Baard District Municipalities
                      </td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Khoikhoi Souhtwest</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(1)(G4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435_google_map_instructions.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435_google_map_instructions.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        SECOND IMAGE: afrikaners_435.png; WESTERN CAPE: - Cape
                        Winelands AND Overberg District Municipalities; - City
                        of Cape Town Metropolitan Municipality; - West Coast
                        District Municipality (ONLY Swartland Local
                        Municipality)
                      </td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Khoikhoi Southeast</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        435(1)(G5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/afrikaners_435_google_map_instructions.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="afrikaners_435_google_map_instructions.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        SECOND IMAGE: afrikaners_435.png; WESTERN CAPE: Central
                        Karoo AND Garden Route District Municipalities
                      </td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Xhosa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        436(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/basuto_436.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="basuto_436.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td> 445 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Basotho</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 134 </td>
                      <td></td>
                      <td>Lesotho</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        436(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/basuto_436.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="basuto_436.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Zulu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 339 </td>
                      <td></td>
                      <td>Swaziland</td>
                    </tr>
                    <tr>
                      <td> 385 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                    <tr>
                      <td> 446 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ndebele</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        438(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bechuanas_438.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bechuanas_438.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        441(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ndebele_441.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ndebele_441.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Matabele</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 357 </td>
                      <td>
                        Excludes the space from this area that is cut and moved
                        to KALANGA Nation
                      </td>
                      <td>Zimbabwe</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Swazi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 337 </td>
                      <td></td>
                      <td>Swaziland</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        441(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ndebele_441.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ndebele_441.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                    <tr>
                      <td> 443 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tonga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        114(9) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bushmen_114.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bushmen_114.png"
                          ></i>
                        </a>
                      </td>
                      <td>Get from 116(Mashona), Botswana(116)</td>
                      <td>Botswana</td>
                    </tr>
                    <tr>
                      <td> 346 </td>
                      <td>Add here white space between 346 and 359</td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        356(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mashona_356.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mashona_356.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 359 </td>
                      <td>Add here white space between 346 and 359</td>
                      <td>Zimbabwe</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tsonga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 338 </td>
                      <td></td>
                      <td>Swaziland</td>
                    </tr>
                    <tr>
                      <td> 360 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 383 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                    <tr>
                      <td> 444 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Venda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 352 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 437 </td>
                      <td></td>
                      <td>South Africa</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maravi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 144 </td>
                      <td></td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td> 145 </td>
                      <td>145-Wafipa</td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        148(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wayao_148.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wayao_148.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        259(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/malavi_259.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="malavi_259.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        259(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/malavi_259.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="malavi_259.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        This is white space of the image to add in this nation
                      </td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 345 </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                    <tr>
                      <td> 355 </td>
                      <td></td>
                      <td>Zimbabwe</td>
                    </tr>
                    <tr>
                      <td> 380 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kinga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 147 </td>
                      <td></td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        259(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/malavi_259.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="malavi_259.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        277(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wakinga_277.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wakinga_277.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 349 </td>
                      <td></td>
                      <td>Zambia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Yao</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        148(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wayao_148.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wayao_148.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td> 292 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 384 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Hehe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 275 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        277(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/wakinga_277.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="wakinga_277.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Makua</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 142 </td>
                      <td></td>
                      <td>Malawi</td>
                    </tr>
                    <tr>
                      <td> 379 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                    <tr>
                      <td> 382 </td>
                      <td>382-Swahili</td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Makua North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 257 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Ngoni</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 236 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 377 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Makonde</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 255 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 378 </td>
                      <td></td>
                      <td>Mozambique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Swahili South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 264 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Swahili North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 113 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        117(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waboni_117.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waboni_117.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 464 </td>
                      <td></td>
                      <td>Somalia</td>
                    </tr>
                    <tr>
                      <td> 465 </td>
                      <td></td>
                      <td>Somalia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Zaramo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 294 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sagara</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 287 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Shambaa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 288 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Gogo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 273 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nyaturu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 262 </td>
                      <td>262- Sandawe</td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 266 </td>
                      <td>266- Tatog Datooga, Datog</td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 281 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Rangi-Iraqw</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 249 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 251 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maasai South</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        101(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/masai_101.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="masai_101.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        241(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/baluhya_241.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="baluhya_241.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 247 </td>
                      <td>247- Hadzapi (Hadzabi)</td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 260 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maasai North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        101(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/masai_101.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="masai_101.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kikuyu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 95 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luhya</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        241(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/baluhya_241.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="baluhya_241.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 303 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        97(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/baluhya_97.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="baluhya_97.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luo, Joluo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 253 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 308 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        97(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/baluhya_241.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="baluhya_241.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 99 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        99(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/joluo_99.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="joluo_99.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nandi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 102 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 311 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Chagga</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 118 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 268 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Pare</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 285 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nyika-Taita</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 120 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 283 </td>
                      <td></td>
                      <td>Tanzania</td>
                    </tr>
                    <tr>
                      <td> 290 </td>
                      <td>290-Wateita</td>
                      <td>Tanzania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kamba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 93 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Oromo</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        105(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/somalis_105.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="somalis_105.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        117(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/waboni_117.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="waboni_117.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 121 </td>
                      <td>121-Wapokomo</td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        24(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/galla_24.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="galla_24.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        25(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/koma_25.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="koma_25.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        30(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sidamo_30.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sidamo_30.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 98 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Somali</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        105(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/somalis_105.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="somalis_105.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 31 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 463 </td>
                      <td></td>
                      <td>Somalia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Afar</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 19 </td>
                      <td>19- Arabs of Yemen</td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 23 </td>
                      <td></td>
                      <td>Ethiopia </td>
                    </tr>
                    <tr>
                      <td> 8 </td>
                      <td></td>
                      <td>Djibouti</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Amhara</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        16(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/agau_16.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="agau_16.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        17(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/amhara_17.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="amhara_17.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        24(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/galla_24.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="galla_24.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Koma</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        25(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/koma_25.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="koma_25.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        30(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sidamo_30.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sidamo_30.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tigre</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        16(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/agau_16.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="agau_16.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 35 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tigrayan</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 29 </td>
                      <td>29-Saho</td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 34 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sidama</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 103 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        30(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sidamo_30.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sidamo_30.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Beja</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 10 </td>
                      <td></td>
                      <td>Egypt</td>
                    </tr>
                    <tr>
                      <td> 21 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 473 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kunama-Nara</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 20 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 26 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luo, Anuak</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 18 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        27(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/murle_27.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="murle_27.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Dinka</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 476 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sudan Arab</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        161(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/dago_161.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="dago_161.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        169(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sudan_arabs_169.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sudan_arabs_169.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 182 </td>
                      <td>182- Libya Arabs</td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        197(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sere_mundu_197.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sere_mundu_197.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 200 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        202(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tama_202.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tama_202.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        209(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/zagawa_209.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="zagawa_209.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 32 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 467 </td>
                      <td>467-Arabs of Yemen</td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        469(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_469.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_469.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 475 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 477 </td>
                      <td>477-Kadugli-Krongo</td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 5 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nubians</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 193 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 204 </td>
                      <td>204-Temaini</td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Koalib</td>
              <td></td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Teso</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 115 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                    <tr>
                      <td> 206 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 312 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td> 33 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Pokot</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 106 </td>
                      <td></td>
                      <td>Kenya</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Karamojong</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 309 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td> 478 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Murle</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 189 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        27(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/murle_27.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="murle_27.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Nuer</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 195 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 28 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Berta</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 22 </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        25(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/koma_25.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="koma_25.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Ethiopia</td>
                    </tr>
                    <tr>
                      <td> 474 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 480 </td>
                      <td>480-koma</td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Koalib-Tagoi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 479 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Luo, Acholi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 199 </td>
                      <td>199-Southern Lwo</td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 246 </td>
                      <td>246-Southern Lwo</td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 296 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bagirmi</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        157(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_157.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_157.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        169(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sudan_arabs_169.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sudan_arabs_169.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        250(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_250.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_250.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 295 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tama</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        157(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_157.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_157.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        161(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/dago_161.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="dago_161.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 170 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        202(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tama_202.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tama_202.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        469(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_469.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_469.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td> 6 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Zaghawa</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        168(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/shoa_arabs_168.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="shoa_arabs_168.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        169(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sudan_arabs_169.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sudan_arabs_169.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 173 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        202(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/tama_202.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="tama_202.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        209(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/zagawa_209.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="zagawa_209.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maba-Masalit</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        161(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/dago_161.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="dago_161.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 165 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        167(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mubi_167.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mubi_167.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td> 185 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Banda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 158 </td>
                      <td></td>
                      <td>Chad</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        186 <span>- </span>
                        <a
                          href="http://api.amulab.org/img/186-sere-mundu"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="186-sere-mundu"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo</td>
                    </tr>
                    <tr>
                      <td> 207 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        240(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/ngiri_240.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="ngiri_240.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        244(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sere_mundu_244.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sere_mundu_244.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        254(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/banda_254.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="banda_254.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        282(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mbum_282.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mbum_282.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Cameroon</td>
                    </tr>
                    <tr>
                      <td> 299 </td>
                      <td></td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td> 4 </td>
                      <td>4-sere-mundu</td>
                      <td>Central Africa Republic</td>
                    </tr>
                    <tr>
                      <td> 471 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Moru-Mangbetu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 187 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        197(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sere_mundu_197.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sere_mundu_197.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        215(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/barega_215.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="barega_215.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        231(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/mba _231.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="mba _231.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 234 </td>
                      <td>
                        Includes the 1 space (of 231-Mba) inside this area in
                        this nation
                      </td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 237 </td>
                      <td>
                        237- Moru-Mangbetu and Sere-Mundu-speaking Pygmy tribes
                      </td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        244(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/sere_mundu_244.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="sere_mundu_244.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 310 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        468(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/azande_468.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="azande_468.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        469(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/bagirmi_469.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="bagirmi_469.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bari</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 217 </td>
                      <td></td>
                      <td>Congo, DRC</td>
                    </tr>
                    <tr>
                      <td> 306 </td>
                      <td></td>
                      <td>Uganda</td>
                    </tr>
                    <tr>
                      <td> 472 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Otuho</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 184 </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        191(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/northern_lwo_191.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="northern_lwo_191.png"
                          ></i>
                        </a>
                      </td>
                      <td></td>
                      <td>Sudan</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Cabo Verde</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 7 </td>
                      <td></td>
                      <td>Cabo Verde</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tombouctou</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        366(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_366.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_366.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Use the Google Map: Timbuktu Cercle in Mali (country) to
                        separate 266(1) and 366(3) border line.
                        https://en.wikipedia.org/wiki/Timbuktu_Cercle
                      </td>
                      <td>Mali</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Madagascar</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 140 </td>
                      <td></td>
                      <td>Madagascar</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Mauritius</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 372 </td>
                      <td></td>
                      <td>Mauritius</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Reunion</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 432 </td>
                      <td></td>
                      <td>Reunion</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Saint-Helena Island</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 910 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Saint Helena</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Fiji</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 911 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Fiji</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Kanak</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 912 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>New Caledonia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Tahiti</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 913 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Tahiti</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Solomon Islands</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 914 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Solomon Islands</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Vanuatu</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 915 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Vanuatu</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Timor-Leste</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 916 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Timor-Leste</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Papuans</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 917 </td>
                      <td>
                        Use Google Earth geo info of country: New Guinea= Papua
                        New Guinea + Western New Guinea
                      </td>
                      <td>New Guinea</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Comoros</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 291 </td>
                      <td>291-Swahili change name to Comoros</td>
                      <td>Comoros</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Comoros-Maore</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 918 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Mayotte</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Seychelles</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 919 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Seychelles</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Saint Martin</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 920 </td>
                      <td>
                        Use Google Earth geo info of country: Saint-Martin =
                        Saint Martin(France) + Sint Maarten (Netherland)
                      </td>
                      <td>Saint Martin</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bermuda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 921 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Bermuda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Guadeloupe</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 922 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Guadeloupe</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Martinique</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 923 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Martinique</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Dominica</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 924 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Dominica</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Antigua and Barbuda</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 925 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Antigua and Barbuda</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Saint Vincent and the Grenadines</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 926 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Saint Vincent and the Grenadines</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Saint Lucia</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 927 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Saint Lucia</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Saint Kitts and Nevis</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 928 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Saint Kitts and Nevis</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Grenada</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 929 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Grenada</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Trinidad and Tobago</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 930 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Trinidad and Tobago</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Montserrat</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 931 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Montserrat</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Anguilla</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 932 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Anguilla</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Virgin Islands</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 933 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>British Virgin Islands</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Cayman Islands</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 934 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Cayman Islands</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Guyana Center</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 935 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Suriname</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Guyana West</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 936 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Guyana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Guyana East</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 937 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>French Guiana</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Bahamas</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 938 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Bahamas</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Barbados</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 939 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Barbados</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Belize</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 940 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Belize</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Turks and Caicos Islands</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 941 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Turks and Caicos Islands</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Jamaica</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 942 </td>
                      <td>Use Google Earth geo info of country</td>
                      <td>Jamaica</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Caribbean ABC Islands</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 943 </td>
                      <td>
                        Use Google Earth geo info of country: ABC Islands
                        (leeward antilles)
                      </td>
                      <td>ABC Islands (leeward antilles)</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sint Eustatius-Saba</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td> 944 </td>
                      <td>
                        Use Google Earth geo info of country: Sint Eustatius +
                        Saba
                      </td>
                      <td>Sint Eustatius + Saba</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Marién</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        999(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hispaniola_999.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hispaniola_999.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        to get geographic scope of each of 5 regions of 999, go
                        to https://en.wikipedia.org/wiki/Chiefdoms_of_Hispaniola
                      </td>
                      <td>Haiti + Dominican Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maguá</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        999(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hispaniola_999.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hispaniola_999.png"
                          ></i>
                        </a>
                      </td>
                      <td>Same instructions like 999(2)</td>
                      <td>Dominican Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Maguana</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        999(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hispaniola_999.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hispaniola_999.png"
                          ></i>
                        </a>
                      </td>
                      <td>Same instructions like 999(1)</td>
                      <td>Dominican Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Jaragua</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        999(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hispaniola_999.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hispaniola_999.png"
                          ></i>
                        </a>
                      </td>
                      <td>Same instructions like 999(1)</td>
                      <td>Haiti + Dominican Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Higüey</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        999(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/hispaniola_999.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="hispaniola_999.png"
                          ></i>
                        </a>
                      </td>
                      <td>Same instructions like 999(1)</td>
                      <td>Dominican Republic</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sahara Southeast</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        366(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_366.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_366.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Mali Cercle (sub region):
                        https://en.wikipedia.org/wiki/Goundam_Cercle{" "}
                      </td>
                      <td>Mali</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        376(1) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        region 7 of
                        https://en.wikipedia.org/wiki/Regions_of_Mauritania
                      </td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sahara Southwest</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        376(2) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Regions 2, 3, 5, 6, 13, 10 and 11 of
                        https://en.wikipedia.org/wiki/Regions_of_Mauritania
                      </td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sahara Central</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        376(3) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        region 12 (only F'Derik and Zouérat departments); region
                        1 (only Chinguetti and Ouadane departments) of
                        https://en.wikipedia.org/wiki/Regions_of_Mauritania
                      </td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sahara West </td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        336(5) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>1 south region of Western Sahara</td>
                      <td>Western Sahara</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        376(4) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Region 1 (only Atar and Aoujeft departments); regions 4,
                        9 https://en.wikipedia.org/wiki/Regions_of_Mauritania
                      </td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
            <tr>
              <td>Sahara North</td>
              <td>
                <table style="width:100%;" class="table-responsive">
                  <tbody>
                    <tr>
                      <td>id</td>
                      <td>Instructions</td>
                      <td>country</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        336(6) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>2 other regions of Western Sahara</td>
                      <td>Western Sahara</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        376(7) <span>- </span>
                        <a
                          href="http://api.amulab.org/img/west_sahara_arabs_376_and_336.png"
                          target="_blank"
                        >
                          <i
                            class="glyphicon glyphicon-eye-open"
                            title="west_sahara_arabs_376_and_336.png"
                          ></i>
                        </a>
                      </td>
                      <td>
                        Region 12 (only Bir Moghrein department) of
                        https://en.wikipedia.org/wiki/Regions_of_Mauritania
                      </td>
                      <td>Mauritania</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>Nation Geopoint</td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <Footer />
    </div>
  );
};

export default YesPage;
