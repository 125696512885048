import React, { useEffect, useState, useMemo, useCallback } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import "../Account/style_profil.css";
import { connect, useDispatch } from "react-redux";
import {
  getAllSubscribersProfileCommunity,
  getUserProfileType,
  getUserProfile_a,
} from "../../store/actions";
import Cookies from "js-cookie";
import ReviewProfileYes from "../../components/reviews/reviewProfile/ReviewProfileYes";
import ReviewProfileNo from "../../components/reviews/reviewProfile/reviewProfileNo";

import { Button, Alert } from "reactstrap";
import YoutubeEmbed from "../../components/Youtube";
import ClaimedProfile from "../Account/updateProfileModal/ClaimedProfile";
import { getBrowserLanguage } from "../../helpers/api_helper";
import ReviewProfileSubsNo from "../../components/reviews/reviewSbscriber/reviewProfileSubsNo";
import ReviewProfileSubsYes from "../../components/reviews/reviewSbscriber/reviewProfileSubsYes";
import UsernameModal from "../Account/updateProfileModal/UsernameModal";
import NameModal from "../Account/updateProfileModal/NameModal";
import YoutubeModal from "../Account/updateProfileModal/YoutubeModal";
import DescriptionModal from "../Account/updateProfileModal/DescriptionModal";
import TypesModal from "../Account/updateProfileModal/TypesModal";
import SubscribeModal from "../Account/updateProfileModal/SubscribeModal";
import ShowMoreLessParagraph from "../../components/ShowMoreLessText";
import { BASE_API_URL_AVATAR } from "../../helpers/url_helper";
import ProfileAvatar from "../Account/updateProfileModal/ProfileAvatar";
import { useMediaQuery } from "react-responsive";
import PaginationComponent from "../../components/PaginationComponent";
import Head from "../../components/Head";

const SubscriberProfile = ({
  user,
  data_page_components,
  subscribersProfileList,
  loading,
  getUser,
  user_type,
}) => {
  const [modalUsername, setModalUsername] = useState(false);
  const [modal_name, setModal_name] = useState(false);
  const [modalYoutube, setModalYoutube] = useState(false);
  const [modalDescription, setModalDescription] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalSubscribe, setModalSubscribe] = useState(false);
  const [starNumber, setStarNumber] = useState(0);
  const [username_, setUsername_] = useState("");
  const [full_name, setFull_name] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [reclamation, setReclamation] = useState(false);
  const [embedId, setEmbedId] = useState("");
  const [visible, setVisible] = useState(false);
  const [youtube, setYoutube] = useState("");
  const [avatarKey, setAvatarKey] = useState(
    user?.profile?.medias.find((media) => media.type === "avatar")?.key
  );
  const [avatarUrl, setAvatarUrl] = useState(
    `${BASE_API_URL_AVATAR + "/" + avatarKey}`
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialTotalPages, setInitialTotalPages] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleUsername = () => setModalUsername(!modalUsername);
  const toggleName = () => setModal_name(!modal_name);
  const toggleDescription = () => setModalDescription(!modalDescription);
  const toggleYoutube = () => setModalYoutube(!modalYoutube);
  const toggleType = () => setModalType(!modalType);
  const toggleSubscribe = () => setModalSubscribe(!modalSubscribe);
  const toggleReclamation = () => setReclamation(!reclamation);
  const onDismiss = () => {
    setVisible(false);
  };

  const data_page_components_obj = data_page_components.reduce((obj, item) => {
    obj[item.key] = item.value;
    return obj;
  }, {});
  const token = localStorage.getItem("authToken");
  const apiKey = process.env.REACT_APP_API_KEY;

  const isMobile = useMediaQuery({ query: "(max-width: 440px)" });

  const { username } = useParams();
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const viewerId = Cookies.get("_SSDID_");
  const checkUser = Cookies.get("checkProfile");
  const newLanguage = selectedLanguage || getBrowserLanguage();

  const extractYouTubeID = (url) => {
    if (!url) {
      return "OcYoEE25IuE";
    }
    const shortsRegex = /\/shorts\/(\w{11})/;
    const watchRegex = /\/watch\?v=(\w{11})/;
    const shortsMatch = url?.match(shortsRegex);
    const watchMatch = url?.match(watchRegex);

    if (shortsMatch) {
      return shortsMatch[1];
    } else if (watchMatch) {
      return watchMatch[1];
    } else {
      return "OcYoEE25IuE";
    }
  };

  const requestData = useMemo(
    () => ({
      apiKey: apiKey,
      id: `${username}`,
      cultureInfo: `${(selectedLanguage || getBrowserLanguage()).substring(
        0,
        2
      )}`,
      idTypeCode: `username`,
      viewerId: `${viewerId ? viewerId : ""}`,
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
    }),
    [newLanguage, username, viewerId, selectedLanguage, apiKey]
  );
  const data = useMemo(
    () => ({
      apiKey: apiKey,
      id: `${username}`,
      idTypeCode: `username`,
      languageCode: `${newLanguage === "fr" ? "fra" : "eng"}`,
      rowsPerPage: 5,
      pageNumber: currentPage,
      sortBy: "",
      sort: "desc",
      q: "",
    }),
    [currentPage, newLanguage, username, apiKey]
  );

  useEffect(() => {
    dispatch(getUserProfile_a(requestData));
    setFull_name(user?.profile?.name);
    setUsername_(user?.profile?.username);
    setDescription(user?.profile?.desc);
    setType(user?.profile?.typeName);
    setStarNumber(user?.profile?.starNumber);
  }, [
    dispatch,
    requestData,
    user?.profile?.name,
    user?.profile?.desc,
    user?.profile?.typeName,
    user?.profile?.username,
    user?.profile?.starNumber,
  ]);

  const handleUpdateYoutube = (newYoutube) => {
    setYoutube(newYoutube);
    const newEmbedId = extractYouTubeID(newYoutube);
    if (newEmbedId) {
      setEmbedId(newEmbedId);
    } else setEmbedId("OcYoEE25IuE");
  };

  useEffect(() => {
    const avatarMedia = user?.profile?.medias?.find(
      (media) => media.type === "avatar"
    );

    setAvatarKey(avatarMedia?.key || null);
    setAvatarUrl(`${BASE_API_URL_AVATAR + "/" + avatarMedia?.key}`);

    const youtubeMedia = user?.profile?.medias?.find(
      (media) => media.type === "youtube"
    );
    if (youtubeMedia) {
      setYoutube(youtubeMedia.url);
      const newEmbedId = extractYouTubeID(youtubeMedia.url);
      setEmbedId(newEmbedId);
    } else {
      setEmbedId("OcYoEE25IuE");
      setYoutube(null);
    }
  }, [user?.profile?.medias]);

  const handleUpdateName = (newName) => {
    setFull_name(newName);
  };

  const handleUpdateUsername = (newUsername) => {
    navigate(`/${newUsername}`);
  };

  const handleUpdateDescription = (newDescription) => {
    setDescription(newDescription);
  };

  const handleUpdateType = (newType) => {
    setType(newType);
  };

  const handleAvatarChange = (newAvatarUrl) => {
    setAvatarUrl(newAvatarUrl || avatarUrl);
  };

  useEffect(() => {
    dispatch(getAllSubscribersProfileCommunity(data));
    setTotalPages(subscribersProfileList?.totalPages);
    if (currentPage === 1) {
      setInitialTotalPages(totalPages);
    }
  }, [
    dispatch,
    currentPage,
    data,
    subscribersProfileList?.totalPages,
    totalPages,
  ]);

  // useEffect(() => {
  //   setStarNumber(user?.profile?.starNumber);
  //   if (user?.profile?.medias.find((media) => media.type === "avatar")?.key) {
  //     setAvatarKey(
  //       user?.profile?.medias.find((media) => media.type === "avatar")?.key
  //     );
  //   }
  // }, [user]);

  const handleDisplayClaimPopup = () => {
    setReclamation(true);
  };

  const handleDisplaySubscribeButton = () => {
    if (token) {
      setModalSubscribe(true);
    } else {
      setVisible(true);
    }
  };

  const handleOpenTypeModal = () => {
    setModalType(true);
    dispatch(getUserProfileType());
  };

  const handleProfileClick = (profileId) => {
    window.scrollTo(0, 0);
  };

  const handlePageClick = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "24px",
          color: "#333",
        }}
      >
        <img
          src="assets/amulab2/images/loading.gif"
          alt="Check"
          className="mb-2"
          style={{ width: "140px" }}
        />
      </div>
    );
  }

  if (getUser) {
    return <Navigate to="/notfound" />;
  }

  return (
    <div id="wrapper">
      <Header />
      <Head pageTitle={username} />
      <div className="main-wrapper">
        <section className="modboxwrapper">
          <div className="container">
            <div className="row back_image">
              <div className="col-md-12">
                <div className="modbox">
                  <section className="channelInfo">
                    <div className="container pl-8 pr-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile d-flex align-items-center justify-content-between">
                            <div className="profileInfo d-flex align-items-center justify-content-between profile_header">
                              <div
                                className="profileImage textImage"
                                style={{
                                  background: isMobile
                                    ? avatarKey !== null
                                      ? `url(${avatarUrl}) center center/cover no-repeat`
                                      : user?.profile?.colorCode
                                    : avatarKey !== null
                                    ? `url(${avatarUrl}) center center/cover `
                                    : user?.profile?.colorCode,
                                }}
                              >
                                <p className="">
                                  {avatarKey !== null
                                    ? ""
                                    : user?.profile?.nameInitials}
                                </p>
                                {user?.isOwnerInd === "1" ? (
                                  <ProfileAvatar
                                    user={user}
                                    onAvatarChange={handleAvatarChange}
                                    defaultAvatar={avatarUrl}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="aboutInfo">
                                <div className="d-block align-items-center">
                                  {/* EDIT CODE */}
                                  <h2
                                    style={{
                                      fontSize:
                                        isMobile && full_name?.length > 26
                                          ? "15px"
                                          : "",
                                    }}
                                  >
                                    {full_name}

                                    {user?.profile?.certifyInd === "1" ? (
                                      <img
                                        src="assets/amulab1/img/check-star.svg"
                                        alt="Check"
                                        className="mb-2"
                                        style={{ width: "24px" }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {user?.isOwnerInd === "1" ? (
                                      <Link to="#">
                                        <img
                                          src="assets/amulab1/img/edit.svg"
                                          alt="editIcon"
                                          className="mb-1"
                                          onClick={() => setModal_name(true)}
                                        />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </h2>
                                  <div>
                                    {" "}
                                    <h4
                                      style={{
                                        color: "#22223b",
                                        fontSize: "18px",
                                      }}
                                    >
                                      @{username_}
                                      {user?.isOwnerInd === "1" ? (
                                        <Link to="#">
                                          <img
                                            src="assets/amulab1/img/edit.svg"
                                            alt="editIcon"
                                            onClick={() =>
                                              setModalUsername(true)
                                            }
                                          />
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </h4>
                                  </div>

                                  <UsernameModal
                                    modalUsername={modalUsername}
                                    toggleUsername={toggleUsername}
                                    username_={username_}
                                    onUpdateUsername={handleUpdateUsername}
                                  />
                                  <NameModal
                                    modal_name={modal_name}
                                    toggleName={toggleName}
                                    full_name={full_name}
                                    onUpdateName={handleUpdateName}
                                  />
                                </div>

                                <div className="orgrating ratingReview">
                                  {user?.profile?.yesCommnunityInd === "1" ? (
                                    <ReviewProfileYes starNumber={starNumber} />
                                  ) : (
                                    <ReviewProfileNo starNumber={starNumber} />
                                  )}

                                  <p>
                                    <span></span> Score{" "}
                                    {user?.profile?.scoreNumber} <span></span>
                                    {user?.profile?.scoreLevelName}
                                  </p>
                                </div>
                                <div className="likeFollow ">
                                  <p
                                    className="mb-0"
                                    style={{ display: "flex" }}
                                  >
                                    {data_page_components_obj.profileCommunity}
                                    <span></span>
                                    {user?.profile?.yesCommnunityInd === "1"
                                      ? data_page_components_obj.profileYes
                                      : data_page_components_obj.profileNo}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="langTranslate0">
                              {!token || !checkUser ? (
                                <Button
                                  className="btn btn-primary mr-3"
                                  onClick={() => handleDisplaySubscribeButton()}
                                >
                                  {data_page_components_obj.btnSubscribe}
                                </Button>
                              ) : null}
                            </div>
                          </div>
                          <SubscribeModal
                            modalSubscribe={modalSubscribe}
                            toggleSubscribe={toggleSubscribe}
                          />
                          <Alert
                            color="danger"
                            isOpen={visible}
                            toggle={onDismiss}
                            style={{ textAlign: "center" }}
                          >
                            {
                              data_page_components_obj.profileSubscribeLoginRequired
                            }
                          </Alert>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            <div className="modbox mt-4">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="">
                    <h3 className="" style={{ marginBottom: "20px" }}>
                      {data_page_components_obj.profileTitleVideo}
                    </h3>
                    {user?.isOwnerInd === "1" ? (
                      <div>
                        <p>
                          {data_page_components_obj.profileYoutubeUrlInfo}
                          <Link to="#">
                            <img
                              src="assets/amulab1/img/edit.svg"
                              alt="editIcon"
                              onClick={() => setModalYoutube(true)}
                              style={{ marginLeft: "2px" }}
                            />
                          </Link>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <YoutubeEmbed embedId={embedId} />
                  </div>
                  <YoutubeModal
                    modalYoutube={modalYoutube}
                    toggleYoutube={toggleYoutube}
                    youtube={youtube}
                    onUpdateYoutube={handleUpdateYoutube}
                  />
                  <h3 className="title_subscriber_list">
                    {data_page_components_obj.profileTitleSubscribers}
                  </h3>
                  {subscribersProfileList?.data?.map((item, index) => (
                    <div className="sidebarBox" key={item.profileId}>
                      <div className="sideProfile">
                        <div
                          className="sidebarImg sidebartextImage"
                          style={{
                            background:
                              item?.medias && item?.medias.length > 0
                                ? `url(${
                                    BASE_API_URL_AVATAR +
                                    "/" +
                                    item?.medias.find(
                                      (media) => media.type === "avatar"
                                    )?.key
                                  }) no-repeat center center/cover`
                                : item?.colorCode,
                          }}
                        >
                          <p className="">
                            {item?.medias.find(
                              (media) => media.type === "avatar"
                            )?.key
                              ? ""
                              : item?.nameInitials}
                          </p>
                        </div>
                      </div>
                      <div className="sidebarboxContent">
                        <Link
                          to={`/${item.username}`}
                          onClick={() => handleProfileClick(item.profileId)}
                          key={item.profileId}
                        >
                          <h3>
                            {item.name}
                            {item.certifyInd === 1 ? (
                              <img
                                src="assets/amulab1/img/check-star.svg"
                                alt="star"
                              />
                            ) : (
                              ""
                            )}
                          </h3>
                        </Link>
                        <div className="ratingReview">
                          {item?.yesCommnunityInd === "1" ? (
                            <ReviewProfileSubsYes
                              starNumberSubs={item.starNumber}
                            />
                          ) : (
                            <ReviewProfileSubsNo
                              starNumberSubs={item.starNumber}
                            />
                          )}
                          <p>
                            Score {item?.scoreNumber} <span></span>{" "}
                            {item.scoreLevelName}
                          </p>
                        </div>
                        <ShowMoreLessParagraph
                          text={item?.desc}
                          maxLength={90}
                        />
                      </div>
                    </div>
                  ))}

                  {subscribersProfileList?.data?.length === undefined ||
                  subscribersProfileList?.data?.length === 0 ? (
                    <div colSpan="4" className="text-center" color="red">
                      <div>
                        <img
                          src="assets/amulab2/images/nodata.png"
                          alt="file"
                          className="nodata-img"
                        />
                      </div>
                      <div style={{ marginTop: "25px" }}>
                        <h4 style={{ color: "red", opacity: "0.7" }}>
                          {data_page_components_obj.communityMenuNotFound}
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <PaginationComponent
                      currentPage={currentPage}
                      initialTotalPages={initialTotalPages}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </div>
                <div className="col-lg-4 col-md-12">
                  <div
                    className="card"
                    style={{
                      marginBottom: "15px",
                      padding: `${
                        user.godFatherName !== ""
                          ? "20px 20px 0px 20px"
                          : "20px"
                      }`,
                    }}
                  >
                    <div className="orgRating ratingReview">
                      {user?.profile?.yesCommnunityInd === "1" ? (
                        <ReviewProfileSubsYes starNumberSubs={starNumber} />
                      ) : (
                        <ReviewProfileSubsNo starNumberSubs={starNumber} />
                      )}

                      <p>
                        <span></span>Score {user?.profile?.scoreNumber}{" "}
                        <span></span>
                        {user?.profile?.scoreLevelName}
                      </p>
                    </div>
                    <div className="likeFollow ">
                      <p
                        className="mb-0"
                        style={{
                          display: "flex",
                          color: "#1da1f2",
                          fontSize: "18px",
                        }}
                      >
                        {data_page_components_obj.profileCommunity}
                        <span
                          style={{
                            width: "7px",
                            height: "7px",
                            background: "#1da1f2",
                            margin: "10px",
                            borderRadius: "50px",
                          }}
                        ></span>
                        {user?.profile?.yesCommnunityInd === "1"
                          ? data_page_components_obj.profileYes
                          : user?.profile?.yesCommnunityInd === "0"
                          ? data_page_components_obj.profileNo
                          : ""}
                      </p>
                    </div>
                    {user?.godFatherName !== "" ? (
                      <Link to={`/${user?.godFatherUsername}`}>
                        <div className="chart d-flex align-items-center mt-3">
                          <div className="imgTitle">
                            <img src="assets/amulab1/img/org.svg" alt="" />
                            <span style={{ fontSize: "20px" }}>
                              {user?.godFatherName}{" "}
                            </span>{" "}
                            {user?.godFatherCertifyInd === "1" ? (
                              <img
                                src="assets/amulab1/img/check-star.svg"
                                alt="Check"
                                className="mb-1"
                                style={{ width: "24px" }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card sidebar_card_user">
                    <div className="titleCommon">
                      <h2> {data_page_components_obj.socialMedias}</h2>
                    </div>
                    <div className="card-body pt-0">
                      <div className="tabLink">
                        <div
                          className="imgTitle"
                          style={{ marginBottom: "-20px" }}
                        >
                          <img src="assets/amulab1/img/logo-sm.svg" alt="" />
                          <span>
                            <Link
                              to={`https://duegho.com/${user?.dueghoUsername}`}
                            >
                              https://duegho.com/{user?.dueghoUsername}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card sidebar_card_user">
                    <div className="titleCommon">
                      <h2>{data_page_components_obj.aboutUs}</h2>
                    </div>

                    <DescriptionModal
                      modalDescription={modalDescription}
                      toggleDescription={toggleDescription}
                      description={description}
                      onUpdateDescription={handleUpdateDescription}
                    />

                    <div className="card-body pt-0">
                      <div className="description">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>{data_page_components_obj.description} </h5>
                          {user?.isOwnerInd === "1" ? (
                            <Link to="#">
                              <img
                                src="assets/amulab1/img/edit.svg"
                                className="editIcon"
                                alt="editIcon"
                                onClick={() => setModalDescription(true)}
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <p className="showText22"></p> */}
                        <ShowMoreLessParagraph
                          text={description}
                          maxLength={90}
                        />
                      </div>
                      <div className="subcategories description mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                          <h5>Type</h5>{" "}
                          {user?.isOwnerInd === "1" ? (
                            <Link to="#">
                              <img
                                src="assets/amulab1/img/edit.svg"
                                className="editIcon"
                                alt="editIcon"
                                onClick={handleOpenTypeModal}
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                        <TypesModal
                          modalType={modalType}
                          toggleType={toggleType}
                          user_type={user_type}
                          onUpdateType={handleUpdateType}
                          type={type}
                        />

                        <h6> {type}</h6>
                      </div>
                    </div>
                  </div>
                  {user?.profile?.claimedStatusCode === "" ? (
                    <div className="card sidebar_card_user">
                      <div className="titleCommon">
                        <h2>
                          <img
                            src="assets/amulab1/img/logo-fill.svg"
                            alt="logo"
                            style={{ marginRight: "5px" }}
                          />
                          {data_page_components_obj.profileTransparency}
                        </h2>
                      </div>

                      <div className="card-body pt-0">
                        <div className="description">
                          {user?.profile?.claimedInd === "1" ? (
                            <h5>
                              {" "}
                              {data_page_components_obj.profileNotClaimed}
                            </h5>
                          ) : (
                            ""
                          )}

                          <ClaimedProfile
                            reclamation={reclamation}
                            toggleReclamation={toggleReclamation}
                          />
                        </div>

                        {/* <p className="card-text-bold">
                          <img
                            src="assets/amulab1/img/calender.svg"
                            className="mr-2"
                            alt="calendar"
                            style={{ marginRight: "8px" }}
                          />
                          Channel Created : {user?.profile?.claimedAt}
                        </p> */}

                        <div>
                          <h6 style={{ textAlign: "center" }}>
                            {data_page_components_obj.profileNotClaimed}
                          </h6>
                        </div>

                        <Button
                          color="success"
                          style={{ display: "block", margin: "25px auto" }}
                          onClick={handleDisplayClaimPopup}
                        >
                          {data_page_components_obj.profileNotClaimedLink}
                        </Button>
                      </div>
                    </div>
                  ) : user?.profile?.claimedStatusCode === "0" ? (
                    <div className="card sidebar_card_user">
                      <div className="titleCommon">
                        <h2>
                          <img
                            src="assets/amulab1/img/logo-fill.svg"
                            alt="logo"
                            style={{ marginRight: "5px" }}
                          />
                          {data_page_components_obj.profileTransparency}
                        </h2>
                      </div>

                      <div className="card-body pt-0">
                        <div className="description">
                          {user?.profile?.claimedInd === "1" ? (
                            <h5>
                              {" "}
                              {data_page_components_obj.profileNotClaimed}
                            </h5>
                          ) : (
                            ""
                          )}

                          <ClaimedProfile
                            reclamation={reclamation}
                            toggleReclamation={toggleReclamation}
                          />
                        </div>
                        <div>
                          <h6 style={{ textAlign: "center" }}>
                            {data_page_components_obj.profileClaimedPending}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />;
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.UserProfilePageReducer.user,
  getUser: state.UserProfilePageReducer.getUser,
  loading: state.UserProfilePageReducer.loading,
  data_page_components: state.DataPageReducer.data_page_components,
  subscribersProfileList: state.SubscribersPageReducer.subscribersProfileList,
  user_type: state.UserProfilePageReducer.user_type,
});

export default connect(mapStateToProps)(SubscriberProfile);
