import React from "react";

const Books = () => {
  return (
    <div>
      <h1>Books</h1>
    </div>
  );
};

export default Books;
